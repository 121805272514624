import React from 'react'
import { numFormat } from '../utils/numberFormat'
import useBacktest from '../hooks/useBacktest'
import {capFirstLetter } from '../utils/chartUtils';
import { useNavigate } from 'react-router-dom';
import useMenu, {Selected} from '../hooks/useMenu';
import { getBacktestMethod } from '../utils/formFieldsMapping';
import moment from 'moment';
import { SPOT } from '../utils/instruments';

const BacktestResult = () => {
    const {backtestSummary, setSelectedMethod, backtestFormValues, startDate} = useBacktest()
    const navigate = useNavigate();
    const {setSelected} = useMenu();

    const startDateStr= moment(startDate).format("DD[/]MM[/]YYYY").toString()

    const handleSubmitRFQ = (e:any) => {
        const chosenMethod = backtestSummary.find(item => item.id === e.target.id)
        if(chosenMethod && Object.keys(chosenMethod).length !==0) {
            const chosenBacktestMethod = getBacktestMethod(chosenMethod?.method)?.toLowerCase()
            navigate('/rfq')
            setSelectedMethod({
                method: chosenBacktestMethod, // convert backtest name to value
                frequency: chosenMethod.parameters?.frequency?.toLowerCase(),
                strikeOffset: chosenMethod.parameters?.strike_offset*100,
                strikeOffsetCall: chosenMethod.parameters?.strike_offset_call*100,
                sym: backtestFormValues.method?.sym,
            })
        }
    }

    const handleMobileSubmit = (method:string, id:string) => {
        setSelected(Selected.RFQ)
        const chosenMethod = backtestSummary.find(item => item.id === id)
        if(chosenMethod && Object.keys(chosenMethod).length !==0) {
            const chosenBacktestMethod = getBacktestMethod(chosenMethod?.method)?.toLowerCase()
            navigate('/rfq')
            setSelectedMethod({
                method: chosenBacktestMethod, // convert backtest name to value
                frequency: chosenMethod.parameters?.frequency?.toLowerCase(),
                strikeOffset: chosenMethod.parameters?.strike_offset*100,
                strikeOffsetCall: chosenMethod.parameters?.strike_offset_call*100,
                sym: backtestFormValues.method?.sym,
            })
        }
    }

  return (
    <article className="md:mx-[2.875rem] pb-[1.5rem] bg-white shadow-[2px_3px_30px_10px_rgba(222,235,241,0.35)] rounded-[6px] pl-[2.5rem] pr-[2.5rem] pt-[1.875rem]">
        <h3 className="font-[600] text-xlg text-backtest-header-text">Backtest Results</h3>
        <table className="mt-[1.4375rem] w-full">
            <thead>
                <tr className="text-sm text-rfq-table-header-text border-b-[#E6EBEE] border-b-[1px] font-[500]">
                    <th className="pb-[0.875rem] text-start">Method</th>
                    <th className="pb-[0.875rem] text-start">Parameters</th>
                    <th className="pb-[0.875rem] text-start">PnL since {startDateStr}</th>
                    <th>{""}</th>
                </tr>
            </thead>
            <tbody>
                {
                    backtestSummary.map((item) => (
                            <tr key={item.pnl} className="text-xxs md:text-sm border-b-[#E6EBEE] border-b-[1px] font-[400] cursor-pointer md:cursor-default" onClick={() => handleMobileSubmit(item.method, item.id)}>
                                <td className={`py-[1rem] ${!item.method.includes('market') && 'underline md:no-underline'}`}><p>{item.method}</p></td>
                                <td className="py-[1rem]">
                                    {item.parameters?.frequency ? <p>{`Frequency: ${capFirstLetter(item.parameters?.frequency)}`}</p> : <></>}
                                    {item.parameters?.strike_offset ? <p>{`Strike Offset: ${item.parameters?.strike_offset*100}%`}</p> : <></>}
                                </td>
                                <td className="py-[1rem]">{numFormat(item.pnl)}</td>
                             
                                    <td className="flex justify-center py-[1rem] text-sm">
                                        <button 
                                            className="rounded-[6px] w-[2.5rem] hidden md:block h-[2.5rem] md:h-[4.125rem] md:w-[10.875rem] bg-black text-white text-sm md:text-mlg font-[500] shadow-[2px_3px_24px_10px_rgba(0,0,0,0.15)]" 
                                            onClick={(e) => handleSubmitRFQ(e)}
                                        >
                                            RFQ
                                        </button>
                                </td>
                             
                            </tr>
                        ))}
            </tbody>
        </table>
    </article>
  )
}

export default BacktestResult;