import React, { useEffect, useState } from 'react'
import useOverview from '../hooks/useOverview';
import {getLatestHedgingMethod} from '../utils/apiService';
import {useAuth0} from '@auth0/auth0-react';
import toast, {Toaster} from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { extractCryptoCcyFromPair } from '../utils/syms';

const HedgingBox = () => {
  const {latestHedgingMethod, setLatestHedgingMethod, isRegistered, symArray, firstSelectedSym} = useOverview()
  const {user} = useAuth0()
  const {setPopup, popUp} = useOverview()
  const [authToken] = useState<string | null>(localStorage.getItem('token'))
  const navigate = useNavigate()

  useEffect(() => {
    if(user && user?.sub && authToken && isRegistered) {
      getLatestHedgingMethod({
        clientId: user?.sub?.split('|')[1],
        sym: firstSelectedSym
      }, authToken)
      .then(data => setLatestHedgingMethod(data))
      .catch(err => {
        if((err as Error).message === 'EAuth0') {
           localStorage.removeItem('token')
           localStorage.removeItem('rfqid')
          navigate('/login')
          return;
        }
        toast.error(err.message ||'Something went wrong')
      })
    }
  },[setLatestHedgingMethod, user, popUp, authToken, navigate, isRegistered, firstSelectedSym, symArray])

  const changeHedging = () => {
    setPopup(true)
  }


  return (
    <div className="flex lg:w-[42%] w-full h-[17.5rem] gap-[1rem] items-center rounded-[6px] justify-between bg-white shadow-[2px_3px_30px_10px_rgba(222,235,241,0.35)] px-[1.75rem] py-[1.6875rem]">
      <Toaster />
      <div className="flex flex-col self-start h-full">
        <h3 className="font-[600] text-[1.625rem]">Hedging Tools</h3>
        <div className="flex flex-col justify-around h-full">
          <p className="text-sm text-performance-box-sm-text leading-[25px] mt-[0.5rem]">{`Your current hedging method for ${extractCryptoCcyFromPair(firstSelectedSym)} is: `}<span className="font-[500] text-menu-active-blue ">{latestHedgingMethod.split("-")[0]}</span></p>
          {latestHedgingMethod?.split("-")[0] !== 'No Hedging' ? <p className="text-sm text-performance-box-sm-text leading-[25px] mt-[0.8125rem] mb-[1.5rem]">with frequency: <span  className="font-[500] text-menu-active-blue">{latestHedgingMethod.split("-")[1]}</span></p> : null}
          <button onClick={changeHedging} className="rounded-[6px] w-[14.3125rem] h-[3.0625rem] bg-black text-white shadow-[2px_3px_26px_10px_rgba(0,0,0,0.12)]">Change Hedging Method</button>
        </div>
      </div>
      <div>
        <img src="hedging_tools.svg" alt="" />
      </div>
    </div>
  )
}

export default HedgingBox;