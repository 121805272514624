import React, {useState, useEffect} from 'react'
import {useAuth0} from '@auth0/auth0-react';
import { getLatestPrice, changeHedgingMethod } from '../utils/apiService';
import useOverview from '../hooks/useOverview';
import toast, {Toaster} from 'react-hot-toast';
import {COLLAR, hedgingMethod, INSTRUMENTS_WITHOUT_STRIKE, PUT, SPOT, ZERO_COST_COLLAR} from '../utils/instruments';
import { rollFrequency} from '../utils/formFieldsMapping';
import SubmitButton from './SubmitButton';
import { priceFormatWithDecimals } from '../utils/numberFormat';
import { capFirstLetter } from '../utils/chartUtils';
import DatePicker from 'react-datepicker';
import PopUpBox from './PopUpBox';
import { useNavigate } from 'react-router-dom';
import { displayedSymToValue } from '../utils/syms';
import { validateStrikeOffset } from '../utils/inputValidation';

const ChangeMethodForm = () => {
    const [sym, setSym] = useState(localStorage.getItem('sym') || '');
    const [method, setMethod] = useState(localStorage.getItem('method') || '');  
    const [frequency, setFrequency] = useState(localStorage.getItem('frequency') || '');
    const [strikeOffset, setStrikeOffset] = useState<number | string>(localStorage.getItem('strikeOffset') ? Number(localStorage.getItem('strikeOffset')) :0);
    const [startdate, setStartDate] = useState<Date>(localStorage.getItem('startDate') ? new Date(Number(localStorage.getItem('startDate')))  : new Date());
    const [changeHedgingInput, setChangeHedgingInput] = useState({
        sym,
        method,
        frequency,
        strikeOffset,
        startdate 
    });
    
    const {user} = useAuth0();
    const [latestPrice, setLatestPrice] = useState(0);
    const {setPopup, popUp, isRegistered} = useOverview();
    const [authToken] = useState<string | null>(localStorage.getItem('token'))
    const navigate = useNavigate();

    useEffect(()=> {
        if(!sym || !authToken || !isRegistered) {
            return;
        }
        getLatestPrice(sym, authToken)
        .then(price => setLatestPrice(price?.toFixed(2)))
        .catch(err => {
            console.log(err)
            toast.error((err as Error).message)
        })
    },[sym, authToken, isRegistered])

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(!sym) {
      toast.error('Please select a token');
      return;
    }
    if(!method) {
      toast.error('Please select a method');
      return;
    }
    if(method !== 'notsold' && !frequency) {
      toast.error('Please select a frequency');
      return;
    }
    if(!INSTRUMENTS_WITHOUT_STRIKE.includes(method)) {
        if(!validateStrikeOffset(Number(strikeOffset))) {
            toast.error('Please enter a valid strike offset value')
            return
        }
    } 

    if(user && user?.sub && authToken) {
        const res = await changeHedgingMethod({
                clientId: user?.sub?.split('|')[1],
                sym,
                method,
                frequency,
                strikeoffset: strikeOffset ? Number(strikeOffset)/100 : 0,
                startdate // date is formatted into string format in the apiService file
        }, authToken)
        .then(data => {
            return data
        })
        .catch(error => {
            if((error as Error).message === 'EAuth0') {
                 localStorage.removeItem('token')
                 localStorage.removeItem('rfqid')
                navigate('/login')
                return;
            }
            toast.error(error.message)
            setTimeout(() => {
                setPopup(false)
            }, 1000)
        })
        
        if(res === 'success') {
            toast.success('Hedging method changed successfully')
            Object.entries(changeHedgingInput).forEach(([key, value]) => {
                localStorage.setItem(key, value.toString())
            })
            setTimeout(() => {
                setPopup(false)
            }, 500)
        } else {
            toast.error('Something went wrong. Please try again.')
            setTimeout(() => {
                setPopup(false)
            }, 1000)
        }

    } 
  }


  return (
    <PopUpBox popUp={popUp} setPopup={setPopup}>
          <Toaster />
          <div className="flex flex-col  rounded-[10px] pb-[2.8rem] z-20 bg-white">
              <div className="w-full relative">
                  <div className="absolute w-[1.5rem] h-[1.5rem] -right-1 -top-1 cursor-pointer" onClick={() => setPopup(false)}>
                      <img src="close.png" alt="" className="absolute" />
                  </div>
              </div>
              <div className="flex flex-col w-[25.25rem] px-[1.5rem]">
                  <h3 className="text-xl mt-[1.5rem] mb-[1.8rem] text-center">Change Hedging Method</h3>
                  <form className="flex flex-col gap-[1.875rem]" onSubmit={handleSubmit}>
                      <label htmlFor='sym'>
                          <p className="text-rfq-label-text">Token</p>
                          <select id="sym" className="text-rfq-value-text mt-[1rem] w-full border-b-[#E6EBEE] border-b-[1px] h-[2.8rem] pl-[0.8rem] rounded-[6px]"
                              onChange={(e) => {
                                  setSym(e.target.value);
                                  setChangeHedgingInput({ ...changeHedgingInput, sym: e.target.value });
                              } }
                              value={sym}
                              required
                          >
                              <option value="">{"Select"}</option>
                              {displayedSymToValue.map((sym) => <option key={sym.value} value={sym.value}>{sym.symbol}</option>)}
                          </select>
                      </label>
                      <label htmlFor='method'>
                          <p className="text-rfq-label-text">Hedging Method</p>
                          <select id="method" className="text-rfq-value-text mt-[1rem] w-full border-b-[#E6EBEE] border-b-[1px] h-[2.8rem] pl-[0.8rem] rounded-[6px]"
                              onChange={(e) => {
                                  setMethod(e.target.value);
                                  setChangeHedgingInput({ ...changeHedgingInput, method: e.target.value });
                              } }
                              value={method}
                              required
                          >
                              <option value="">{"Select"}</option>
                              <option value="notsold">No Hedging</option>
                              {hedgingMethod.map((method) => <option key={method.value} value={method.value}>{method.name}</option>)}
                          </select>
                      </label>
                      {method !== 'notsold' && (
                          <label htmlFor='frequency'>
                              <p className="text-rfq-label-text">Hedging Frequency</p>
                              <select id="frequency" className="text-rfq-value-text mt-[1rem] w-full border-b-[#E6EBEE] border-b-[1px] h-[2.8rem] pl-[0.8rem] rounded-[6px]"
                                  onChange={(e) => {
                                      setFrequency(e.target.value);
                                      setChangeHedgingInput({ ...changeHedgingInput, frequency: e.target.value });
                                  } }
                                  value={frequency}
                              >
                                  <option value="">{"Select"}</option>
                                  {method === SPOT && <option value="weekly">Weekly</option>}
                                  {rollFrequency.map((frequency) => <option key={frequency} value={frequency}>{capFirstLetter(frequency)}</option>)}
                              </select>
                          </label>)}
                      {[PUT, COLLAR, ZERO_COST_COLLAR].includes(method) && (
                          <label htmlFor="strikeOffset">
                              <p className="text-rfq-label-text pb-[0.5rem]">{`Current ${sym.slice(0, 3).toUpperCase()} Price: ${priceFormatWithDecimals(latestPrice)}`}</p>
                              <p className="text-rfq-label-text">How much below the market price do you choose your strike (% of spot)?</p>
                              <input type="number" id="strikeOffset" name="strikeOffset" placeholder={'10%'} min={0} max={100} className="text-rfq-value-text mt-[1rem] w-full border-b-[#E6EBEE] border-b-[1px] h-[2.8rem] pl-[0.8rem] rounded-[6px]"
                                  onChange={(e) => {
                                      setStrikeOffset(e.target.value);
                                      setChangeHedgingInput({ ...changeHedgingInput, strikeOffset: e.target.value });
                                  } }
                                  value={strikeOffset} />
                              <p className="text-rfq-label-text">{`Resulting strike price K=${priceFormatWithDecimals(latestPrice * (1 - Number(strikeOffset) / 100))}`}</p>
                          </label>)}
                      <label htmlFor="startdate">
                          <p className="text-rfq-label-text">When did you start using this hedging method?</p>
                          <DatePicker
                              selected={startdate}
                              onChange={(date) => {
                                  setStartDate(date as Date);
                                  setChangeHedgingInput({ ...changeHedgingInput, startdate: date as Date });
                              } }
                              className="border-b-[#E6EBEE] border-b-[1px] w-full pl-[0.8rem] mt-[1rem]"
                              dateFormat={"dd/MM/yyyy"} 
                              maxDate={new Date()}
                            />
                      </label>
                      <SubmitButton btnText={"Submit"} />
                  </form>
              </div>
          </div>
        </PopUpBox>)
}

export default ChangeMethodForm;