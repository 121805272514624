import React, { useEffect, useState } from 'react';
import { formatDate, priceFormatWithDecimals, thousandSeparator } from '../utils/numberFormat';
import useRFQ, {singleRecentQuote} from '../hooks/useRFQ';
import { RecentQuoteTableField } from '../utils/formFieldsMapping';
import moment from 'moment';
import { getRecentQuoteList } from '../utils/apiService';
import { useAuth0 } from '@auth0/auth0-react';
import toast, {Toaster} from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import useOverview from '../hooks/useOverview';

export const formatNotional = (notional: string) => {
    if(!notional) return 'NA';
    const [amount, ccy] = (notional as string).split(' ');
    if(amount && ccy) {
        return `${thousandSeparator(Number(amount))} ${ccy}`
    }
    return notional;
}

export const RFQTableRow = ({quote, submitTrade}:{quote: singleRecentQuote; submitTrade:() => void}) => {
    const {Date, Counterparty, Notional, BidPrice, AskPrice, FairPrice, Product, QuoteId, Timestamp} = quote;
 
    
    return (
    <tr key={QuoteId} className="my-[1.5rem] h-[4.125rem] border-b-[1px] border-b-[#EBF2F5]">
        <td className="w-[8%] text-center">{formatDate(Date as number | string)}</td>
        <td className="w-[10%] text-center">{moment(Timestamp).format('LT')}</td>
        <td className="w-[20%]">{Product}</td>
        <td className="w-[10%] text-center">{formatNotional(Notional as string)}</td>
        <td className="w-[10%] text-center">{Counterparty}</td>
        <td className="w-[10%] text-center">{FairPrice ? `${priceFormatWithDecimals(Number(FairPrice))}` : '0.0'}</td>
        <td className="w-[10%] text-center">{BidPrice ? `${priceFormatWithDecimals(Number(BidPrice))}` : '0.0'}</td>
        <td className="w-[10%] text-center">{AskPrice ? `${priceFormatWithDecimals(Number(AskPrice))}` : '0.0'}</td>
        <td className="text-center">
            <button className="w-[5rem] h-[2.25rem] rounded-[6px] bg-black text-white text-sm shadow-[2px_3px_24px_2px_rgba(0,0,0,0.15)]" id={quote?.QuoteId} onClick={submitTrade}>
                Trade
            </button> 
        </td>
    </tr>)
}

const RecentRFQTable = () => {
    const {recentQuoteList, setRecentQuoteList,setRecentQuoteCols} = useRFQ();
    const {submitTrade} = useRFQ();
    const {user, isLoading} = useAuth0();
    const [authToken] = useState<string | null>(localStorage.getItem('token'))
    const navigate = useNavigate()
    const { symArray } = useOverview();

    useEffect(() => {
        try{
            if(user && !isLoading && authToken && symArray) {
                getRecentQuoteList({
                    clientId: user?.sub?.split('|')[1] as string,
                    symArray: symArray.map(item => item.value),
                }, authToken).then(res => {
                    if(res) {
                        const {recentQuoteList:newRecentQuoteList, recentQuoteCols:newRecentQuoteCols} = res
                        setRecentQuoteList(newRecentQuoteList)
                        setRecentQuoteCols(newRecentQuoteCols)
                    }
                }).catch(err => {
                    if((err as Error).message === 'EAuth0') {
                        localStorage.removeItem('token')       
                        localStorage.removeItem('rfqid')
                        window.location.href = window.location.origin + '/login'
                        return;
                      }
                    toast.error(err.message)
                    throw new Error(err.message)
                })
            }
        } catch (error) {
            if((error as Error).message === 'EAuth0') {
                localStorage.removeItem('token')
                localStorage.removeItem('rfqid')
                navigate('/login')
                return;
              }
            toast.error('No recent quote list received')
        }

    },[symArray, user, isLoading, authToken, setRecentQuoteList, setRecentQuoteCols, navigate])

  return (
    <div className="w-full min-w-[40rem]">
        <Toaster/>
        <table className="mt-[1.5rem] w-full rounded-[6px] shadow-[2px_3px_30px_10px_rgba(222,235,241,0.35)]">
            <thead className="bg-bg-rfq-table-header text-rfq-table-header-text md:text-xs text-xxs font-[400]">
                <tr className="py-[1.38rem] h-[3.875rem]">
                    <th className="w-[8%] text-center">{RecentQuoteTableField['Date']}</th>
                    <th className="w-[10%] text-center">{'Time'}</th>
                    <th className="w-[20%] text-start">{RecentQuoteTableField['Product']}</th>
                    <th className="w-[10%] text-center">{RecentQuoteTableField['Notional']}</th>
                    <th className="w-[10%] text-center">{RecentQuoteTableField['Counterparty']}</th>
                    <th className="w-[10%] text-center">{RecentQuoteTableField['FairPrice']}</th>
                    <th className="w-[10%] text-center">{RecentQuoteTableField['BidPrice']}</th>
                    <th className="w-[10%] text-center">{RecentQuoteTableField['AskPrice']}</th>
                    <th className="text-center">{""}</th>
                </tr>
            </thead>
            <tbody className="text-rfq-table-row-text md:text-xs text-xxs font-[500] bg-white">
                {
                    recentQuoteList.slice().sort((a,b) => Number(b.Timestamp)- Number(a.Timestamp)).map((quote: singleRecentQuote) => (
                            <RFQTableRow key={quote.QuoteId} quote={quote} submitTrade={async () => await submitTrade({
                                Date: quote.Date,
                                Product: quote.Product,
                                Timestamp: quote.Timestamp,
                                Notional:formatNotional(quote.Notional as string) || 0.0,
                                Counterparty:quote.Counterparty,
                                FairPrice: quote.FairPrice,
                                BidPrice:quote.BidPrice,
                                AskPrice:quote.AskPrice,
                                QuoteId:quote.QuoteId,
                            })}/>))
                }
            </tbody>
        </table>
    </div>
  )
}

export default RecentRFQTable