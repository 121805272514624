import React from 'react'

const Loading = () => {
  return (
    <div className="w-full h-full flex justify-center items-center">
        <img src="loading.gif" alt="loading" />
    </div>
  )
}

export default Loading