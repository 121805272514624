import React from 'react';
import SideMenuProfile from './SideMenuProfile';
import SideNavigationMenu from './SideNavigationMenu';

const SideMenu = () => {

  return (
    <nav className="hidden md:flex flex-col w-[18.5rem] shadow-[2px_3px_30px_10px_rgba(222,235,241,0.35)] min-h-[100%] bg-white z-10">
      <SideMenuProfile />
      <hr className="border-[#E4EEF3] border-solid border-[1.3px] mb-[2.125rem] w-[80%] flex mx-auto"/>
      <SideNavigationMenu />
    </nav>
  )
}

export default SideMenu;