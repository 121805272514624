import {COLLAR, FORWARD, PUT, SPOT, ZERO_COST_COLLAR, hedgingMethod} from '../utils/instruments';
import { MONTHLY, QUARTERLY, WEEKLY } from './frequencies';
export const rollFrequency = [MONTHLY, QUARTERLY]

/*
not used, just for info
*/
export const hedgingMethodForDisplay = {
    notsold: 'No Hedging',
    weeklyspot: WEEKLY+' Spot Selling',
    monthlyspot: MONTHLY+' Spot Selling',
    quarterlyspot: QUARTERLY+' Spot Selling',
    monthlyforward: MONTHLY+' Forward Selling',
    quarterlyforward: QUARTERLY+' Forward Selling',
    monthlyput: MONTHLY+' Put Option',
    quarterlyput: QUARTERLY+' Put Option',
    monthlyzerocostcollar: MONTHLY+' Zero Cost Collar',
    quarterzerocostlycollar: QUARTERLY+' Zero Cost Collar',
    monthlycollar: MONTHLY+' Collar',
    quarterlycollar: QUARTERLY+' Collar'
}

export const getMethodValueFromName = (methodName:string) => {
    if(!methodName) return;
    const targetMethod = hedgingMethod.find(method => method.name === methodName)
    if(targetMethod) {
        return targetMethod.value
    }
    return '';
}


export const strikeOffsetPutFormField = "How much below the market price are you ready to go? (0-100%)"
export const strikeOffsetCallFormField = "How much above the market price are you ready to go? (0-100%)"
export const strikeCallFormField = "From which price do you want to give-up the upside?"

export const getBacktestMethod = (methodName:string)=> {
    if(!methodName) return ''
    if(methodName.toLowerCase().includes(COLLAR)) {
        return COLLAR
    }
    if(methodName.toLowerCase().includes(ZERO_COST_COLLAR)) {
        return ZERO_COST_COLLAR
    }
    if(methodName.toLowerCase().includes(PUT)) {
        return PUT
    }
    if(methodName.toLowerCase().includes(FORWARD)) {
        return FORWARD
    }
    if(methodName.toLowerCase().includes('market')) {
        return SPOT
    }
    return ''
}


export const RecentQuoteTableField = {
    Date: 'Date',
    Product: 'Product',
    Notional: 'Notional',
    Counterparty: 'Market Maker',
    FairPrice: 'Fair Price',
    BidPrice: 'Bid Price',
    AskPrice: 'Ask Price',
    QuoteId: 'Quote Id'
}

export const CSVFileName = {
    PnLChart: 'pnl_over_time.csv',
    PricingChart: 'pricing_simulation.csv',
    BacktestChart: 'backtest.csv'
}

export enum FrequencyType {
    monthly = 'monthly',
    quarterly = 'quarterly'
}

export const generateExpiryOptions = (frequency:FrequencyType) => {
    const monthNow = (new Date()).getMonth()
    const yearNow = (new Date()).getFullYear()
    const monthArray = Array.from({length: 12}, (item, i) => {
        return (new Date(yearNow, monthNow + i, 1).toLocaleString('en-GB', {month: 'short', year: 'numeric'})).toString()
    });
    if (frequency === FrequencyType.monthly) {
        return monthArray
    }

    if(frequency === FrequencyType.quarterly) {
        return monthArray.filter(month => month.includes('Mar') || month.includes('Jun') || month.includes('Sep') || month.includes('Dec'))
    }
}