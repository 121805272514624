import React,{useState} from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import GeneralSetting from './GeneralSetting';
import EmailSetting from './EmailSetting';
import PasswordSetting from './PasswordSetting';
import ImportData from './ImportData';

const ProfileSettingsMenu = () => {
    const {logout} = useAuth0()
    const [profileMenu, setProfileMenu] = useState("General")


    const handleClick=(e:any) => {
        setProfileMenu(e.target.innerText)
    }

    const handleLogout = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('rfqid')
        logout({returnTo: window.location.origin +'/login'})
    }

  return (
    <nav>
        <div className="flex flex-col md:flex-row gap-[1.875rem] mr-[2.5rem]">
            <ul className="flex ml-[1.125rem] pr-[1.125rem] gap-[1rem] md:gap-[3rem] h-[3.625rem] items-center bg-bg-profile-setting rounded-[10px] pl-[1.25rem] flex-1 min-w-[18.5rem]" onClick={(e) => handleClick(e)}>
                <li className={`${profileMenu === 'General' ? "font-[500] border-b-[4px] border-b-black" : "font-[400]"} h-full flex text-xs cursor-pointer pt-[1rem]`}>General</li>
                <li className={`${profileMenu === 'Email' ? "font-[500] border-b-[4px] border-b-black" : "font-[400]"} h-full flex text-xs cursor-pointer pt-[1rem]`}>Email</li>
                <li className={`${profileMenu === 'Password' ? "font-[500] border-b-[4px] border-b-black" : "font-[400]"} h-full flex text-xs cursor-pointer pt-[1rem]`}>Password</li>
                <li className={`${profileMenu === 'Revenue' ? "font-[500] border-b-[4px] border-b-black" : "font-[400]"} h-full flex text-xs cursor-pointer pt-[1rem]`}>Revenue</li>
            </ul>
            <div className="flex gap-[1rem] min-w-[6rem] ml-[1.125rem] md:ml-0 items-center cursor-pointer" onClick={() => handleLogout()}>
                <p>Logout</p>
                <div className="w-[1.5rem] h-[1.5rem] cursor-pointer" >
                    <img src="logout.png" alt="logout" className="w-full h-full" />
                </div>
            </div>
        </div>  
        <div className="ml-[1.125rem]">
        {
            profileMenu === 'General' 
            ? <GeneralSetting />
            : profileMenu === 'Email' 
            ? <EmailSetting />
            : profileMenu === 'Password' 
            ? <PasswordSetting />
            : (<div className="mt-[1.5rem] pr-[2rem] w-full md:w-[40rem]">
                <ImportData/>   
            </div>)
        } 
        </div>
    </nav>
  )
}

export default ProfileSettingsMenu;

