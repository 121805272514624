import React from 'react'

const ErrorPage = () => {
  return (
    <div className="w-full h-full flex justify-center items-center">
        <img src="errorpage.gif" alt="error" />
    </div>
  )
}

export default ErrorPage