import React, {useEffect, useState} from 'react'
import Header from '../components/Header';
import RecentRFQTable from '../components/RecentRFQTable';
import SideMenu from '../components/SideMenu';
import RFQSubmitted from '../components/RFQSubmitted';
import { useAuth0 } from '@auth0/auth0-react';
import {useNavigate, useLocation} from 'react-router-dom';
import useMenu, {Selected} from '../hooks/useMenu';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from '../components/Error';
import MobileSideMenu from '../components/MobileSideMenu';
import MobileHeader from '../components/MobileHeader';
import TradeBox from '../components/TradeBox';
import useRFQ from '../hooks/useRFQ';
import { domain, getRegistrationStatus } from '../utils/apiService';
import useOverview from '../hooks/useOverview';
import Loading from '../components/Loading';

const RFQSubmit = () => {
  const {isAuthenticated, isLoading, user, getAccessTokenSilently} = useAuth0();
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const {setSelected, showMobileMenu, setShowMobileMenu} = useMenu();
  const headerText = Selected.RFQ
  const {popUp} = useRFQ();
  const [authToken, setAuthToken] = useState<string|null>(localStorage.getItem('token'))
  const {setIsRegistered} = useOverview();
  const [isSubscribed, setSubscribed] = useState<boolean>(false);

  useEffect(() => {
    if(pathname === '/rfq-submitted') {
      setSelected(Selected.RFQ)
    }
  },[pathname, setSelected])

  useEffect(() => {
    if(!authToken && !isLoading && user) {
        getAccessTokenSilently({
            ignoreCache: true,
            audience: `https://${domain}/api/v2/`,
        }).then(tk => {
          setAuthToken(tk)
          localStorage.setItem('token', tk)
        })
    }
  },[authToken, getAccessTokenSilently, isLoading, user])

  useEffect(() => {
    if(isAuthenticated && user && !isLoading && authToken) {
      getRegistrationStatus(user?.sub?.split('|')[1] as string, authToken as string)
      .then(st => {
        if(st.isregistered === 'true') {
            setIsRegistered(true)
          if(st.stripestatus === 'paid') {
            // user has registered
            setSubscribed(true)
            return;
          } else  {
          // user has not paid
            navigate('/loading')
            window.open('/subscribe', '_blank')
          } 
        } else {
          // user has not registered
          navigate('/register');
        }
    })
      .catch(err => {
        if(err.message === 'EAuth0') {
          localStorage.removeItem('token')
          localStorage.removeItem('rfqid')
        }
        navigate('/login')
      });
    } else if (!isLoading && !isAuthenticated) {
      // user is not authenticated or user is not defined
      navigate('/login');
    }
  },[authToken, isAuthenticated, isLoading, navigate, user, setIsRegistered, isSubscribed])

  if(isLoading || !isAuthenticated || !user || !authToken || !isSubscribed) {
    return <Loading/>
  }

  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
    >
    <main className="h-[100%] flex flex-1 overflow-x-scroll md:overflow-x-hidden">
      <SideMenu />
      <div className="flex flex-col bg-dashboard w-full">
        {showMobileMenu && <MobileSideMenu />} 
        {popUp && <TradeBox/>}
        <Header headerText={headerText}/>
        <MobileHeader/>
        <section className="flex flex-col px-[1rem] md:px-0 md:mx-[2.875rem] mt-[2.25rem] min-w-[18.5rem]" onClick={()=> setShowMobileMenu(false)}>
          <h1 className="text-xlg font-[700] text-center md:hidden mt-[3rem] mb-[1.5rem]">{headerText}</h1>
          <RFQSubmitted />
          <h3 className="font-[600] text-lg md:text-xl text-rfq-header mt-[2rem]">Recent RFQs</h3>
          <RecentRFQTable />
        </section>
      </div>
    </main>
  </ErrorBoundary>
  )
}

export default RFQSubmit;