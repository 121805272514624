import { SinglePricingMethod } from "../hooks/usePricing";
import moment from 'moment';
import {SinglePricing} from '../hooks/usePricing';
import { SingleBacktestSummary } from "../hooks/useBacktest";
import { COLLAR, FORWARD, PUT, SPOT, ZERO_COST_COLLAR } from "./instruments";

export interface SingleBacktestPerformance {
    date: number,
    method: number,
    methodCompare: number,
  }
export interface SingleOverviewChartData {
    date: number,
    hedging: number,
    nohedging: number,
}

interface IPricingTable {
    method: SinglePricingMethod,
    methodCompare: SinglePricingMethod,
}

export const backtestSummaryMethod = (methodName:string) => {
    if(!methodName) return '';
    switch (methodName) {
        case SPOT:
          return SPOT;
        case FORWARD:
          return FORWARD;
        case PUT:
          return PUT;
        case ZERO_COST_COLLAR:
          return 'Zero Cost Collar';
        case COLLAR:
            return COLLAR;
        default:
          return '';
      }
}

export const capFirstLetter = (text:string) => {
    return text.slice(0,1).toUpperCase()+ text.slice(1)
}

export enum ChartPeriod {
    '1m' = '1m',
    '3m' = '3m',
    '6m' = '6m',
    '1Y' = '1Y',
    '2Y' = '2Y',
    '5Y' = '5Y',
    'YTD' = 'YTD',
    'Max' = 'Max'
}

export const getSmartYtdStartDate = () => {
    var startOfTheYear=moment().startOf('year');
    var thresholdInDays=60;
    var diff=(Number(moment()) - Number(startOfTheYear))/86400000;
    return (diff>thresholdInDays)?startOfTheYear:startOfTheYear.subtract(1, 'years');
}

export const startDate = (text:string) => {
    if(!text) return
    switch (text){
        case ChartPeriod['1m']: return Number(moment().subtract(1,'months'))
        case ChartPeriod['3m']: return Number(moment().subtract(3,'months'))
        case ChartPeriod['6m']: return Number(moment().subtract(6,'months'))
        case ChartPeriod['1Y']: return Number(moment().subtract(1,'years'))
        case ChartPeriod['2Y']: return Number(moment().subtract(2,'years'))
        case ChartPeriod['5Y']: return Number(moment().subtract(5,'years'))
        case ChartPeriod['YTD']: return Number(getSmartYtdStartDate())
    }
}

export const xAxisTickCount = (text:string, earliestDate:number) => {
    if(!text) return
    switch (text){
        case ChartPeriod['1m']: return 5
        case ChartPeriod['3m']: return 5
        case ChartPeriod['6m']: return 10
        case ChartPeriod['1Y']: return 20
        case ChartPeriod['2Y']: return 26
        case ChartPeriod['5Y']: return 32
        case ChartPeriod['YTD']: return moment().month() + 1
        case ChartPeriod['Max']: 
            const days = Math.abs(moment().diff(moment(earliestDate),'days'))+1
            const months = Math.abs(moment().diff(moment(earliestDate),'months')) +1
            if (days <30) {
                return 20
            } else if(days>30 && days <90) {
                return 15
            } else if (days>90 && days <1314) {
                // if less than 3 years, return months
                return months
            } else {
                return 40
            }
    }
}

export const YAxisTickCount = 8

export const formatOverviewChartCSVData = (chartData:SingleOverviewChartData[], chartCols: string[]) => {

    const formattedData = chartData.map((item:any) => {
        return {
            date: moment(item.date).format('YYYY[-]MM[-]DD')?.toString(),
            [chartCols[1]]: item[chartCols[1]]?.toString(),
            [chartCols[2]]:item[chartCols[2]]?.toString()
        }
    })
    const headers = [
        {label: chartCols[0], key:chartCols[0]},
        {label: chartCols[1], key:chartCols[1]},
        {label: chartCols[2], key:chartCols[2]}
    ]
  
    return {
        formattedData,
        headers
    }
}

export const formatPricingChartCSVData = (chartData:SinglePricing[], pricingTable:IPricingTable) => {
    const formattedData = chartData.map((item:SinglePricing)=> {
        if(!pricingTable.methodCompare?.name) {
            return {
                price: item.price?.toString(),
                method: item.method?.toString(),
            }
        }
        return {
            price: item.price?.toString(),
            method: item.method?.toString(),
            methodCompare: item.methodCompare?.toString(),
        }
    })

    const headers = pricingTable.methodCompare?.name 
    ? [
        {label: 'Price', key:'price'},
        {label: pricingTable.method?.name, key:'method'},
        {label: pricingTable.methodCompare?.name, key:'methodCompare'}
    ]
    : [
        {label: 'Price', key:'price'},
        {label: pricingTable.method?.name, key:'method'},
    ]

    return {
        formattedData,
        headers
    }
}

export const formatBacktestChartCSVData = (chartData:SingleBacktestPerformance[], backtestTable:SingleBacktestSummary[]) => {
    const formattedData = chartData.map((item:SingleBacktestPerformance) => {
        if(!backtestTable[1]?.method) {
            return {
                date: moment(item.date).format('YYYY[-]MM[-]DD')?.toString(),
                method: item.method?.toString()
            }
        }
        return {
            date: moment(item.date).format('YYYY[-]MM[-]DD').toString(),
            method: item.method?.toString(),
            methodCompare: item.methodCompare?.toString()
        }
    })

    const headers = backtestTable[1]?.method
    ? [
        {label: 'Date', key:'date'},
        {label: backtestTable[0]?.method, key:'method'},
        {label: backtestTable[1]?.method, key:'methodCompare'}
    ]
    : [
        {label: 'Date', key:'date'},
        {label: backtestTable[0]?.method, key:'method'},
    ]

    return {
        formattedData,
        headers
    }
}

interface IChartData {
    date: number,
    method?: number,
    methodCompare?: number,
    hedging?:number,
    nohedging?:number,
}

const getCurrentWeekNumber = (date: number | Date| string) => {
    const currentDate = new Date(date)
    if(!isNaN(Number(currentDate))) {
        const startDate = new Date(currentDate.getFullYear(),0,1)
        const days = Math.floor((Number(currentDate) - Number(startDate))/(24*60*60*1000))
        // round up and return an integer
        return Math.ceil(days/7)
    }
    return 0;
}

// function for determining number of ticks
/**
 * It takes in an array of objects, and returns an array of numbers
 * @param {IChartData[]} chartData - an array of objects that contain the data to be plotted on the
 * chart.
 * @param {string} chartPeriod - string - the period of the chart, e.g. '1m', '5Y'
 * @param {string} [chartDataFrequency=monthly] - string = 'monthly' as default
 */
export const ticksArray = (chartData:IChartData[], chartPeriod:string, chartDataFrequency: string = 'monthly') => {
    let shortTicksArray: (number|string)[];
    switch(chartDataFrequency) {
        case 'weekly':
            switch(chartPeriod) {
                case '1m':
                    // for 1m - display weekly 
                    shortTicksArray = chartData.map(item => {
                        return item.date
                    }).filter(value => typeof value === 'number')
                    return shortTicksArray;
                case '5Y':
                    // display every 3 month
                    shortTicksArray = chartData.map(item => {
                        if(getCurrentWeekNumber(item.date)) {
                            if(getCurrentWeekNumber(item.date) % 12 === 0) {
                                return item.date
                            }
                        }
                        return ''
                    }).filter(value => typeof value === 'number')
                    return shortTicksArray;
                default:
                    // default display monthly
                    shortTicksArray = chartData.map(item => {
                        if(getCurrentWeekNumber(item.date)) {
                            if(getCurrentWeekNumber(item.date) % 4 === 0) {
                                return item.date
                            }
                        }
                        return ''
                    }).filter(value => typeof value === 'number')
                    return shortTicksArray;
            }
        
        case 'quarterly':
            shortTicksArray = chartData.map(item => {
                return item.date
            }).filter(value => typeof value === 'number')
            return shortTicksArray;
        // default = 'monthly'
        default:
            switch(chartPeriod) {
                case '5Y':
                    // for 5y, show every 3 months
                    shortTicksArray = chartData.map(item => {
                        if(new Date(item.date).getMonth() % 3 === 0) {
                            return item.date
                        }
                        return ''
                    }).filter(value => typeof value === 'number')
                    return shortTicksArray;
                default:
                    // default monthly data frequency
                    shortTicksArray = chartData.map(item => {
                        return item.date
                    }).filter(value => typeof value === 'number')
                    return shortTicksArray;
            }
    }  
}

