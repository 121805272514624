import React from 'react';
import useProfile from '../hooks/useProfile';
import useMenu from '../hooks/useMenu';

const MobileHeader = () => {
    const {generalSetting} = useProfile();
    const {setShowMobileMenu} = useMenu();
    
  return (
    <header className="fixed bg-white w-full md:hidden py-[1rem] transition-all shadow-[2px_3px_30px_10px_rgba(222,235,241,0.35)] min-w-[18.5rem]">
        <div className="flex justify-between items-center pl-[1rem] pr-[1rem] sm:pr-[2rem]">
          <div className="w-6 h-6"  onClick={() => setShowMobileMenu((prev) => !prev)}>
            <img src="menu.png" alt="" className="w-full h-full object-cover"/>
          </div>
          <div className="w-[9.2rem] h-[2rem]">
          <img src="pridwenLogo.png" alt="" className="object-fit w-full h-full"/>
          </div>
          <div className="rounded-full w-8 h-8 overflow-hidden">
              <img src={generalSetting?.picture} alt="profile-pic" className="object-cover w-full h-full"/>
          </div>
        </div>
    </header>
  )
}

export default MobileHeader;