import React,{useEffect, useState} from 'react';
import LoginInBox from '../components/LoginInBox';
import { getRegistrationStatus } from '../utils/apiService';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from '../components/Error';
import useOverview from '../hooks/useOverview';

const Login = () => {
  const {isAuthenticated, user, isLoading} = useAuth0();
  const navigate = useNavigate();
  const [authToken] = useState<string | null>(localStorage.getItem('token'))
  const {setIsRegistered} = useOverview();
  const [isSubscribed, setSubscribed] = useState<boolean>(false);

  useEffect(() => {
    if(isAuthenticated && user && !isLoading && authToken) {
        try {
            getRegistrationStatus(user?.sub?.split('|')[1] as string, authToken as string)
            .then(status => {
                if(status.isregistered === 'true') {
                    setIsRegistered(true)
                    if(status.stripestatus === 'paid') {
                        // user is registered and has paid
                        setSubscribed(true)
                        navigate('/')
                    } else {
                        // user is registered but has not paid
                        navigate('/loading')
                        window.open('/subscribe', '_blank')
                        return;
                    }
                } else {
                    navigate('/register')
                    return
                }
            })
        } catch (error) {
            if((error as Error).message === 'EAuth0') {
                localStorage.removeItem('token')
                localStorage.removeItem('rfqid')
            }
            navigate('/login')
        }
    } else {
        return;
    }
},[isAuthenticated, navigate, user, isLoading, authToken, setIsRegistered, isSubscribed])

  return (
    <ErrorBoundary
        FallbackComponent={ErrorPage}
    >
        <div className="flex w-full h-[100vh] items-center md:pl-[4rem]">
            <div className="md:flex w-[40%] hidden">
                <img src="login.png" alt="login" />
            </div>
            
            <div className="flex justify-center gap-[2rem] w-full">
                <LoginInBox />
            </div>
        </div>
    </ErrorBoundary>
  )
}

export default Login;