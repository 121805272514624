import React, {useEffect, useCallback} from 'react'
import SideNavigationMenu from './SideNavigationMenu';
import useMenu from '../hooks/useMenu';


const MobileSideMenu = () => {
  const {setShowMobileMenu, showMobileMenu} = useMenu();

  const keyPress = useCallback((e:KeyboardEvent) => {
      if(e.key === 'Escape' && showMobileMenu) {
          setShowMobileMenu(false)
      }
  },[setShowMobileMenu, showMobileMenu])

  // useEffect to execute the keypress function and watch for the function change
  useEffect(()=> {
      document.addEventListener('keydown', keyPress);
      return () => document.removeEventListener('keydown', keyPress);
  },[keyPress])


  return (
    <div className="absolute h-[100%] w-[60%] min-w-[13rem] max-w-[15rem] z-10 bg-white pt-[20%] shadow-[2px_3px_30px_10px_rgba(222,235,241,0.35)] md:hidden overflow-y-hidden">
      <div className="absolute top-[1.7rem] right-[1.5rem] w-6 h-6 flex cursor-pointer" onClick={() => setShowMobileMenu((prev) => !(prev))}>
        <img src="close.png" alt="" />
      </div>
      <SideNavigationMenu />
    </div>
  )
}

export default MobileSideMenu;