import React from 'react';
import { numFormat } from '../utils/numberFormat';

interface PerformanceBoxProps {
  icon:string;
  pnl:number;
  btmText:string;
  btmTextPart2?:string;
}

const splitText = (text:string,line:number) => {
  const textArray = text.split(' ')
  if(line === 1) {
    return `${textArray.slice(0,textArray.length -1).join(' ')}`
  } 

  if(line === 2) {
    return `${textArray[textArray.length-1]}`
  }

}

const PerformanceBox = ({icon, pnl, btmText, btmTextPart2}:PerformanceBoxProps) => {
  return (
    <div className="lg:w-[30%] w-full h-[17.5rem] flex flex-col items-center justify-center rounded-[6px] md:py-[1.375rem] bg-white shadow-[2px_3px_30px_10px_rgba(222,235,241,0.35)]">
      <div className="w-[5.25rem] h-[5.25rem] rounded-full overflow-hidden bg-[#E8F4FA] flex justify-center items-center">
        <img src={icon} alt="" className="object-cover"/>
      </div>
      <h3 className={`${btmTextPart2 ? 'text-sm': 'text-xlg'} font-[600] mt-[1.25rem] text-performance-box-lg-text`}>{`${numFormat(pnl)}`}</h3>
      <p>{btmTextPart2 && 'or'}</p>
      <h3 className="text-sm font-[600] text-performance-box-lg-text">{btmTextPart2 ? btmTextPart2: ''}</h3>
      <p className={`${btmTextPart2 ? 'text-xs': 'text-sm'} text-performance-box-sm-text text-center`}>{splitText(btmText,1)}</p>
      <p className={`${btmTextPart2 ? 'text-xs': 'text-sm'} text-performance-box-sm-text text-center`}>{splitText(btmText,2)}</p>
    </div>
  )
}

export default PerformanceBox;