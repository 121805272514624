import React, {useState, useRef } from 'react'
import useProfile, {IGeneralSetting} from '../hooks/useProfile';
import toast, {Toaster} from 'react-hot-toast';
import { changeUserName, changeUserPicture, srcToFile } from '../utils/apiService';
import { useAuth0 } from '@auth0/auth0-react';
import { validateName } from '../utils/inputValidation';
import { useNavigate } from 'react-router-dom';


const GeneralSetting = () => {
    const {setGeneralSetting, generalSetting} = useProfile();
    const [displayName, setDisplayName] = useState('');
    const [picture, setPicture] = useState<File | string>('');
    const [pictureFileName, setPictureFileName] = useState('');
    const hiddenInputRef = useRef<HTMLInputElement>(null);
    const {user} = useAuth0();
    const authToken = localStorage.getItem('token')
    const navigate = useNavigate();

    const handleImport = () => {
        hiddenInputRef.current?.click();
    }

    const handleSubmitPicture = async (e: React.MouseEvent) => {
        e.preventDefault();
        try {
            if(!picture || !(picture as File).name) {
                throw new Error('Please upload a valid image file')
            } 
            if((picture as File).type !== 'image/png' && (picture as File).type !== 'image/jpeg') {
                throw new Error('Please upload a valid PNG or JPEG image file')
            }
            if((picture as File).size > 6000000) {
                throw new Error('Please upload an image file less than 5MB')
            }

            if(user && user?.sub && authToken) {
                const pictureURL = await changeUserPicture({
                    clientId: user && user?.sub ? user?.sub?.split('|')[1] : '',
                    picture: picture
                }, authToken)
                if(!pictureURL) throw new Error('User picture change failed. Please try again.')
                setGeneralSetting((prev: IGeneralSetting) => ({
                    ...prev,
                    picture: pictureURL
                }))
                toast.success('User picture changed successfully')
                
            }
        } catch(error) {
            if((error as Error).message === 'EAuth0') {
                 localStorage.removeItem('token')
                 localStorage.removeItem('rfqid')
                navigate('/login')
                return;
              }
            toast.error((error as Error)?.message || 'User picture change failed. Please try again.')
        } finally {
            setPicture('')
            setPictureFileName('')
        }
    }

    const handleSubmitDisplayName = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            if(!displayName || !validateName(displayName)) {
                toast.error('Please enter a valid user name')
                return
            }
            if(user && user?.sub && authToken) {
                const res = await changeUserName({
                    clientId: user && user?.sub ? user?.sub?.split('|')[1] : '',
                    username: displayName
                }, authToken)
                if(res === 'True') {
                    toast.success('User name changed successfully')
                    setGeneralSetting((prev: IGeneralSetting) => ({
                        ...prev,
                        displayName
                    }))
                    
                }
            }
        } catch (error) {
            if((error as Error).message === 'EAuth0') {
                 localStorage.removeItem('token')
                 localStorage.removeItem('rfqid')
                navigate('/login')
                return;
            }
            toast.error((error as Error)?.message || 'Username change failed. Please try again.')
        } finally {
            setDisplayName('')
        }
    }

    const handleDeletePicture = async (e: React.MouseEvent) => {
        e.preventDefault();
        const blankPic = await srcToFile('/placeholderImg.png', 'placeholder.png', 'image/png')
        try {
            if(user && user?.sub && authToken) {
                const pictureURL = await changeUserPicture({
                    clientId: user && user?.sub ? user?.sub?.split('|')[1] : '',
                    picture: blankPic
                }, authToken)
                if(!pictureURL) throw new Error('User picture delete failed. Please try again.')
                toast.success('User picture deleted successfully')
                setGeneralSetting((prev: IGeneralSetting) => ({
                    ...prev,
                    picture: pictureURL
                }))
                setPicture('')
                setPictureFileName('')
            }

        } catch (error) {
            if((error as Error).message === 'EAuth0') {
                 localStorage.removeItem('token')
                 localStorage.removeItem('rfqid')
                navigate('/login')
                return;
            }
            toast.error((error as Error)?.message || 'Unable to delete user picture. Please try again.')
        } finally {
            setPicture('')
            setPictureFileName('')
        }
    }

    return (
        <div className="flex flex-col gap-[2.5rem] mt-[1.5rem]">
            <Toaster/>
            <div className="flex gap-[2rem] items-center">
                <div className="flex flex-col items-center justify-center gap-[1rem]">
                    <input 
                    type="file" 
                    accept={"image/png, image/jpeg"} 
                    onChange={(e) => {
                        if(!e.target?.files?.length) return;
                        const pic = e.target?.files[0] as File
                        if(pic?.type !== 'image/png' && pic?.type !== 'image/jpeg') {
                            toast.error('Please upload a valid image file')
                            return
                        }
                        if(pic?.size > 6000000) {
                            toast.error('Please upload an image file less than 5MB')
                            return
                        }
                        setPicture(pic)
                        setPictureFileName((pic as File)?.name.slice(0,15) + '...')
                    }} 
                    className="w-[15rem] hidden" 
                    ref={hiddenInputRef}
                    />
                    <div className="w-[6rem] flex flex-col gap-[1rem] justify-center items-center truncate">
                        <div className="w-[5rem] h-[5rem] border-[1px] border-chart-axis rounded-full overflow-hidden justify-center items-center flex">
                            <img src={generalSetting.picture ? generalSetting.picture : 'placeholderImg.png'} alt="profile" className="w-full h-full object-cover"/>
                        </div>
                        <button type="button" onClick={handleImport} className="border-black border-[1px] bg-bg-import-btn rounded-[6px] px-[1rem] text-xxs">Import</button>
                        <p className="text-xxs self-start">{pictureFileName}</p>
                    </div>
                </div>  
                <div className="flex flex-col md:flex-row md:-mt-[3.5rem] md:gap-[2rem] gap-[0.5rem]">
                    <button type="button" onClick={handleSubmitPicture} className="border-primary-blue bg-bg-profile-picture border-[1px] min-h-[2.5rem] rounded-[10px] px-[1rem] text-xxs md:textsm">Upload new picture</button>
                    <button type="button" onClick={handleDeletePicture} className="rounded-[10px] border-[1px] border-border-profile-delete bg-bg-picture-delete h-[2.5rem] md:w-[4.6875rem] font-[400] px-[1rem] text-xxs md:textsm">Delete</button>
                </div>
            </div>
            <form className="flex flex-col gap-[2.125rem]" onSubmit={handleSubmitDisplayName}>
                <label htmlFor="name">
                    <p className="text-xs mb-[0.5rem] text-rfq-label-text">Display name</p>
                    <input type="text" id="name" name="name" value={displayName} placeholder="Enter your display name" onChange={(e) => setDisplayName(e.target.value)} className="w-[25rem] h-[3rem] border-[1px] border-bg-profile-setting rounded-[10px] pl-[1rem] text-lg"/>
                </label>
                <button type="submit" className="bg-primary-blue text-white text-xs w-[9.31rem] h-[3.5rem] rounded-[10px] shadow-[0px_6px_16px_5px_rgba(58,49,45,0.16)]">Save changes</button>
            </form>
        </div>
    )
}

export default GeneralSetting;