import React,{useState, useEffect} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import { getPriceSimulationData, getLatestPrice } from '../utils/apiService';
import toast, { Toaster } from 'react-hot-toast';
import usePricing from '../hooks/usePricing';
import { priceFormatWithDecimals } from '../utils/numberFormat';
import SubmitButton from './SubmitButton';
import {COLLAR, hedgingMethod, INSTRUMENTS_WITHOUT_STRIKE, SPOT, ZERO_COST_COLLAR} from '../utils/instruments';
import { rollFrequency, strikeOffsetCallFormField, strikeOffsetPutFormField} from '../utils/formFieldsMapping';
import { capFirstLetter } from '../utils/chartUtils';
import { useNavigate } from 'react-router-dom';
import { displayedSymToValue, extractCryptoCcyFromPair } from '../utils/syms';
import { validateRevenue, validateStrikeOffset } from '../utils/inputValidation';

interface PricingFormProps {
    chartRef:React.RefObject<HTMLDivElement>;
}

const PricingForm = ({chartRef}: PricingFormProps) => {
    const [sym, setSym] = useState<string>('');
    const [revenue, setRevenue] = useState<number|string>(50);
    const [method, setMethod] = useState('');
    const [strikeOffset, setStrikeOffset] = useState<number|string>(0);
    const [strikeOffsetCall, setStrikeOffsetCall] = useState<number|string>(0);
    const [frequency, setFrequency] = useState('')
    const [methodCompare, setMethodCompare] = useState('');
    const [strikeOffsetCompare,setStrikeOffsetCompare] = useState<number|string>(0);
    const [strikeOffsetCallCompare, setStrikeOffsetCallCompare] = useState<number|string>(0);
    const [frequencyCompare, setFrequencyCompare] = useState('')
    const {setPricingChartData, setPricingTable, compareCheck, setCompareCheck, setPricingExplanation, setPricingFormValues} = usePricing();
    const {user} = useAuth0();
    const [latestPrice, setLatestPrice] = useState(0);
    const [authToken] = useState<string| null>(localStorage.getItem('token'))
    const navigate = useNavigate()

    useEffect(() => {
        if(!sym || !authToken) {
            return;
        }
        getLatestPrice(sym, authToken)
        .then(price => setLatestPrice(price?.toFixed(2)))
        .catch(error => {
            toast.error(error.message || 'Failed to get latest price.')
        })
    },[sym, authToken])

    // Function to reset form to default value if necessary
    // const resetForm = () => {
    //     setSym('');
    //     setRevenue(50);
    //     setMethod('');
    //     setStrikeOffset(10);
    //     setFrequency('');
    //     setMethodCompare('');
    //     setStrikeOffsetCompare(10);
    //     setFrequencyCompare('');
    // }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(!sym) {
            toast.error('Please select a token');
            return;
        }
        if(!validateRevenue(Number(revenue))) {
            toast.error('Please enter a valid revenue number');
            return;
        }
        if(!method) {
            toast.error('Please enter hedging method');
            return;
        }
        if(!frequency) {
            toast.error('Please enter hedging frequency');
            return;
        }
        if(method !== SPOT) {
            if(!validateStrikeOffset(Number(strikeOffset))) {
                toast.error('Please enter a valid strike offset value')
                return
            }
        }
        if(compareCheck) {
            if(!methodCompare) {
                toast.error('Please enter hedging method to compare');
                return;
            }
            if(!frequencyCompare) {
                toast.error('Please enter hedging frequency to compare');
                return;
            }
            if(!INSTRUMENTS_WITHOUT_STRIKE.includes(methodCompare)) {
                if(!validateStrikeOffset(Number(strikeOffsetCompare))) {
                    toast.error('Please enter a valid strike offset value to compare')
                    return
                }
            }
        }
        
        try {
            if(user && authToken) {
                const {pricingChartData, pricingTable, pricingExplanationText} = await getPriceSimulationData({
                    clientId: user.sub?.split('|')[1] as string,
                    sym,
                    revenue:Number(revenue),
                    method,
                    strikeoffset: strikeOffset ? Number(strikeOffset)/100 : 0,
                    strikeoffsetcall: strikeOffsetCall? Number(strikeOffsetCall)/100:0,
                    frequency:frequency.toString(),
                    methodcompare: compareCheck ? methodCompare : '',
                    strikeoffsetcompare: compareCheck ? Number(strikeOffsetCompare)/100 : 0,
                    frequencycompare: compareCheck ? frequencyCompare.toString() : '',
                    strikeoffsetcallcompare: compareCheck ? Number(strikeOffsetCallCompare)/100 : 0,
                }, authToken)
                setPricingChartData(pricingChartData);
                setPricingTable(pricingTable)
                setPricingExplanation(pricingExplanationText)
                setPricingFormValues({
                    method: {
                        sym,
                        revenue,
                        method,
                        strikeOffset,
                        strikeOffsetCall,
                        frequency,
                    },
                    methodCompare: {
                        method: methodCompare,
                        strikeOffset:strikeOffsetCompare,
                        strikeOffsetCall:strikeOffsetCallCompare,
                        frequency:frequencyCompare,
                    },
                    pricingFormSubmitted:true,
                    compareCheck: compareCheck,
                })
                chartRef.current?.scrollIntoView({behavior: 'smooth', block:'end'})
                // resetForm();
            } else {
                throw new Error('No user id found');
            }
        } catch (error) {
            if((error as Error).message === 'EAuth0') {
                 localStorage.removeItem('token')
                navigate('/login')
                return;
              }
            toast.error((error as Error).message || 'Failed to get pricing data');
        }
    }

  return (
    <form className="flex flex-col gap-[2.5rem] pt-[1.875rem] px-[2.375rem] md:mx-[2.875rem] rounded-[6px] bg-white shadow-[2px_3px_30px_10px_rgba(222,235,241,0.35)] md:mt-[2.25rem] md:ml-[2.875rem]" onSubmit={handleSubmit}>
        <Toaster />
        <h3 className="font-[600] text-xlg text-backtest-header-text">{`Let's Start Hedging`}</h3>
        <div className="flex flex-col md:grid md:grid-cols-2 gap-[2.75rem]">
            <div className="flex flex-col gap-[2.75rem] w-[90%]">   
                <label htmlFor='sym'>
                    <p className="text-rfq-label-text">Token</p>
                    <select id="sym" className="text-rfq-value-text mt-[1rem] w-full border-b-[#E6EBEE] border-b-[1px]" onChange={(e) => setSym(e.target.value)} value={sym} required>
                        <option value="">Select</option>
                        {
                            displayedSymToValue.map((sym) => (<option key={sym.value} value={sym.value}>{sym.symbol}</option>))
                        }
                    </select>
                </label>
                <label htmlFor='revenue'>
                    <p className="text-rfq-label-text">{`Monthly Revenue (in ${extractCryptoCcyFromPair(sym)})`}</p>
                    <input id='revenue' type="number" min={0} placeholder={"50"} value={revenue} onChange={(e) => setRevenue(e.target.value)} className="border-b-[#E6EBEE] border-b-[1px] w-full text-rfq-value-text mt-[1rem]"/>
                </label>
                <label htmlFor='method'>
                    <p className="text-rfq-label-text">Hedging Method</p>
                    <select id="method" className="text-rfq-value-text mt-[1rem] w-full border-b-[#E6EBEE] border-b-[1px]" onChange={(e) => setMethod(e.target.value)} value={method}>
                        <option value="">Select</option>
                        {
                            hedgingMethod.map((method) => (<option key={method.value} value={method.value}>{method.name}</option>))
                        }
                    </select>
                </label>
                <label htmlFor='frequency'>
                    <p className="text-rfq-label-text">Frequency </p>
                    <select id='frequency' className="text-rfq-value-text mt-[1rem] w-full border-b-[#E6EBEE] border-b-[1px]" onChange={(e) => setFrequency(e.target.value)} value={frequency}>
                        <option value="">Select</option>
                        {method === SPOT && <option value="weekly">Weekly</option>}
                        {
                            rollFrequency.map((frequency) => (<option key={frequency} value={frequency}>{capFirstLetter(frequency)}</option>))
                        }
                    </select>
                </label>
                <label htmlFor='strikeOffset' className={`${INSTRUMENTS_WITHOUT_STRIKE.includes(method) ? 'opacity-0': ''} ${INSTRUMENTS_WITHOUT_STRIKE.includes(methodCompare) && INSTRUMENTS_WITHOUT_STRIKE.includes(method) ? 'hidden': ''}`}>
                    <p className="text-rfq-label-text pb-[0.5rem]">{`Current ${extractCryptoCcyFromPair(sym)} Price: ${priceFormatWithDecimals(latestPrice)}`}</p>
                    <p className="text-rfq-label-text">{strikeOffsetPutFormField}</p>
                    <input id="strikeOffset" type="number" min={0} max={100} placeholder={"10"} value={strikeOffset} onChange={(e) => setStrikeOffset(e.target.value)} className="text-rfq-value-text mt-[1rem] border-b-[#E6EBEE] border-b-[1px] w-full"/>
                </label>
                <label htmlFor='strikeOffsetCall' className={`${method!=COLLAR ? 'opacity-0': ''} ${method!=COLLAR && methodCompare!=COLLAR ? 'hidden': ''}`}>
                    <p className="text-rfq-label-text">{strikeOffsetCallFormField}</p>
                    <input id='strikeOffsetCall' type="number" min={0} placeholder={"10"} value={strikeOffsetCall} onChange={(e) => setStrikeOffsetCall(e.target.value)} className="text-rfq-value-text mt-[1rem] border-b-[#E6EBEE] border-b-[1px] w-full"/>
                </label>
            </div>
            <div className="flex flex-col gap-[2.75rem] w-[90%]">
                <div className="flex flex-col flex-1">
                    <label className="flex gap-[2rem]">
                        <input type="checkbox" checked={compareCheck} onChange={(e) => setCompareCheck(e.target.checked)}/>
                        <p className="text-rfq-value-text font-[500]">Check if you want to compare with another hedging method</p>
                    </label>
                </div>
                {compareCheck && (
                        <>
                        {/* This is a dummy component to align the form */}
                    <label htmlFor='methodCompare'>
                        <p className="text-rfq-label-text">Hedging Method</p>
                        <select id='methodCompare' className="text-rfq-value-text mt-[1rem] w-full border-b-[#E6EBEE] border-b-[1px]" onChange={(e) => setMethodCompare(e.target.value)} value={methodCompare}>
                            <option value="">Select</option>
                            <option value="forward">Forward Selling</option>
                            <option value="put">Put Option</option>
                            <option value="zerocostcollar">Zero Cost Collar</option>
                            <option value="collar">Collar</option>
                            <option value="spot">Selling at Market Price</option>
                        </select>
                    </label>
                    <label htmlFor='frequencyCompare'>
                        <p className="text-rfq-label-text">Frequency</p>
                        <select id='frequencyCompare' className="text-rfq-value-text mt-[1rem] w-full border-b-[#E6EBEE] border-b-[1px]" onChange={(e) => setFrequencyCompare(e.target.value)} value={frequencyCompare}>
                            <option value="">Select</option>
                            {methodCompare === SPOT && <option value="weekly">Weekly</option>}
                            {
                                rollFrequency.map((frequency) => (<option key={frequency} value={frequency}>{capFirstLetter(frequency)}</option>))
                            }
                        </select>
                    </label>
                    <label htmlFor='strikeOffsetCompare' className={`${INSTRUMENTS_WITHOUT_STRIKE.includes(methodCompare) ? 'opacity-0': ''} ${INSTRUMENTS_WITHOUT_STRIKE.includes(methodCompare) && INSTRUMENTS_WITHOUT_STRIKE.includes(method) ? 'hidden': ''}`}>
                        <p className="text-rfq-label-text pb-[0.5rem]">{`Current ${extractCryptoCcyFromPair(sym)} Price: ${priceFormatWithDecimals(latestPrice)}`}</p>
                        <p className="text-rfq-label-text">{strikeOffsetPutFormField}</p>
                        <input id='strikeOffsetCompare' type="number" min={0} max={100} placeholder={"10"} value={strikeOffsetCompare} onChange={(e) => setStrikeOffsetCompare(e.target.value)} className="text-rfq-value-text mt-[1rem] border-b-[#E6EBEE] border-b-[1px] w-full"/>
                    </label>
                    <label htmlFor='strikeOffsetCallCompare' className={`${methodCompare!=COLLAR ? 'opacity-0': ''} ${method!=COLLAR && methodCompare!=COLLAR ? 'hidden': ''}`}>
                        <p className="text-rfq-label-text">{strikeOffsetCallFormField}</p>
                        <input id='strikeOffsetCallCompare' type="number" min={0} placeholder={"10"} value={strikeOffsetCallCompare} onChange={(e) => setStrikeOffsetCallCompare(e.target.value)} className="text-rfq-value-text mt-[1rem] border-b-[#E6EBEE] border-b-[1px] w-full"/>
                    </label>
                </>
                )}
            </div>
        </div>
        <SubmitButton btnText={'Submit'}/>
    </form>
  )
}

export default PricingForm;