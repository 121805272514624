import React,{useEffect, useState} from 'react'
import Header from '../components/Header';
import SideMenu from '../components/SideMenu';
import { useAuth0 } from '@auth0/auth0-react';
import {useNavigate, useLocation} from 'react-router-dom';
import useMenu, {Selected} from '../hooks/useMenu';
import MobileSideMenu from '../components/MobileSideMenu';
import MobileHeader from '../components/MobileHeader';
import useOverview from '../hooks/useOverview';
import { domain, getRegistrationStatus } from '../utils/apiService';
import Loading from '../components/Loading';

const Docs = () => {
  const {isAuthenticated, isLoading, user, getAccessTokenSilently} = useAuth0();
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const {setSelected, showMobileMenu} = useMenu();
  const [authToken, setAuthToken] = useState<string | null>(localStorage.getItem('token'))
  const {setIsRegistered} = useOverview();
  const [isSubscribed, setSubscribed] = useState<boolean>(false);


  useEffect(() => {
    if(pathname === '/docs') {
      setSelected(Selected.Docs)
    }
  },[pathname, setSelected])

  useEffect(() => {
    if(!authToken && !isLoading && user) {
        getAccessTokenSilently({
            ignoreCache: true,
            audience: `https://${domain}/api/v2/`,
        }).then(tk => {
          setAuthToken(tk)
          localStorage.setItem('token', tk)
        })
    }
  },[authToken, getAccessTokenSilently, isLoading, user])

  useEffect(() => {
    if(isAuthenticated && user && !isLoading && authToken) {
      getRegistrationStatus(user?.sub?.split('|')[1] as string, authToken as string)
      .then(st => {
        if(st.isregistered === 'true') {
            setIsRegistered(true)
          if(st.stripestatus === 'paid') {
            // user has registered
            setSubscribed(true)
            return;
          } else  {
          // user has not paid
            navigate('/loading')
            window.open('/subscribe', '_blank')
          } 
        } else {
          // user has not registere
          navigate('/register');
        }
    })
      .catch(err => {
        if(err.message === 'EAuth0') {
          localStorage.removeItem('token')
          localStorage.removeItem('rfqid')
        }
        navigate('/login')
      });
    } else if (!isLoading && !isAuthenticated) {
      // user is not authenticated or user is not defined
      navigate('/login');
    }
  },[authToken, isAuthenticated, isLoading, navigate, user, setIsRegistered])

  if(isLoading || !authToken || !user|| !isAuthenticated || !isSubscribed){
    return <Loading />
  }


  return (
    <main className="h-full flex">
    <SideMenu />
    {showMobileMenu && <MobileSideMenu />}
    <div className="flex flex-col bg-bg-dashboard w-full">
      <Header headerText={"Docs"}/>
      <MobileHeader/>
    </div>
  </main>
  )
}

export default Docs;
