import React,{useState} from 'react'
import useProfile, { IEmailSetting } from '../hooks/useProfile';
import toast, {Toaster} from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { changeUserEmail } from '../utils/apiService';
import { validateEmail } from '../utils/inputValidation';
import { useNavigate } from 'react-router';

const EmailSetting = () => {
    const {user} = useAuth0();
    const {emailSetting, setEmailSetting} = useProfile();
    const [currentEmail,setCurrentEmail] = useState( emailSetting.currentEmail ? emailSetting.currentEmail : user?.email);
    const [newEmail,setNewEmail] = useState('');
    const [password,setPassword] = useState('');
    const authToken = localStorage.getItem('token')
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();
        try {
            if(!currentEmail || !validateEmail(currentEmail) || currentEmail !== (emailSetting.currentEmail || user?.email)) {
                toast.error('Please enter a valid current email')
                return
            }

            if(!newEmail || !validateEmail(newEmail)) {
                toast.error('Please enter a valid new email')
                return
            }

            if(!password) {
                toast.error('Please enter a valid password')
                return
            }

            if(user && user?.sub && authToken) {
                const res = await changeUserEmail({
                    clientId: user && user?.sub ? user?.sub?.split('|')[1] : '',
                    email: newEmail
                }, authToken)
                if(res === 'True') {
                    toast.success('Email changed successfully')
                    setEmailSetting((prev: IEmailSetting) => ({
                        ...prev,
                        currentEmail: newEmail
                    }))
                }
            }
        } catch (error) {
            if((error as Error).message === 'EAuth0') {
                 localStorage.removeItem('token')
                navigate('/login')
                return;
            }
            toast.error((error as Error)?.message || 'Failed to change user email. Please try again.')
        } finally {
            setCurrentEmail('')
            setNewEmail('')
            setPassword('')
        }
    }

    return (
        <form onSubmit={handleSubmit} className="flex flex-col gap-[1.5rem] mt-[1.5rem] w-[100mv] pr-[1rem]">
            <Toaster/>
            <label htmlFor="current-email" className="flex flex-col gap-[0.5rem]">
                <p className="text-xs mb-[0.5rem] text-rfq-label-text">Current Email</p>
                <input 
                    type="email" 
                    id="current-email" 
                    name="current-email" 
                    placeholder={"email@example.com"} 
                    onChange={(e) => setCurrentEmail(e.target.value)} 
                    value={currentEmail} 
                    className="w-full md:w-[25rem] h-[3rem] border-[1px] border-bg-profile-setting rounded-[10px] pl-[1rem] text-lg"
                />
            </label>
            <label htmlFor="new-email">
                <p className="text-xs mb-[0.5rem] text-rfq-label-text">New Email</p>
                <input 
                    type="email" 
                    id="new-email" 
                    name="new-email" 
                    placeholder={"email@example.com"} 
                    onChange={(e) => setNewEmail(e.target.value)} 
                    value={newEmail} 
                    className="w-full md:w-[25rem] h-[3rem] border-[1px] border-bg-profile-setting rounded-[10px] pl-[1rem] text-lg"
                />
            </label>
            <label htmlFor="password">
                <p className="text-xs mb-[0.5rem] text-rfq-label-text">Password</p>
                <input 
                    type="password" 
                    id="password" 
                    name="password" 
                    placeholder={"Enter your password"} 
                    onChange={(e) => setPassword(e.target.value)} 
                    value={password} 
                    className="w-full md:w-[25rem] h-[3rem] border-[1px] border-bg-profile-setting rounded-[10px] pl-[1rem] text-lg"
                />
            </label>
            <button className="bg-primary-blue text-white text-xs w-[9.31rem] h-[3.5rem] rounded-[10px] shadow-[0px_6px_16px_5px_rgba(58,49,45,0.16)]">Save changes</button>
        </form>
    )
}

export default EmailSetting;