import React from 'react';
import PopUpBox from './PopUpBox';
import { formatDate } from '../utils/numberFormat';
import useRFQ, { singleRecentQuote } from '../hooks/useRFQ';
import TradeButton from './TradeButton';
import Clipboard from './Clipboard';


export const TradeSummaryTable = ({quote}:{quote:singleRecentQuote}) => {
    const {QuoteId, Date, Counterparty, Notional, BidPrice, AskPrice, FairPrice} = quote;

    return (
        <table className="border-[1px] border-[#EBF2F5] p-[1rem] mb-3">
            <tr>
                <th className="text-start px-3 border-[1px] border-[#EBF2F5]">Quote Id</th>
                <td className="px-3 border-[1px] border-[#EBF2F5] text-center">{QuoteId}</td>
            </tr>
            <tr>
                <th className="text-start px-3 border-[1px] border-[#EBF2F5]">Date</th>
                <td className="px-3 border-[1px] border-[#EBF2F5] text-center">{formatDate(Date as number|string)}</td>
            </tr>
            <tr>
                <th className="text-start px-3 border-[1px] border-[#EBF2F5]">Market Maker</th>
                <td className="px-3 border-[1px] border-[#EBF2F5] text-center">{Counterparty}</td>
            </tr>
            <tr>
                <th className="text-start px-3 border-[1px] border-[#EBF2F5]">Size</th>
                <td className="pl-1 border-[1px] border-[#EBF2F5] text-center">{Notional}</td>
            </tr>
            <tr>
                <th className="text-start px-3 border-[1px] border-[#EBF2F5]">Bid Price</th>
                <td className="pl-1 border-[1px] border-[#EBF2F5] text-center">{BidPrice && typeof BidPrice === 'number' ? `$${BidPrice.toFixed(4)}` : '0.0'}</td>
            </tr>
            <tr>
                <th className="text-start px-3 border-[1px] border-[#EBF2F5]">Ask Price</th>
                <td className="pl-1 border-[1px] border-[#EBF2F5] text-center">{AskPrice && typeof AskPrice === 'number' ? `$${AskPrice.toFixed(4)}` : '0.0'}</td>
            </tr>
            <tr>
                <th className="text-start px-3 border-[1px] border-[#EBF2F5]">Fair Price</th>
                <td className="pl-1 border-[1px] border-[#EBF2F5] text-center">{FairPrice && typeof FairPrice === 'number' ? `$${FairPrice?.toFixed(4)}` : '0.0'}</td>
            </tr>
        </table>
    )
}

const TradeBox = () => {
    const {popUp, setPopup, selectedQuote, popUpContent} = useRFQ()
    const {title, message, status, telegramlink, button } = popUpContent
    const {QuoteId} = selectedQuote;
    const submitTradeToTelegram = async () => {
        if(!QuoteId) {
            return;
        }
        window.open(telegramlink, '_blank')
    }

    const cancelTrade = () => {
        setPopup(false);
    }

    const formatMessage = (message:string, status:string) => {
        if(!message) return 'No message to display.'
        if(status === 'expired') {
            return 'This quote has expired. Please submit a new RFQ for a new quote.'
        }
        return message;
    }

        return (
            <PopUpBox popUp={popUp} setPopup={setPopup}>
                <div className="flex flex-col mr-[20rem] rounded-[10px] z-20 bg-white" id="main_popup_container">
                    <div className="w-full relative">
                        <div className="absolute w-[1.5rem] h-[1.5rem] -right-1 -top-1 cursor-pointer" onClick={() => setPopup(false)}>
                            <img src="close.png" alt="" className="absolute" />
                        </div>
                    </div>
                    <form 
                        id={'popUpTradeForm'}
                        className="flex flex-col w-[40rem] mt-[1.5rem] px-[1.5rem] gap-[1rem] justify-between items-center h-full" 
                    >
                        <h1 className="text-mlg font-[500] text-center px-[1rem] mb-[1rem] bg-bg-import-icon py-[1rem] rounded-xl"
                            dangerouslySetInnerHTML={{__html: title || 'No title to display'}}

                        />
                        <h2 className="text-sm font-[500] text-left px-[1rem] mb-[1rem] text-white italic bg-red-500 bg-opacity-40">
                            This quote is indicative, you will need to confirm it with the market maker when you submit your trade.
                        </h2>
                        <Clipboard text={formatMessage(message,status)}/>
                        <TradeSummaryTable quote={selectedQuote}/>
                        <div className="flex gap-[1rem]">
                            {button !== 'NA' ? <TradeButton btnText={button} btnClick={submitTradeToTelegram} disabled={status === 'expired'? true : false}/> : null}
                            <TradeButton btnText={'Cancel'} btnClick={cancelTrade} cancel={true}/>
                        </div>
                    </form>
                </div>  
            </PopUpBox>
        )
}

export default TradeBox;