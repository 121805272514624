import React, {useContext, createContext, useState, useEffect} from 'react';
import { getRFQById, getRFQList, getRecentQuoteList, IQuoteResponse, getPopUpContent } from '../utils/apiService';
import { useAuth0 } from '@auth0/auth0-react';
import toast, {Toaster} from 'react-hot-toast';
import useOverview from './useOverview';

export interface IRFQForm {
    sym: string,
    client_id: string,
    fair_price: number | string,
    date:Date | string,
    hedging_method: string,
    strike_offset:string|number,
    strike_offset_call:string|number,
    rfq_id: string,
    spot_price: number | string,
    status: string,
    timestamp:number,
    notional: number | string,
    maturity: string;
}

export interface SingleRFQ {
    date: Date | number | string,
    entity: string,
    bidPrice: number | string,
    askPrice: number | string,
    notional: number,
    fairPrice: number | string,
    quoteid: string,
}

export interface singleRecentQuote {
    Date: Date | number |string,
    Product: string,
    Notional:number |string,
    Counterparty:string,
    FairPrice:number|string,
    BidPrice:number|string,
    AskPrice:number|string,
    QuoteId:string,
    Timestamp: number | Date | string,
}

export interface RFQProps {
    RFQFormDetails: IRFQForm,
    setRFQFormDetails: React.Dispatch<React.SetStateAction<IRFQForm>>,
    RFQFormSubmitted: boolean,
    setRFQFormSubmitted: React.Dispatch<React.SetStateAction<boolean>>,
    RFQList: SingleRFQ[],
    setRFQList: React.Dispatch<React.SetStateAction<SingleRFQ[]>>,
    RFQId: string,
    setRFQId: React.Dispatch<React.SetStateAction<string>>,
    recentQuoteList: singleRecentQuote[],
    setRecentQuoteList: React.Dispatch<React.SetStateAction<singleRecentQuote[]>>
    recentQuoteCols: string[],
    setRecentQuoteCols: React.Dispatch<React.SetStateAction<string[]>>,
    showRecentQuoteTable: boolean,
    setShowRecentQuoteTable: React.Dispatch<React.SetStateAction<boolean>>,
    popUp: boolean,
    setPopup: React.Dispatch<React.SetStateAction<boolean>>,
    popUpContent: IQuoteResponse,
    setPopUpContent: React.Dispatch<React.SetStateAction<IQuoteResponse>>,
    selectedQuote: singleRecentQuote,
    setSelectedQuote: React.Dispatch<React.SetStateAction<singleRecentQuote>>,
    submitTrade: (quote: singleRecentQuote | string) => Promise<void>,
}

const emptyQuote: singleRecentQuote = {
    Date: '',
    Product: '',
    Notional: '',
    Counterparty: '',
    FairPrice: '',
    BidPrice: '',
    AskPrice: '',
    QuoteId: '',
    Timestamp: '',
}

const initialFormDetails = {
    sym: '',
    client_id: '',
    fair_price: '',
    date:new Date(),
    hedging_method: '',
    strike_offset:'',
    strike_offset_call:'',
    rfq_id: '',
    spot_price: '',
    status: '',
    timestamp: Date.now(),
    notional: '',
    maturity: '',
}

const RFQContext = createContext<RFQProps>({
    RFQFormDetails: initialFormDetails,
    setRFQFormDetails: () => {},
    RFQFormSubmitted: false,
    setRFQFormSubmitted: () => {},
    RFQList: [],
    setRFQList: () => {},
    RFQId: '',
    setRFQId: () => {},
    recentQuoteList: [],
    setRecentQuoteList: () => {},
    recentQuoteCols: [],
    setRecentQuoteCols: () => {},
    showRecentQuoteTable: false,
    setShowRecentQuoteTable: () => {},
    popUp: false,
    setPopup: () => {},
    selectedQuote: emptyQuote,
    setSelectedQuote: () => {},
    submitTrade: () => Promise.resolve(),
    popUpContent: {
        title: '',
        message: '',
        telegramlink:'',
        status: '',
        button: ''
    },
    setPopUpContent: () => {},
})

export const RFQProvider = ({children}:{children: React.ReactNode}) => {
    const [RFQFormDetails, setRFQFormDetails] = useState(initialFormDetails as IRFQForm)
    const [RFQList, setRFQList] = useState<SingleRFQ[]>([])
    const [RFQId, setRFQId] = useState<string>(localStorage.getItem('rfqid') || '')
    const [recentQuoteList, setRecentQuoteList] = useState<singleRecentQuote[]>([])
    const [recentQuoteCols, setRecentQuoteCols] = useState<string[]>([])
    const [showRecentQuoteTable, setShowRecentQuoteTable] = useState<boolean>(false)
    const {user, isLoading} = useAuth0()
    const [popUp, setPopup] = useState<boolean>(false)
    const [popUpContent, setPopUpContent] = useState<IQuoteResponse>({
        title: '',
        message: '',
        telegramlink:'',
        status: '',
        button: ''
    })
    const [selectedQuote, setSelectedQuote] = useState<singleRecentQuote>(emptyQuote)
    const [authToken] = useState(localStorage.getItem('token'))
    const [RFQFormSubmitted, setRFQFormSubmitted] = useState<boolean>(false)
    const rfqFormSubmittedLocalStorage = localStorage.getItem('rfqformsubmitted')
    const {symArray} = useOverview()


    const submitTrade = async(quote: singleRecentQuote | string) => {   
        if(!quote || typeof quote === 'string' || !authToken) {
            return
        }
        await getPopUpContent({
            clientId: user?.sub?.split('|')[1] as string,
            rfqId: quote.QuoteId,
        },authToken).then(res => {
            setPopUpContent(res)
        }).catch(err => {
            throw new Error(err)
        })
        setSelectedQuote(quote)
        setPopup(true)
    }

    useEffect(() => {
        if(!!RFQId) {
            setRFQFormSubmitted(true)
        }
    },[RFQId])

    useEffect(() => {
        if(rfqFormSubmittedLocalStorage === 'true') {
            setRFQFormSubmitted(true)
        }
    },[rfqFormSubmittedLocalStorage])

    // get single RFQ details
    useEffect(() => {
        try {
            if(user && RFQId && !isLoading && authToken && RFQFormSubmitted) {
                getRFQById({
                    clientId: user?.sub?.split('|')[1] as string,
                    rfqId: RFQId
                }, authToken).then(res => {
                    const params = JSON.parse(res.parameters)
                    const strike_offset =  params.strike_offset
                    const strike_offset_call = params.strike_offset_call
                    setRFQFormDetails({
                        ...res,
                        strike_offset:Number(strike_offset)*100,
                        strike_offset_call:Number(strike_offset_call)*100,
                        date: new Date(res.date),
                        timestamp: res.timestamp.toString().length === 10 ? Number(res.timestamp)*1000 : res.timestamp,
                    })
                })
            }
        } catch (error) {
            if((error as Error).message === 'EAuth0') {
                localStorage.removeItem('token')
                localStorage.removeItem('rfqid')
                return;
              }
            toast.error('No RFQ details received')
        }
    },[user, RFQId, isLoading, authToken, RFQFormSubmitted])

    // get recent quote list
    useEffect(() => {
        if(!symArray || symArray.length === 0) {
            return;
        }
        try{
            if(user && !isLoading && symArray && authToken && RFQFormSubmitted) {
                getRecentQuoteList({
                    clientId: user?.sub?.split('|')[1] as string,
                    symArray: symArray.map(item => item.value),
                }, authToken).then(res => {
                    if(res) {
                        const {recentQuoteList, recentQuoteCols} = res
                        setRecentQuoteList(recentQuoteList)
                        setRecentQuoteCols(recentQuoteCols)
                    }
                }).catch(err => {
                    if((err as Error).message === 'EAuth0') {
                        localStorage.removeItem('token')       
                        localStorage.removeItem('rfqid')
                        window.location.href = window.location.origin + '/login'
                        return;
                      }
                    toast.error(err.message)
                    throw new Error(err.message)
                })
            }
        } catch (error) {
            if((error as Error).message === 'EAuth0') {
                localStorage.removeItem('token')
                localStorage.removeItem('rfqid')
                window.location.href = window.location.origin + '/login'
                return;
              }
            toast.error('No recent quote list received')
        }

    },[symArray, user, isLoading, authToken, RFQFormSubmitted])

    // get RFQ list
    useEffect(() => {
        if(user && RFQFormDetails?.fair_price !== undefined && RFQId && !isLoading && authToken && RFQFormSubmitted) {
            getRFQList({
                clientId: user?.sub?.split('|')[1] as string,
                rfqId: RFQId,
                fairPrice: RFQFormDetails.fair_price
            }, authToken).then(res => {
                setRFQList(res)
            }).catch(err => {
                if((err as Error).message === 'EAuth0') {
                    localStorage.removeItem('token')
                    localStorage.removeItem('rfqid')
                    window.location.href = window.location.origin + '/login'
                    return;
                  }
                toast.error(err.message)
                throw new Error(err.message)
            })
        } 
    },[user, RFQFormDetails, RFQId, isLoading, authToken, RFQFormSubmitted])

    // logic for re-query recent Quote list
    useEffect(() => {
        let firstInterval: ReturnType<typeof setInterval>
        let secondInterval: ReturnType<typeof setInterval>
        let secondTimeout: ReturnType<typeof setTimeout>
        let thirdTimeout: ReturnType<typeof setTimeout>

        try{
            if(user && !isLoading && symArray && authToken && RFQFormSubmitted) {
                // query every 1 min for the first 20 min
                firstInterval = setInterval(() => {
                    getRecentQuoteList({
                        clientId: user?.sub?.split('|')[1] as string,
                        symArray: symArray.map(item => item.value),
                    }, authToken).then(res => {
                        if(res) {
                            const {recentQuoteList, recentQuoteCols} = res
                            setRecentQuoteList(recentQuoteList)
                            setRecentQuoteCols(recentQuoteCols)
                        }
                    }).catch(err => {
                        if((err as Error).message === 'EAuth0') {
                            localStorage.removeItem('token')
                            localStorage.removeItem('rfqid')
                            window.location.href = window.location.origin + '/login'
                            return;
                          }
                        toast.error(err.message)
                        throw new Error(err.message)
                    })
                },1000*60)

                // delay for the 1st 20min, query every 5 min for the next 1 hour 
                secondTimeout = setTimeout(() => {
                    clearInterval(firstInterval)
                    secondInterval = setInterval(() => {
                        getRecentQuoteList({
                            clientId: user?.sub?.split('|')[1] as string,
                            symArray: symArray.map(item=> item.value),
                        }, authToken).then(res => {
                            if(res) {
                                const {recentQuoteList, recentQuoteCols} = res
                                setRecentQuoteList(recentQuoteList)
                                setRecentQuoteCols(recentQuoteCols)
                            }
                        }).catch(err => {
                            if((err as Error).message === 'EAuth0') {
                                localStorage.removeItem('token')
                                localStorage.removeItem('rfqid')
                                window.location.href = window.location.origin + '/login'
                                return;
                              }
                            toast.error(err.message)
                            throw new Error(err.message)
                        })
                    },1000*60*5)
                },1000*60*20)

                // delay for 1 hour 20 min, clear timer
                thirdTimeout = setTimeout(() => {
                    clearTimeout(secondTimeout)
                    clearInterval(secondInterval)
                },1000*60*60+ 1000*60*20)
            }

            return () => {
                clearTimeout(secondTimeout)
                clearTimeout(thirdTimeout)
                clearInterval(firstInterval)
                clearInterval(secondInterval)
            }

        } catch (error) {
            toast.error('No recent quote list received')
        }
    },[user, symArray, isLoading, authToken, RFQFormSubmitted])

    // logic for re-query RFQ list
    useEffect(() => {
        let firstInterval: ReturnType<typeof setInterval> 
        let secondInterval: ReturnType<typeof setInterval> 
        let thirdInterval: ReturnType<typeof setInterval> 
        let secondTimeout: ReturnType<typeof setTimeout>
        let thirdTimeout: ReturnType<typeof setTimeout> 
        let fourthTimeout: ReturnType<typeof setTimeout>
        
        try{
            if(user && RFQFormDetails?.fair_price !== undefined && RFQId && !isLoading && authToken && RFQFormSubmitted) {
                    // 1st 1 minute, query every 5 seconds
                firstInterval = setInterval(() => {
                    if(Date.now() <RFQFormDetails.timestamp + 1000*60) {
                        getRFQList({
                            clientId: user?.sub?.split('|')[1] as string,
                            rfqId: RFQId,
                            fairPrice: RFQFormDetails?.fair_price
                        }, authToken).then(res => {
                            setRFQList(res)
                        }).catch(err => {
                            if((err as Error).message === 'EAuth0') {
                                localStorage.removeItem('token')
                                localStorage.removeItem('rfqid')
                                window.location.href = window.location.origin + '/login'
                                return;
                              }
                            toast.error(err.message)
                            throw new Error(err.message)
                        })
                    } 
                },5*1000)
                   
                // delay 1 minute, query every 1 minute for 20 min
                secondTimeout = setTimeout(() => {
                    clearInterval(firstInterval)
                    secondInterval = setInterval(() => {
                        if((Number(Date.now()) > Number(RFQFormDetails?.timestamp + 1000*60)) && (Number(Date.now()) < Number(RFQFormDetails?.timestamp + 1000*60*20))) {
                            getRFQList({
                                clientId: user?.sub?.split('|')[1] as string,
                                rfqId: RFQId,
                                fairPrice: RFQFormDetails?.fair_price
                            }, authToken).then(res => {
                                setRFQList(res)
                            }).catch(err => {
                                if((err as Error).message === 'EAuth0') {
                                    localStorage.removeItem('token')
                                    localStorage.removeItem('token')
                                    window.location.href = window.location.origin + '/login'
                                    return;
                                  }
                                toast.error(err.message)
                                throw new Error(err.message)
                            })
                        } 
                    },1000*60)
                },1000*60)

                // delay 21 minutes, query every 5 minute for 1 hour
                thirdTimeout = setTimeout(() => {
                    clearInterval(secondInterval)
                    clearTimeout(secondTimeout)
                    thirdInterval = setInterval(() => {
                        if((Date.now() > RFQFormDetails?.timestamp + 1000*60*20) && (Date.now() < RFQFormDetails?.timestamp + 1000*60*60)) {
                             getRFQList({
                                clientId: user?.sub?.split('|')[1] as string,
                                rfqId: RFQId,
                                fairPrice: RFQFormDetails?.fair_price
                            }, authToken).then(res => {
                                setRFQList(res)
                            }).catch(err => {
                                if((err as Error).message === 'EAuth0') {
                                    localStorage.removeItem('token')
                                    localStorage.removeItem('rfqid')
                                    window.location.href = window.location.origin + '/login'
                                    return;
                                  }
                                toast.error(err.message)
                                throw new Error(err.message)
                            })
                        } 
                    },5*1000)
                },1000*60*20 + 1000*60)

                // delay 1 hour and 21 min, clear timer and stop querying
                fourthTimeout = setTimeout(() => {
                    clearTimeout(thirdTimeout)
                    clearInterval(thirdInterval)

                },1000*60*60 + 1000*60*20 + 1000*60)
               
            }

            // clear timer when unmount
            return () => {
                clearInterval(firstInterval)
                clearInterval(secondInterval)
                clearInterval(thirdInterval)
                clearTimeout(secondTimeout)
                clearTimeout(thirdTimeout)
                clearTimeout(fourthTimeout)
            }
        } catch (error) {
            toast.error('No RFQ quotes received')
        }
        
    },[RFQFormDetails, RFQId, user, isLoading, authToken, RFQFormSubmitted])
     
    return (
        <RFQContext.Provider value={{
            popUp,
            setPopup,
            RFQFormDetails,
            setRFQFormDetails,
            RFQFormSubmitted,
            setRFQFormSubmitted,
            RFQList,
            setRFQList,
            RFQId,
            setRFQId,
            recentQuoteList,
            setRecentQuoteList,
            recentQuoteCols,
            setRecentQuoteCols,
            showRecentQuoteTable,
            setShowRecentQuoteTable,
            selectedQuote,
            setSelectedQuote,
            submitTrade,
            popUpContent,
            setPopUpContent,
        }}>
            <Toaster/>
            {children}
        </RFQContext.Provider>
    )
}

export default function useRFQ(){
    return useContext(RFQContext)
}