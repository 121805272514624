import React, {useContext, createContext, useState} from 'react';

export interface SinglePricing {
    price: number,
    method: number,
    methodCompare: number,
}

export interface SinglePricingMethod {
    name: string,
    parameters: {
        notional: number,
        strike_call:number|undefined,
        strike_put: number|undefined,
        time_to_maturity: number,
        volatility: number,
    },
    price: number,
}

interface SinglePricingExplanation {
    header: string,
    text:string
}


interface PricingExplanationProps {
    method: SinglePricingExplanation,
    methodCompare: SinglePricingExplanation,
}


const initialSinglePricingMethod = {
    name: "",
    parameters: {
        notional: 0,
        strike_call: 0,
        strike_put: 0,
        time_to_maturity: 0,
        volatility: 0,
    },
    price: 0,
}

interface PricingFormFieldsProps {
    method: {
        sym: string,
        revenue: number | string,
        method: string,
        frequency: string,
        strikeOffset: number | string,
        strikeOffsetCall: number | string,
    },
    methodCompare: {
        method: string,
        frequency: string,
        strikeOffset: number | string,
        strikeOffsetCall: number | string,
    },
    pricingFormSubmitted: boolean,
    compareCheck: boolean,
}

const initialPricingFormValues = {
    method: {
        sym: '',
        revenue: '',
        method: '',
        frequency: '',
        strikeOffset: '',
        strikeOffsetCall: '',
    },
    methodCompare: {
        method: '',
        frequency: '',
        strikeOffset: '',
        strikeOffsetCall: '',
    },
    pricingFormSubmitted: false,
    compareCheck:false
}
export interface PricingProps {
    pricingChartData: SinglePricing[],
    setPricingChartData: React.Dispatch<React.SetStateAction<SinglePricing[]>>,
    pricingTable: {
        method: SinglePricingMethod,
        methodCompare: SinglePricingMethod,
    }
    setPricingTable: React.Dispatch<React.SetStateAction<{
        method: SinglePricingMethod,
        methodCompare: SinglePricingMethod,
    }>>,
    pricingExplanation: PricingExplanationProps,
    setPricingExplanation: React.Dispatch<React.SetStateAction<PricingExplanationProps>>,
    compareCheck: boolean,
    setCompareCheck: React.Dispatch<React.SetStateAction<boolean>>,
    pricingFormValues: PricingFormFieldsProps,
    setPricingFormValues: React.Dispatch<React.SetStateAction<PricingFormFieldsProps>>,
    showPricingChart:boolean,
    setShowPricingChart: React.Dispatch<React.SetStateAction<boolean>>,
}

const PricingContext = createContext<PricingProps>({
    pricingChartData: [] as SinglePricing[],
    setPricingChartData: () => {},
    pricingTable: {
        method: initialSinglePricingMethod,
        methodCompare: initialSinglePricingMethod,
    },
    setPricingTable: () => {},
    pricingExplanation: {
        method: {
            header: "",
            text: "",
        },
        methodCompare: {
            header: "",
            text: "",
        },
    },
    setPricingExplanation: () => {},
    compareCheck: false,
    setCompareCheck: () => {},
    pricingFormValues: initialPricingFormValues,
    setPricingFormValues: () => {},
    showPricingChart:false,
    setShowPricingChart: () => {},
})

export const PricingProvider = ({children}:{children: React.ReactNode}) => {
    const [pricingChartData, setPricingChartData] = useState([] as SinglePricing[])
    const [pricingTable, setPricingTable] = useState<{method:SinglePricingMethod,methodCompare:SinglePricingMethod}>({
        method: initialSinglePricingMethod,
        methodCompare: initialSinglePricingMethod,
    })
    const [pricingExplanation, setPricingExplanation] = useState<PricingExplanationProps>({
        method: {
            header: "",
            text: "",
        },
        methodCompare: {
            header: "",
            text: "",
        },
    })
    const [pricingFormValues, setPricingFormValues] = useState<PricingFormFieldsProps>(initialPricingFormValues)
    const [showPricingChart, setShowPricingChart] = useState(false)
    const [compareCheck, setCompareCheck] = useState(false)
    
    return (
        <PricingContext.Provider value={{
            pricingChartData,
            setPricingChartData,
            pricingTable,
            setPricingTable,
            pricingExplanation,
            setPricingExplanation,
            compareCheck,
            setCompareCheck,
            pricingFormValues,
            setPricingFormValues,
            showPricingChart,
            setShowPricingChart,
        }}>
            {children}
        </PricingContext.Provider>
    )
}

export default function usePricing(){
    return useContext(PricingContext)
}