import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MenuProvider } from './hooks/useMenu';
import { RFQProvider } from './hooks/useRFQ';
import {BacktestProvider} from './hooks/useBacktest';
import {PricingProvider} from './hooks/usePricing';
import {ProfileProvider} from './hooks/useProfile';
import { Auth0Provider} from "@auth0/auth0-react";  
import { OverviewProvider } from './hooks/useOverview';
import { AuthProvider } from './hooks/useAuth';
const domain =process.env.REACT_APP_AUTH0_DOMAIN as string;
const clientId =process.env.REACT_APP_AUTH0_CLIENT_ID as string;


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <React.StrictMode>
    <Auth0Provider
      domain = {domain}
      audience = {'https://'+domain+'/api/v2/'}
      clientId = {clientId}
      redirectUri = {window.location.origin + '/loading'}
    >
      <AuthProvider>
      <ProfileProvider>
        <MenuProvider>
          <OverviewProvider>
          <PricingProvider>
          <RFQProvider>
            <BacktestProvider>
              <App />
            </BacktestProvider>
          </RFQProvider>
          </PricingProvider>
          </OverviewProvider>
        </MenuProvider>
      </ProfileProvider>
      </AuthProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
