import React from 'react'
import usePricing from '../hooks/usePricing';

const PricingChartExplanation = () => {
    const { pricingExplanation } = usePricing();
    const {method, methodCompare} = pricingExplanation;
    return (
    <article className="flex flex-col w-full gap-[2rem] mt-[2.5rem] border-bg-pricing-explanation border-[1px] rounded-[10px] text-xxs px-[2.5rem] py-[1rem] text-pricing-explanation-text">
        {method.header ? (
        <div>
            <header className="font-[600] pb-[0.5rem]">{method?.header}</header>
            <p>{method?.text}</p>
        </div>) : 
        <></>
        }
        { methodCompare.header ? (
        <div>
            <header className="font-[600] pb-[0.5rem]">{methodCompare?.header}</header>
            <p>{methodCompare?.text}</p>
        </div>):
        <></>
        }
    </article>
  )
}

export default PricingChartExplanation