import React from 'react'
import { useAuth0 } from '@auth0/auth0-react';

const LogoutButton = () => {
    const {logout} = useAuth0();
    
    const handleLogout = async () => {
        localStorage.removeItem('token');
        localStorage.removeItem('rfqid');
        logout({returnTo: window.location.origin + '/login'});
    }

    return (
        <div className="fixed flex-col top-[4.5rem] pl-[3.5rem] h-[3.5rem] w-[16.5rem] flex scale-in-ver-top">
            <button type="button" className="bg-white h-full w-full gap-[1.5rem] pl-[2.5rem] rounded-[6px] flex items-center border- shadow-[5px_8px_10px_4px_rgba(0,0,0,0.2)] cursor-pointer" onClick={handleLogout}>
                <div className="w-[1.25rem] h-[1.25rem]">
                <img src="logout.png" alt="logout" />
                </div>
                <p className="rounded-[6px] text-lg font-[600] self-center">{'Logout'}</p>
            </button>
        </div>
    )
}

export default LogoutButton