import React, { useEffect, useState } from 'react';
import useRFQ from '../hooks/useRFQ';
import moment from 'moment';
import { extractCryptoCcyFromPair } from '../utils/syms';
import { useAuth0 } from '@auth0/auth0-react';
import { getRFQList } from '../utils/apiService';
import toast, {Toaster} from 'react-hot-toast';
import { priceFormatWithDecimals, thousandSeparator } from '../utils/numberFormat';

const RFQSubmittedQuotes = () => {
  const {RFQList, submitTrade, RFQFormDetails, RFQId, setRFQList} = useRFQ();
  const {user, isLoading } = useAuth0();
  const {sym} = RFQFormDetails
  const [authToken] = useState(localStorage.getItem('token'))
  const cryptoSym = extractCryptoCcyFromPair(sym)

  useEffect(() => {
    if(user && RFQFormDetails && RFQId && !isLoading && authToken) {
        getRFQList({
            clientId: user?.sub?.split('|')[1] as string,
            rfqId: RFQId,
            fairPrice: RFQFormDetails.fair_price
        }, authToken).then(res => {
            setRFQList(res)
        }).catch(err => {
            if((err as Error).message === 'EAuth0') {
                localStorage.removeItem('token')
                localStorage.removeItem('rfqid')
                window.location.href = window.location.origin + '/login'
                return;
              }
            toast.error(err.message)
            throw new Error(err.message)
        })
    } 
},[user, RFQFormDetails, RFQId, isLoading, authToken, setRFQList])

  return (
    <article className="w-full rounded-[6px] bg-white shadow-[2px_3px_30px_10px_rgba(222,235,241,0.35)] px-[1rem] md:px-[3.125rem] pt-[2.5rem] pb-[2rem] min-w-[40rem]">
      <Toaster/>
      <h2 className="text-lg md:text-xl font-[600]">Quotes</h2>
      <table className="w-full mt-[2rem] md:text-sm">
        <thead>
          <tr className="text-rfq-label-text border-b-[1px] border-b-[#EBF2F5] text-xs md:text-sm">
            <th className="text-start font-[400]">Date</th>
            <th className="text-start font-[400]">Time</th>
            <th className="text-start font-[400]">Notional</th>
            <th className="text-start font-[400]">Market Maker</th>
            <th className="font-[400] text-center">Fair Price</th>
            <th className="font-[400] text-center">Bid Price</th>
            <th className="font-[400] text-center">Ask Price</th>
            <th>{""}</th>
          </tr>
        </thead>
        <tbody>
          {/* to delete the mock quotes once RFQ endpoint is ready */}
          {
            RFQList.slice().sort((a,b) => Number(b.date) - Number(a.date)).map((quote) => (
              <tr key={quote.quoteid} className="text-rfq-value-text font-[600] border-b-[1px] border-b-[#EBF2F5] text-xxs md:text-sm">
                <td className="py-[1.5rem] w-[14%]">{moment(quote?.date as Date).format('DD[/]MM[/]YYYY')}</td>
                <td className="py-[1.5rem] w-[14%]">{moment(quote?.date as Date).format('LT')}</td>
                <td className="py-[1.5rem] w-[14%]">{quote?.notional ? `${thousandSeparator(Number(quote.notional))} ${extractCryptoCcyFromPair(sym)}` :'0.0'}</td>
                <td className="py-[1.5rem] w-[14%]">{quote?.entity}</td>
                <td className="py-[1.5rem] text-center w-[14%]">{quote.fairPrice ? `${priceFormatWithDecimals(Number(quote.fairPrice))}` : '0.0'}</td>
                <td className="py-[1.5rem] text-center w-[14%]">{quote.bidPrice ? `${priceFormatWithDecimals(Number(quote?.bidPrice))}` : '0.0'}</td>
                <td className="py-[1.5rem] text-center w-[14%]">{quote.askPrice ? `${priceFormatWithDecimals(Number(quote?.askPrice))}` : '0.0'}</td>
                <td className="mx-auto text-center">
                  <button className="w-[5rem] h-[2.25rem] rounded-[6px] bg-black text-white text-sm font-[500] shadow-[2px_3px_24px_2px_rgba(0,0,0,0.15)]"
                    onClick={() => submitTrade({
                      Date: quote?.date,
                      Product: '',
                      Notional:`${thousandSeparator(Number(quote.notional))} ${cryptoSym}` || 0.0,
                      Counterparty:quote?.entity,
                      FairPrice: quote?.fairPrice,
                      BidPrice:quote?.bidPrice,
                      AskPrice:quote?.askPrice,
                      QuoteId:quote?.quoteid,
                      Timestamp: ''
                    })}
                  >
                    Trade
                  </button>
                </td>
              </tr>)) 
          }
        </tbody>
      </table>
    </article>
  )
}

export default RFQSubmittedQuotes