import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { domain } from '../utils/apiService';

export interface AuthContextProps {
    userLogout: () => void;
}

export const AuthContext = createContext<AuthContextProps>({
    userLogout: () => {},
});


export const AuthProvider = ({children}:{children: React.ReactNode}) => {
    const [authToken, setAuthToken] = useState<string | null>(localStorage.getItem('token')) 
    const { getAccessTokenSilently, logout, user, isLoading } = useAuth0();
    const userLogout = () => {
        logout({ returnTo: window.location.origin + '/login'});
        localStorage.removeItem('token')
        localStorage.removeItem('rfqid')
    } 
    

    useEffect(() => {
        if(!authToken && !isLoading && user) {
                getAccessTokenSilently({
                    ignoreCache: true,
                    audience: `https://${domain}/api/v2/`,
                }).then((tk) => {
                    setAuthToken(tk)
                    localStorage.setItem('token', tk)
                })
                
            }
    },[authToken, user, isLoading, getAccessTokenSilently])

    return (
        <AuthContext.Provider value={{
            userLogout
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext);