import React from 'react'
interface ChartButtonProps {
    image: string;
}

const ChartButton = ({image}:ChartButtonProps) => {

    return (
    <div className="md:hidden w-[5rem] h-[5rem] bg-white rounded-[10px] flex justify-center items-center shadow-[2px_3px_10px_8px_rgba(222,235,241,1)] border-black border-[1px]">
        <img src={image} alt="" />
    </div>
  )
}

export default ChartButton;