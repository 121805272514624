import React from 'react';
import moment from 'moment';
import useRFQ from '../hooks/useRFQ';
import { capFirstLetter } from '../utils/chartUtils';
import { thousandSeparator } from '../utils/numberFormat';
import { extractCryptoCcyFromPair } from '../utils/syms';
import { INSTRUMENTS_WITHOUT_STRIKE, INSTRUMENTS_WITH_CALL_STRIKE, findHedgingMethodName } from '../utils/instruments';

function formatStrikes(number: number): string {
    const roundedNumber = +number.toFixed(2); // Round to 2 decimal places
    const formattedNumber = String(roundedNumber).replace(/\.0+$/, ''); // Remove trailing zeros
    return formattedNumber;
  }

export default function RFQSubmittedDetails() {
    const {RFQFormDetails} = useRFQ()
    const {date, sym, hedging_method, strike_offset ,strike_offset_call, notional, timestamp, maturity} = RFQFormDetails
    const includePutStrike = !INSTRUMENTS_WITHOUT_STRIKE.includes(hedging_method.toLowerCase());
    const includeCallStrike = INSTRUMENTS_WITH_CALL_STRIKE.includes(hedging_method.toLowerCase());
    return (
        <article className="bg-white shadow-[2px_3px_30px_10px_rgba(222,235,241,0.35)] rounded-[6px] px-[1rem] md:px-[3.125rem] pt-[2.5rem] min-w-[40rem]">
            <h2 className="text-lg md:text-xl font-[600]">RFQ Submitted</h2>
            <table className="w-full mt-[2rem] md:text-sm">
                <thead>
                    <tr className="text-rfq-label-text border-b-[1px] text-xs md:text-sm border-b-[#EBF2F5]">
                        <th className="text-start font-[400]">Date</th>
                        <th className="text-start font-[400]">Time</th>
                        <th className="text-start font-[400]">Token</th>
                        <th className="text-center font-[400]">Product</th>
                        <th className="text-center font-[400]">{`Strike (% of Spot)`}</th>
                        <th className="text-center font-[400]">{`Notional (in ${extractCryptoCcyFromPair(sym)})`}</th>
                        <th className="text-center font-[400]">Maturity</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="text-rfq-value-text font-[600] border-b-[1px] border-b-[#EBF2F5] text-xxs md:text-sm">
                        <td className="py-[1.5rem] w-[14%]">{moment(date).format('DD[/]MM[/]YYYY')}</td>
                        <td className="py-[1.5rem] w-[14%]">{moment(timestamp).format('LT')}</td>
                        <td className="py-[1.5rem]">{sym.toUpperCase()}</td>
                        <td className="py-[1.5rem] text-center">{findHedgingMethodName(hedging_method.toLowerCase())}</td>
                        <td className="py-[1.5rem] text-center">
                         {includePutStrike ? `Put: ${formatStrikes(100 - Number(strike_offset))}%` : ''}
                         {includeCallStrike ? ` /Call: ${formatStrikes(Number(100 + Number(strike_offset_call)))}%`:''}</td>
                        <td className="py-[1.5rem] text-center">{thousandSeparator(Number(notional))}</td>
                        <td className="py-[1.5rem] w-[14%] text-center">{typeof maturity === 'number' ? moment(maturity).format('DD[/]MM[/]YYYY') : maturity}</td>
                    </tr>
                </tbody>
            </table>
        </article>
    )
}