import React,{useState, useRef} from 'react';
import useBacktest from '../hooks/useBacktest';
import { uploadRevenuesCSV } from '../utils/apiService';
import { useAuth0 } from '@auth0/auth0-react';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const ImportData = () => {
  const [file, setFile] = useState<File | null>(null)
  const {sym} = useBacktest()
  const { user } = useAuth0();
  const clientId = user?.sub?.split('|')[1];
  const hiddenInputRef = useRef<HTMLInputElement>(null);
  const [authToken] = useState<string|null>(localStorage.getItem('token'))
  const navigate = useNavigate()

  const handleImport = () => {
    hiddenInputRef.current?.click();
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      if(!authToken) {
        return;
      }
      if(!file) {
        toast.error('Please upload a file');
        return;
      }
      if(!clientId) {
        toast.error('Missing client info');
        return;
      }  
      if(!sym) {
        toast.error('Please select a token');
        return;
      }  
      if(file && clientId && sym && authToken) {
        const res = await uploadRevenuesCSV(file, clientId, sym, authToken);
        if(res.error.length !== 0) {
          throw new Error(res.error[0])
        }
        toast.success('File uploaded successfully')
        setTimeout(() => {
          setFile(null)
        }, 2000)
      } else {
        throw new Error('File upload failed')
      }
    } catch(err) {
      if((err as Error).message === 'EAuth0') {
         localStorage.removeItem('token')
         localStorage.removeItem('rfqid')
        navigate('/login')
        return;
      }
      toast.error((err as Error).message)
      setTimeout(() => {
        setFile(null)
      }, 4000)
    } 
  }

  return (
    <div className="rounded-[6px] bg-bg-data-container flex  pl-[2rem] border-dashed border-[1px] border-black pt-[1rem] w-full md:pr-[1rem] md:mr-[1rem] min-w-[18.5rem]">
        <Toaster/>
        <div className="hidden w-[6.625rem] h-[6.625rem] rounded-full self-center bg-bg-import-icon md:flex justify-center items-center">
            <img src="import.svg" alt="import"/>
        </div>
        <div className="flex flex-col mt-[1rem] gap-[0.625rem] md:pl-[1rem] pb-[2rem] md:w-[80%] w-[100%]">
            <h3 className="font-[600] text-xs md:text-mlg text-backtest-header-text p-0">Import a time series of your monthly rewards.</h3>
            <p className="text-xxs md:text-sm">Last imported on 17/08/2022</p>
            <form className="flex flex-col xl:flex-row xl:items-center gap-[1rem] xl:gap-[3rem] w-[100%]" onSubmit={(e) => handleSubmit(e)}>
                <input 
                  type="file" accept={".csv"} 
                  onChange={(e) => {
                    if(!e.target?.files?.length) return;
                    const csvFile = e.target?.files[0] as File
                    if(csvFile?.type !== 'text/csv') {
                      toast.error('Please upload a csv file')
                    }
                    setFile(csvFile)
                  }} 
                  className="w-[15rem] hidden" 
                  ref={hiddenInputRef}/>
                <div className="flex flex-col gap-[0.5rem] md:w-[40%] max-w-[8rem]">
                  <button type="button" onClick={handleImport} className="border-black border-[1px] bg-bg-import-btn rounded-[6px]">Choose File</button>
                  <p className="text-center text-xxs text-ellipsis overflow-hidden">{file ? file?.name : 'No file chosen' }</p>
                </div>
                <button type="submit" className="rounded-[6px] h-[4.125rem] w-[10.875rem] bg-black text-white text-mlg font-[600] shadow-[2px_3px_24px_10px_rgba(0,0,0,0.15)]">Import</button>
            </form>
        </div>
    </div>
  )
}

export default ImportData;