import React, { useEffect, useState} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {useNavigate} from 'react-router-dom';
import Loading from './Loading';
import useProfile from '../hooks/useProfile';
import LogoutButton from './LogoutButton';

const SideMenuProfile = () => {
  const [showProfileMenu, setShowProfileMenu] = useState(false)
  const navigate = useNavigate();
  const {user, isLoading} = useAuth0();
  const {generalSetting } = useProfile();
  
  const toggleProfile = () => {
    setShowProfileMenu(!showProfileMenu);
  }

  useEffect(() => {
    if (!isLoading && !user) {
      navigate('/login');
    }
  },[isLoading, user, navigate])

  if (isLoading) {
    return <Loading />;
  }

  

  return (
    <div className="w-full h-[5.875rem] pt-[1rem] flex flex-col  relative cursor-pointer" onClick={toggleProfile}>
        <div className="flex w-full items-center pl-[2.5rem] gap-[1.2rem]">
          <div className="rounded-full w-[4rem] h-[4rem] overflow-hidden">
              <img src={generalSetting.picture} alt="profile-pic" className="object-cover w-full h-full"/>
          </div>
          <div className="flex">
            <p className="font-[600] text-md">{generalSetting.displayName}</p>
            <div className="w-[1.5rem] h-[1rem] cursor-pointer self-center">
              <img src="arrow_down.svg" alt="" className=" w-full h-full"/>
            </div>
          </div>
        </div>
        {showProfileMenu && (
          <LogoutButton />
        )}
    </div>
  )
}

export default SideMenuProfile;