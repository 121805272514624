import React from 'react';
import toast, {Toaster} from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Tooltip from './Tooltip';
interface ClipboardProps {
    text: string;
}

const Clipboard = ({text}:ClipboardProps) => {
    const [copied, setCopied] = React.useState(false);
    const navigate = useNavigate()

    const copyText= async (text:string) => {
        if(!navigator?.clipboard) return
        try {
            await navigator.clipboard.writeText(text.replace('</br>', ''))
            setCopied(true);
            setTimeout(() => {
                setCopied(false);
            }, 500);
        } catch (err) {
            if((err as Error).message === 'EAuth0') {
                 localStorage.removeItem('token')
                navigate('/login')
                return;
              }
            toast.error(`Failed to copy! - ${(err as Error).message}}`)
            setCopied(false)
        }
        
    }

    return (
        <>
            <Toaster />
            <div className="flex justify-between gap-5 py-3 rounded-xl px-5 items-center mb-5 border-bg-import-btn border-[1px]">
                <p dangerouslySetInnerHTML={{__html: text}} />
                <div>
                    <button type='button' onClick={() => copyText(text)}>
                        {copied ? (<div className="fixed -mt-[3rem] -ml-[1.2rem]">
                            <Tooltip text="Copied" />
                        </div>): <></>}
                        <div className="w-5 h-5">
                            <img src={copied ? 'copy_after.png' : 'copy_before.png'} alt="copy" />
                        </div> 
                    </button>
                </div>
            </div>
        </>
    )
}

export default Clipboard;