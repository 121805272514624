import numeral from "numeral";
import moment from "moment";
import { THRESHOLD_FOR_ONE_DECIMALS, THRESHOLD_FOR_TWO_DECIMALS } from "./constants/decimals";

export const numFormat = (num: number) => {
  if (num > 1000) {
    if (num > 1000000) {
      return `${numeral(num / 1000000).format("$0,0.00")}M`;
    }
    if (num > 100000) {
      return `${numeral(num / 1000).format("$0,0.00")}k`;
    }
    return `${numeral(num).format("$0,0.00a")}`;
  }
  return `${numeral(num).format("$0,0.00")}`;
};

export const priceFormat = (num: number) => {
  return `${numeral(num).format("$0,0")}`;
};

export const formatDate = (dt: string | number) => {
  return moment(dt).format("DD[/]MM[/]YYYY");
};

export const thousandSeparator = (num: number) => {
  if (num > 1000000) {
    return `${numeral(num / 1000).format("0,0")}k`;
  }else if(num <= THRESHOLD_FOR_TWO_DECIMALS) {
    return `${numeral(num).format("0,0.00")}`;
  } else if(num > THRESHOLD_FOR_TWO_DECIMALS && num <= THRESHOLD_FOR_ONE_DECIMALS) {
    return  `${numeral(num).format("0,0.0")}`;
  }
  return `${numeral(num).format("0,0")}`;
};

export const priceFormatWithDecimals = (num: number) => {
  if (num > 1000000) {
    return `${numeral(num / 1000).format("$0,0")}k`;
  }
  return `${numeral(num).format("$0,0.0000")}`;
};
