import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { getRegistrationStatus, domain } from '../utils/apiService';
import toast, { Toaster } from 'react-hot-toast';
import useOverview from '../hooks/useOverview';

const LoginInBox = () => {
    const { isLoading, user, loginWithRedirect, getAccessTokenSilently} = useAuth0();
    const navigate = useNavigate();
    const [authToken, setAuthToken] = useState<string|null>(localStorage.getItem('token')) 
    const {setIsRegistered} = useOverview()

    useEffect(() => {
        if(!authToken && !isLoading && user) {
            getAccessTokenSilently({
                ignoreCache: true,
                audience: `https://${domain}/api/v2/`,
            }).then(tk => {
              setAuthToken(tk)
              localStorage.setItem('token', tk)
            })
        }
      },[authToken, getAccessTokenSilently, isLoading, user])


    const handleLogin = async () => {
        try {
            await loginWithRedirect({screen_hint: 'login'})
            if(user && authToken) {
                getRegistrationStatus(user?.sub?.split('|')[1] as string, authToken)
                .then((st) => {
                    if(st.isregistered === 'true') {
                        setIsRegistered(true)
                        if(st.stripestatus === 'paid') {
                        // user has registered
                            navigate('/')
                        } else {
                            window.open('/subscribe', '_blank')
                        }
                    } else  {
                        setIsRegistered(false)
                        navigate('/register');
                    } 
                }).catch((error) => {
                    toast.error((error as Error).message)
                })
            }
            
        } catch (error) {
            if((error as Error).message === 'EAuth0') {
                localStorage.removeItem('token')
                localStorage.removeItem('rfqid')
                }
            navigate('/login')
        }

    }

    const handleSignUp = async () => {
        try {
            await loginWithRedirect({
                screen_hint: 'signup',
            });
            if(!isLoading && user && authToken) {
                    const status = await getRegistrationStatus(user?.sub?.split('|')[1] as string, authToken);
                    if(status.isregistered === 'true') {
                        if(status.stripestatus === 'paid') {
                        // user has registered
                            navigate('/');
                        } else {
                            navigate('/subscribe');
                        }
                    } else {
                        navigate('/register');
                    } 
                }
            } catch (error) {
                if((error as Error).message === 'EAuth0') {
                    localStorage.removeItem('token')
                    localStorage.removeItem('rfqid')
                }
                navigate('/login')
            }
        }

  return (
    <article className="w-[25.25rem] pb-[2rem] bg-bg-login flex flex-col rounded-[10px] shadow-[0px_6px_16px_5px_rgba(58,49,45,0.16)]">
        <Toaster/>
        <div className="flex flex-col mt-[1.5rem] mx-[2rem] gap-[2rem]">
            <h2 className="text-xl text-login-text-header text-center">Welcome back!</h2>
            <button className="bg-primary-blue text-white text-mlg h-[3.5rem] rounded-[10px] shadow-[0px_6px_16px_5px_rgba(58,49,45,0.16)] w-full" onClick={handleLogin}>
                Login
            </button>
            <button className="bg-primary-blue text-white text-mlg h-[3.5rem] rounded-[10px] shadow-[0px_6px_16px_5px_rgba(58,49,45,0.16)] w-full" onClick={handleSignUp}>
                Sign Up
            </button>
        </div>
    </article>
  )
}

export default LoginInBox;