import React,{useEffect, useState} from 'react';
import SideMenu from '../components/SideMenu';
import Header from '../components/Header';
import PerformanceBox from '../components/PerformanceBox';
import HedgingBox from '../components/HedgingBox';
import PnlChart from '../components/PnlChart';
import {useAuth0} from '@auth0/auth0-react';
import { useNavigate, useLocation } from 'react-router-dom';
import useMenu, {Selected} from '../hooks/useMenu';
import useOverview  from '../hooks/useOverview';
import ChangeMethodForm from '../components/ChangeMethodForm';
import ErrorPage from '../components/Error';
import { ErrorBoundary } from 'react-error-boundary';
import MobileHeader from '../components/MobileHeader';
import MobileSideMenu from '../components/MobileSideMenu';
import ChartButton from '../components/ChartButton';
import { getRegistrationStatus, domain } from '../utils/apiService';
import Loading from '../components/Loading';
import Select from 'react-select';
import { selectSymList, SymArrayType } from '../utils/syms';

const Overview = () => {
  const {isLoading, isAuthenticated, user, getAccessTokenSilently} = useAuth0();
  const {overview, popUp, showOverviewChart, setShowOverviewChart, setIsRegistered, symArray, setSymArray, setFirstSelectedSym} = useOverview();
  const {pnlhedging, pnlnohedging, savings, savingspercentage} = overview;
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const {setSelected, showMobileMenu, setShowMobileMenu} = useMenu();
  const [authToken, setAuthToken] = useState<string | null>(localStorage.getItem('token'))
  const [isSubscribed, setSubscribed] = useState<boolean>(false);

  const headerText = "Performance Since Inception - ";

  useEffect(() => {
    if(pathname === '/' || pathname === '/overview') {
      setSelected(Selected.Overview)
    }
  }, [pathname, setSelected])

  useEffect(() => {
    if(!authToken && !isLoading && user) {
        getAccessTokenSilently({
            ignoreCache: true,
            audience: `https://${domain}/api/v2/`,
        }).then(tk => {
          setAuthToken(tk)
          localStorage.setItem('token', tk)
        })
    }
  },[authToken, getAccessTokenSilently, isLoading, user])

  useEffect(() => {
    if(isAuthenticated && user && !isLoading && authToken) {
      getRegistrationStatus(user?.sub?.split('|')[1] as string, authToken as string)
      .then(st => {
        if(st.isregistered === 'true') {
            setIsRegistered(true)
          if(st.stripestatus === 'paid') {
            // user has registered
            setSubscribed(true)
            return;
          } else  {
          // user has not paid
            navigate('/loading')
            window.open('/subscribe', '_blank')
          } 
        } else {
          // user has not registered
          navigate('/register');
        }
    })
      .catch(err => {
        if(err.message === 'EAuth0') {
          localStorage.removeItem('token')
          localStorage.removeItem('rfqid')
        }
        navigate('/login')
      });
    } else if (!isLoading && !isAuthenticated) {
      // user is not authenticated or user is not defined
      navigate('/login');
    }
  },[authToken, isAuthenticated, isLoading, navigate, user, setIsRegistered, isSubscribed])


  if(isLoading || !authToken || !user|| !isAuthenticated || !isSubscribed){
    return <Loading/>
  }

  
  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
    >
      <main 
        className="h-[100%] flex flex-1 overflow-x-scroll md:overflow-x-hidden" 
      >
        {popUp && <ChangeMethodForm/>}
          <SideMenu />
          <div className="flex flex-col bg-bg-dashboard w-[100vw] lg:header-width">
            {showMobileMenu && <MobileSideMenu/>}
            <Header headerText={headerText}/>
            <MobileHeader/>
              <section className="md:pl-[2.875rem] mt-[5rem] md:mt-[2.25rem] items-center md:pr-[2.875rem] px-[1rem] flex flex-col gap-[2.5rem] w-full lg:header-width h-full min-w-[18.5rem]" 
                onClick={() => setShowMobileMenu(false)}>
                <div className="flex flex-col lg:flex-row w-full lg:justify-around gap-[1.75rem] lg:gap-0">
                  <h1 className="text-xlg font-[700] text-center md:hidden">{headerText}</h1>
                  <div className="flex self-center md:hidden">
                    {
                      <Select
                        isMulti
                        name="sym-list"
                        defaultValue = {symArray[0]}
                        onChange={(e) => {
                          setSymArray(e as SymArrayType)
                          const sym = e && e.length > 0 ? (e as SymArrayType)[0]?.value : 'ethusd'
                          setFirstSelectedSym(sym)
                        }}
                        options={selectSymList}
                      />
                    }
                  </div>
                  <div className="flex flex-col lg:flex-row w-full lg:w-[60%] lg:justify-start gap-[1.75rem] lg:gap-[3%] flex-1">
                    <PerformanceBox icon={"chart.svg"} pnl={Math.round(Number(pnlhedging))} btmText={`Profit With Hedging`}/>
                    <PerformanceBox icon={"trend_down.svg"} pnl={Math.round(Number(pnlnohedging))} btmText={`Profit Without Hedging`}/>
                    <PerformanceBox icon={"medal_star.svg"} pnl={Math.round(Number(savings))} btmText={`Rewards Saved By Hedging`} btmTextPart2={(100*Number(savingspercentage)).toFixed(2).toString()+'%'}/>
                  </div>
                  <HedgingBox/>
                </div>
                <div 
                  className="block md:hidden mb-[3rem] cursor-pointer w-full" 
                  onClick={() => setShowOverviewChart((prev:boolean) => !prev)}>
                  {
                    showOverviewChart ? <PnlChart/> : <ChartButton image={"chart.svg"}/>
                  }
                </div>
                <div className="hidden md:block mb-[3rem] w-full">
                  <PnlChart/>
                </div>
              </section>
          </div>
        </main>
      </ErrorBoundary>
  )
}

export default Overview;


