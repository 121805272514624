import React, {useContext, createContext, useState} from 'react';

export enum Selected {
    Overview = 'Overview',
    Backtest = 'Backtest',
    Pricing = 'Simulation',
    RFQ = 'RFQ',
    Docs = 'Docs',
    Profile = 'Profile',
    Support = 'Support',
    Logout = 'Logout',
    Empty = ''
}

export interface MenuProps {
    selected:Selected,
    setSelected:React.Dispatch<React.SetStateAction<Selected>>,
    showMobileMenu:boolean,
    setShowMobileMenu:React.Dispatch<React.SetStateAction<boolean>>
}

const MenuContext = createContext<MenuProps>({
    selected: Selected.Empty,
    setSelected:() => {},
    showMobileMenu:false,
    setShowMobileMenu:() => {}
})

export const MenuProvider = ({children}:{children: React.ReactNode}) => {
    const [selected, setSelected] = useState<Selected>(Selected.Empty);
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    return (
        <MenuContext.Provider value={{
            selected,
            setSelected,
            showMobileMenu,
            setShowMobileMenu
        }}>
            {children}
        </MenuContext.Provider>
    )
}

export default function useMenu() {
    return useContext(MenuContext);
}