import React,{useState, useEffect} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import toast, { Toaster } from 'react-hot-toast';
import useBacktest from '../hooks/useBacktest';
import usePricing from '../hooks/usePricing';
import { getBacktestChartData, getLatestPrice } from '../utils/apiService';
import { priceFormatWithDecimals } from '../utils/numberFormat';
import SubmitButton from './SubmitButton';
import {COLLAR, hedgingMethod, INSTRUMENTS_WITHOUT_STRIKE, SPOT, ZERO_COST_COLLAR} from '../utils/instruments';
import { rollFrequency, strikeOffsetCallFormField, strikeOffsetPutFormField} from '../utils/formFieldsMapping';
import {capFirstLetter} from '../utils/chartUtils';
import { useNavigate } from 'react-router-dom';
import { displayedSymToValue, extractCryptoCcyFromPair } from '../utils/syms';
import { validateRevenue, validateStrikeOffset } from '../utils/inputValidation';

interface BacktestHedgingFormProps {
    dataOption:string;
    revenue:number|string;
    startDate:Date;
    chartRef:React.RefObject<HTMLDivElement>;
}

const BacktestHedgingForm = ({dataOption, revenue, startDate, chartRef}:BacktestHedgingFormProps) => {
    const [strikeOffset, setStrikeOffset] = useState<number|string>(0);
    const [strikeOffsetCall, setStrikeOffsetCall] = useState<number|string>(0);
    const [method, setMethod] = useState('')
    const [methodCompare, setMethodCompare] = useState('')
    const [frequencyCompare, setFrequencyCompare] = useState('')
    const [frequency, setFrequency] = useState('')
    const [strikeOffsetCompare, setStrikeOffsetCompare] = useState<string|number>(0)
    const [strikeOffsetCallCompare, setStrikeOffsetCallCompare] = useState<string|number>(0)
    const {user} = useAuth0();
    const {setBacktestChartData,setBacktestSummary, compareCheck, setCompareCheck, sym, setSym, setBacktestFormValues, setStartDate} = useBacktest();
    const [latestPrice, setLatestPrice] = useState<number>(0);
    const {pricingFormValues} = usePricing()
    const authToken = localStorage.getItem('token')
    const navigate = useNavigate()
    
    useEffect(() => {
        if(!sym || ! authToken) {
            return;
        }
        getLatestPrice(sym, authToken)
        .then(data => setLatestPrice(data?.toFixed(2)))
        .catch(error => {
            if((error as Error).message === 'EAuth0') {
                 localStorage.removeItem('token')
                 localStorage.removeItem('rfqid')
                navigate('/login')
                return;
              }
            toast.error(error.message ||'Error fetching latest price')
        })
    },[authToken, navigate, sym])

    useEffect(() => {
        if(pricingFormValues?.pricingFormSubmitted) {
            setSym(pricingFormValues.method.sym)
            setMethod(pricingFormValues.method.method)
            setFrequency(pricingFormValues.method.frequency)
            setCompareCheck(pricingFormValues.compareCheck)
            setStrikeOffset(Number(pricingFormValues.method.strikeOffset))
            setStrikeOffsetCall(Number(pricingFormValues.method.strikeOffset))
            setMethodCompare(pricingFormValues.methodCompare.method)
            setFrequencyCompare(pricingFormValues.methodCompare.frequency)
            setStrikeOffsetCompare(Number(pricingFormValues.methodCompare.strikeOffset))
            setStrikeOffsetCallCompare(Number(pricingFormValues.methodCompare.strikeOffsetCall))
        }
    },[pricingFormValues, setCompareCheck, setSym])

    // Function to reset form values to default if necessary
    // const resetForm = () => {
    //     setSym('');
    //     setStrikeOffset(10);
    //     setMethod('');
    //     setMethodCompare('');
    //     setFrequencyCompare('');
    //     setFrequency('');
    //     setStrikeOffsetCompare(10);
    //     setCompareCheck(false);
    // }
    
    const handleSubmit = async(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(!dataOption) {
            toast.error('Please select backtest data source');
            return;
        }
        if(!sym) {
            toast.error('Please select a token');
            return;
        }
        if(!method) {
            toast.error('Please enter hedging method');
            return;
        }
        if(!frequency) {
            toast.error('Please enter hedging frequency');
            return;
        }
        if(!INSTRUMENTS_WITHOUT_STRIKE.includes(method)) {
            if(!validateStrikeOffset(Number(strikeOffset))) {
                toast.error('Please enter a valid strike offset value')
                return
            }
        }
        if(method==COLLAR) {
            if(!validateStrikeOffset(Number(strikeOffsetCall))) {
                toast.error('Please enter a valid strike offset value')
                return
            }
        }
        
        if(!startDate) {
            toast.error('Please enter a start date');
            return;
        }
        if(compareCheck) {
            if(!methodCompare) {
                toast.error('Please enter hedging method to compare');
                return;
            }
            if(!frequencyCompare) {
                toast.error('Please enter hedging frequency to compare');
                return;
            }
            if(INSTRUMENTS_WITHOUT_STRIKE.includes(methodCompare)) {
                if(!validateStrikeOffset(Number(strikeOffsetCompare))) {
                    toast.error('Please enter a valid strike offset value to compare')
                    return
                }
            }
            if(methodCompare==COLLAR) {
                if(!validateStrikeOffset(Number(strikeOffsetCallCompare))) {
                    toast.error('Please enter a valid strike offset value to compare')
                    return
                }
            }
        }
        if(dataOption === 'dummy') {
            if(!validateRevenue(Number(revenue))) {
                toast.error('Please enter a valid revenue number');
                return;
            }
        }
        if(user && authToken) {
            await getBacktestChartData({
                clientId: user.sub?.split('|')[1] as string,
                sym,
                method,
                strikeoffset: strikeOffset ? Number(strikeOffset)/100 : 0,
                strikeoffsetcall: strikeOffsetCall ? Number(strikeOffsetCall)/100 : 0,
                frequency: frequency,
                methodcompare: compareCheck ? methodCompare : '',
                strikeoffsetcompare: compareCheck ? Number(strikeOffsetCompare)/100 : 0,
                strikeoffsetcallcompare: compareCheck ? Number(strikeOffsetCallCompare)/100 : 0,
                frequencycompare: compareCheck ? frequencyCompare : '',
                revenue: dataOption === 'dummy' ? revenue : 0,
                withuserrevenues: (dataOption === 'saved' || dataOption === 'import') ? true : false,
                startDate, // date is formatted into string format in the apiService file
            }, authToken)
            .then(({backtestTimeSeries, backtestSummary}) => {
                setBacktestChartData(backtestTimeSeries)
                setBacktestSummary(backtestSummary)
                setStartDate(startDate)
            })
            .catch(error => {
                if((error as Error).message === 'EAuth0') {
                     localStorage.removeItem('token')
                    navigate('/login')
                    return;
                }
                toast.error(error.message || 'Error fetching backtest data')
            })
            setBacktestFormValues({
                method: {
                    sym,
                    method,
                    strikeOffset,
                    strikeOffsetCall,
                    frequency
                },
                methodCompare: {
                    method: methodCompare,
                    strikeOffset: strikeOffsetCompare,
                    strikeOffsetCall: strikeOffsetCallCompare,
                    frequency: frequencyCompare
                },
                backtestFormSubmitted: true
            })
            chartRef.current?.scrollIntoView({behavior: 'smooth', block:'end'})
        }
        // resetForm();
    }

  return (
        <div className="pb-[1rem] pt-[3rem] flex flex-col gap-[3.125rem] pr-[1rem] md:pr-[3.25rem] bg-white mt-[1.5rem] min-w-[18.5rem]">
            <h3 className="font-[600] text-xlg text-backtest-header-text text-start">Backtest a Hedging Strategy</h3>
                <form className="flex flex-col md:grid md:grid-cols-2 gap-[2.75rem]" onSubmit={handleSubmit}>
                    <Toaster />
                    <div className="flex flex-col gap-[2.75rem]" >
                        <label htmlFor='sym'>
                            <p className="text-rfq-label-text">Token</p>
                            <select id="sym" className="text-rfq-value-text mt-[1rem] w-full border-b-[#E6EBEE] border-b-[1px]" onChange={(e) => setSym(e.target.value)} value={sym} required>
                                {
                                    displayedSymToValue.map((item) => (<option key={item.value} value={item.value}>{item.symbol}</option>))
                                }
                            </select>
                        </label>
                        <label htmlFor='method'>
                            <p className="text-rfq-label-text">Hedging Method</p>
                            <select id="method" className="text-rfq-value-text mt-[1rem] w-full border-b-[#E6EBEE] border-b-[1px]" onChange={(e) => setMethod(e.target.value)} value={method} required>
                                <option value="">Select</option>
                                {
                                    hedgingMethod.map((item) => (<option key={item.value} value={item.value}>{item.name}</option>))
                                }
                            </select>
                        </label>
                        <label htmlFor='frequency'>
                            <p className="text-rfq-label-text">Frequency</p>
                            <select id='frequency' className="text-rfq-value-text mt-[1.2rem] w-full border-b-[#E6EBEE] border-b-[1px]" onChange={(e) => setFrequency(e.target.value)} value={frequency} required>
                                <option value="">Select</option>
                                {method === SPOT && <option value="weekly">Weekly</option>}
                                {
                                    rollFrequency.map((frequency) => (<option key={frequency} value={frequency}>{capFirstLetter(frequency)}</option>))
                                }
                            </select>
                        </label>
                        <label htmlFor='strikeOffset' className={`${INSTRUMENTS_WITHOUT_STRIKE.includes(method) ? 'opacity-0': ''} ${INSTRUMENTS_WITHOUT_STRIKE.includes(methodCompare) && INSTRUMENTS_WITHOUT_STRIKE.includes(method) ? 'hidden': ''}`}>
                            <p className="text-rfq-label-text pb-[0.5rem]">{`Current ${extractCryptoCcyFromPair(sym)} Price: ${priceFormatWithDecimals(latestPrice)}`}</p>
                            <p className="text-rfq-label-text">{strikeOffsetPutFormField}</p>
                            <input 
                                id='strikeOffset' 
                                type="number" 
                                placeholder={'10'} 
                                min={0} 
                                max={100} 
                                className="text-rfq-value-text mt-[1rem] w-full border-b-[#E6EBEE] border-b-[1px] pl-[0.5rem]" 
                                onChange={(e) => setStrikeOffset(e.target.value)} 
                                value={strikeOffset}
                            />
                        </label>
                        <label htmlFor='strikeOffsetCall' className={`${method!=COLLAR ? 'opacity-0': ''} ${methodCompare!=COLLAR && method!=COLLAR ? 'hidden': ''}`}>
                            <p className="text-rfq-label-text">{strikeOffsetCallFormField}</p>
                            <input 
                                id='strikeOffsetCall' 
                                type="number" 
                                placeholder={'10'} 
                                min={0} 
                                max={100} 
                                className="text-rfq-value-text mt-[1rem] w-full border-b-[#E6EBEE] border-b-[1px] pl-[0.5rem]" 
                                onChange={(e) => setStrikeOffsetCall(e.target.value)} 
                                value={strikeOffsetCall}
                            />
                        </label>
                    </div>
                    <div className="flex flex-col gap-[2.75rem] flex-1">
                        <div className="flex flex-col flex-1">
                            <label className="flex gap-[2rem]">
                                <input type="checkbox" checked={compareCheck} onChange={(e) => setCompareCheck(e.target.checked)}/>
                                <p className="text-rfq-value-text font-[500]">Check if you want to compare with another hedging method</p>
                            </label>
                        </div>
                        {compareCheck 
                            ? (
                            <>
                                <label htmlFor='methodCompare'>
                                    <p className="text-rfq-label-text">Hedging Method</p>
                                    <select id='methodCompare' className="text-rfq-value-text mt-[1rem] w-full border-b-[#E6EBEE] border-b-[1px]" onChange={(e) => setMethodCompare(e.target.value)} value={methodCompare}>
                                        <option value="">Select</option>
                                        {
                                            hedgingMethod.map((item) => (<option key={item.value} value={item.value}>{item.name}</option>))
                                        }
                                    </select>
                                </label>
                                <label htmlFor='frequencyCompare'>
                                    <p className="text-rfq-label-text">Frequency</p>
                                    <select id='frequencyCompare' className="text-rfq-value-text mt-[1.2rem] w-full border-b-[#E6EBEE] border-b-[1px]" onChange={(e) => setFrequencyCompare(e.target.value)} value={frequencyCompare}>
                                        <option value="">Select</option>
                                        {methodCompare === SPOT && <option value="weekly">Weekly</option>}
                                        {
                                            rollFrequency.map((frequency) => (<option key={frequency} value={frequency}>{capFirstLetter(frequency)}</option>))
                                        }
                                    </select>
                                </label>
                                <label htmlFor='strikeOffsetCompare' className={`${INSTRUMENTS_WITHOUT_STRIKE.includes(methodCompare) ? 'opacity-0':''} ${INSTRUMENTS_WITHOUT_STRIKE.includes(methodCompare) && INSTRUMENTS_WITHOUT_STRIKE.includes(method) ? 'hidden': ''}`}>
                                    <p className="text-rfq-label-text pb-[0.5rem]">{`Current ${extractCryptoCcyFromPair(sym)} Price: ${priceFormatWithDecimals(latestPrice)}`}</p>
                                    <p className="text-rfq-label-text">{strikeOffsetPutFormField}</p>
                                    <input id='strikeOffsetCompare' type="number" placeholder={'10'} min={0} max={100} className="text-rfq-value-text mt-[1rem] w-full border-b-[#E6EBEE] border-b-[1px] pl-[0.5rem]" onChange={(e) => setStrikeOffsetCompare(e.target.value)} value={strikeOffsetCompare}/>
                                </label>
                                <label htmlFor='strikeOffsetCallCompare' className={`${methodCompare!=COLLAR? 'opacity-0':''} ${methodCompare!=COLLAR && method!=COLLAR ? 'hidden': ''}`}>
                                    <p className="text-rfq-label-text">{strikeOffsetCallFormField}</p>
                                    <input id='strikeOffsetCallCompare' type="number" placeholder={'10'} min={0} max={100} className="text-rfq-value-text mt-[1rem] w-full border-b-[#E6EBEE] border-b-[1px] pl-[0.5rem]" onChange={(e) => setStrikeOffsetCallCompare(e.target.value)} value={strikeOffsetCallCompare}/>
                                </label>
                            </>)
                            : null
                        }
                    </div>
                    <div className="col-span-full place-self-center">
                        <SubmitButton btnText={'Submit'}/>
                    </div>
                </form>
        </div>
  )
}

export default BacktestHedgingForm;