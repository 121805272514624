import React,{useState} from 'react';
import "react-datepicker/dist/react-datepicker.css";
import DummyData from './DummyData';
import ImportData from './ImportData';
import BacktestHedgingForm from './BacktestHedgingForm';
import { PRIDWEN_START_DATE } from '../utils/constants';

interface backtestImportDataProps {
    chartRef: React.RefObject<HTMLDivElement>
}


const BacktestImportData = ({chartRef}:backtestImportDataProps) => {
    const [startDate, setStartDate] = useState(new Date(PRIDWEN_START_DATE))
    const [revenue, setRevenue] = useState<number|string>(50)
    const [dataOption, setDataOption] = useState('')
    
  return (
    <article className="flex flex-col md:mx-[2.875rem] bg-white shadow-[2px_3px_30px_10px_rgba(222,235,241,0.35)] rounded-[6px] px-[2.5rem] pt-[1.875rem] min-w-[18.5rem]">
        <h3 className="font-[600] text-xlg text-backtest-header-text">Revenues</h3>
        <div className="flex-col lg:flex-row flex w-full gap-[1rem] md:gap-[2rem]">
            <div className="mt-[1.5rem] items-center md:w-[50%] md:pr-[2.5rem]">
                <label htmlFor="backtest-data ">
                    <p className="text-rfq-label-text font-[400] pb-[1.5rem]">Select one of the following origins for the revenues to hedge:</p>
                    <select name="backtest-data" id="backtest-data" className="border-[#E6EBEE] border-[1px] w-full py-[1.1rem] pl-[1rem] rounded-[6px] shadow-[2px_3px_30px_10px_rgba(222,235,241,0.35)]" onChange={(e) => setDataOption(e.target.value)} value={dataOption} required>
                        <option value="">Select</option>
                        <option value="saved">Use previously saved revenues</option>
                        <option value="import">Import my revenues as a CSV</option>
                        <option value="dummy">Use a fixed monthly revenue</option>
                    </select>
                </label>
            </div>
            <div className="flex flex-1 md:pr-[2.5rem] pr-[1rem] justify-start">
                {dataOption === 'import'
                ? <ImportData/>
                : dataOption === 'dummy'
                ? <DummyData startDate={startDate} setStartDate={setStartDate} revenue={revenue} setRevenue={setRevenue}/>
                : null
                }
            </div>
        </div>
        <BacktestHedgingForm dataOption={dataOption} revenue={revenue} startDate={startDate} chartRef={chartRef}/>
    </article>
  )
}
export default BacktestImportData;