import React from 'react'

interface SubmitButtonProps {
    btnText: string
}

const SubmitButton = ({btnText}:SubmitButtonProps) => {
  return (
    <button id={btnText} type="submit" className="rounded-[6px] h-[4.125rem] w-[10.875rem] bg-black text-white text-mlg font-[600] mb-[1.5rem] shadow-[2px_3px_24px_10px_rgba(0,0,0,0.15)] self-center">
      {btnText}
    </button>
  )
}

export default SubmitButton;