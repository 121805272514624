import React from 'react';
import { CSVLink } from 'react-csv';

interface CSVDownloadProps {
    data: any,
    headers: any,
    filename:string,
}

const CSVDownload = ({data, headers, filename}:CSVDownloadProps) => {
  return (
    <CSVLink
        data={data}
        headers={headers}
        filename={filename}
        className="rounded-full h-[2.625rem] w-[2.625rem] border-black border-[1px] flex justify-center items-center"
    >
        <img src={'download.svg'} alt="download" />
    </CSVLink>
  )
}

export default CSVDownload;