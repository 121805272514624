import React from 'react'
import useMenu from '../hooks/useMenu';
import useOverview from '../hooks/useOverview';
import { selectSymList, SymArrayType } from '../utils/syms';
import Select from 'react-select';

const Header = ({headerText}:{headerText:string}) => {
  const { symArray, setSymArray, setFirstSelectedSym } = useOverview();
  const [isSelectAll, setSelectAll] = React.useState<boolean>(false)
  const {selected} = useMenu()

  

  return (
    <header className="hidden md:flex justify-between min-h-[5.875rem] items-center bg-white shadow-[2px_3px_30px_10px_rgba(222,235,241,0.35)] pr-[2.875rem] pl-[3.125rem]">
      <div className="flex-col md:flex md:flex-row justify-start gap-[1rem]">
        <h1 className="text-lg max-w-[16rem] xl:max-w-full md:text-xl xl:text-xxl font-[700]">{headerText}</h1>
        <div className="flex items-center">
          {
            selected === 'Overview' || selected === 'RFQ' ?
              <Select
                isMulti
                name="sym-list"
                defaultValue = {symArray[0]}
                onChange={(e) => {
                  const symArr = e.map(item => item.value)
                  if(symArr.includes('all')) {
                    setSelectAll(true)
                    const allSyms = selectSymList.filter(item => item.value !== 'all')
                    setSymArray(allSyms)
                    setFirstSelectedSym('ethusd')
                    return
                  }
                  setSelectAll(false)
                  setSymArray(e as SymArrayType)
                  const sym = e && e.length > 0 ? (e as SymArrayType)[0]?.value : 'ethusd'
                  setFirstSelectedSym(sym)
                }}
                options={isSelectAll ? [] as SymArrayType : selectSymList}
                value={symArray}
              />
            : null
          }
        </div>
      </div>
      
      <div className="w-[13.75rem] h-[3rem]">
        <img src="pridwenLogo.png" alt="" className="object-fit w-full h-full"/>
      </div>
    </header>
  )
}

export default Header;