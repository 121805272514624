import React from 'react';
import DatePicker from 'react-datepicker';
import useBacktest from '../hooks/useBacktest';
import { extractCryptoCcyFromPair } from '../utils/syms';
interface DummyDataProps {
    startDate: Date,
    setStartDate: React.Dispatch<React.SetStateAction<Date>>,
    revenue: number|string,
    setRevenue: React.Dispatch<React.SetStateAction<number|string>>
}

const DummyData = ({startDate, setStartDate, revenue, setRevenue}:DummyDataProps) => {
    const {sym} = useBacktest()
  return (
    <div className="flex flex-col gap-[2.5rem] w-full">
        <label>
            <p className="text-rfq-label-text mb-[1rem]">Start Date</p>
            <DatePicker 
                selected={startDate}
                onChange={(date) => setStartDate(date as Date)}
               
                className="border-b-[#E6EBEE] border-b-[1px] w-full pl-[0.5rem]"
                dateFormat={"dd/MM/yyyy"}
            />
        </label>
        <label>
            <p className="text-rfq-label-text mb-[1rem]">{`Monthly Revenue (${extractCryptoCcyFromPair(sym)})`}</p>
            <input 
                type="number" 
                placeholder={"50"} 
                value={revenue}
                onChange={(e) => setRevenue(e.target.value)}
                className="border-b-[#E6EBEE] border-b-[1px] w-full pl-[0.5rem]"/>
        </label>
    </div>
  )
}


export default DummyData;