export const SPOT = 'spot';
export const FORWARD = 'forward';
export const PUT = 'put';
export const COLLAR = 'collar';
export const ZERO_COST_COLLAR = 'zerocostcollar';

export const INSTRUMENTS_WITHOUT_STRIKE = [SPOT, FORWARD];
export const INSTRUMENTS_WITH_CALL_STRIKE= [ZERO_COST_COLLAR, COLLAR];


export const hedgingMethod = [
    {
        name: 'Selling at Market Price',
        value: SPOT,
    },
    {
        name: 'Forward Selling',
        value: FORWARD
    },
    {
        name: 'Zero Cost Collar',
        value: ZERO_COST_COLLAR
    },
    {
        name: 'Put Option',
        value: PUT
    },
    {
        name: 'Collar',
        value: COLLAR
    },
]
export function findHedgingMethodName(value: string): string | undefined {
    const method = hedgingMethod.find(item => item.value === value);
    return method ? method.name : undefined;
}
