import React, {useState, useEffect} from 'react'
import useRFQ from '../hooks/useRFQ';
import { useNavigate } from 'react-router-dom';
import {COLLAR, hedgingMethod, INSTRUMENTS_WITHOUT_STRIKE, SPOT} from '../utils/instruments';
import { rollFrequency, generateExpiryOptions, FrequencyType, strikeOffsetCallFormField, strikeOffsetPutFormField } from '../utils/formFieldsMapping';
import { capFirstLetter } from '../utils/chartUtils';
import useBacktest from '../hooks/useBacktest';
import toast, {Toaster} from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { getLatestPrice, getRFQById, getRFQId, getRFQList } from '../utils/apiService';
import moment from 'moment';
import { SUPPORTED_CCY_PAIRS, CCY_PAIRS_VS_EUR, extractCryptoCcyFromPair } from '../utils/syms';
import { validateStrikeOffset, validateNotional } from '../utils/inputValidation';
import { priceFormatWithDecimals } from '../utils/numberFormat';

const RFQForm = () => {
    const {setRFQFormDetails, setRFQList, setRFQId, setRFQFormSubmitted} = useRFQ()
    const [sym, setSym] = useState('');
    const [strikeOffset, setStrikeOffset] = useState<number|string>(0);
    const [strikeOffsetCall, setStrikeOffsetCall] = useState<number|string>(0);
    const [expiry, setExpiry] = useState<string>('')
    const [method, setMethod] = useState('')
    const [frequency, setFrequency] = useState('Monthly')
    const [notional, setNotional] = useState<string| number>(0)
    const navigate = useNavigate()
    const {selectedMethod} = useBacktest()
    const {user} = useAuth0();
    const [authToken] = useState<string| null>(localStorage.getItem('token')) 
    const [latestPrice, setLatestPrice] = useState<number>(0);

    useEffect(() => {
        if(!authToken) {
            return;
        }
        // pre-fill the form with the selected method
        if(selectedMethod.method && selectedMethod.sym && selectedMethod.frequency) {
            setSym(selectedMethod.sym)
            setMethod(selectedMethod.method)
            setFrequency(selectedMethod.frequency)
            setStrikeOffset(selectedMethod.strikeOffset)
            setStrikeOffsetCall(selectedMethod.strikeOffsetCall)
        }

        getLatestPrice(sym, authToken)
        .then(price => setLatestPrice(price?.toFixed(2)))
        .catch(error => {
            toast.error(error.message || 'Failed to get latest price.')
        })
    },[selectedMethod, sym, authToken])

        
    const handleSubmit = async (e:React.FormEvent) => {
        e.preventDefault()
        if(!sym) {
            toast.error('Please select a authToken')
            return
        }
        if(!method) {
            toast.error('Please select a method')
            return
        }
        if(!validateNotional(Number(notional))) {
            toast.error('Please enter a valid notional value')
            return
        }
        if(method !== SPOT) {
            if(!frequency) {
                toast.error('Please select a frequency')
                return
            }
            if(!validateStrikeOffset(Number(strikeOffset))) {
                toast.error('Please enter a valid strike offset value')
                return
            }
            if(!expiry) {
                toast.error('Please select an expiry date')
                return
            }
        }
        try {
            if(user && user?.sub && authToken) {
                const {rfqid, fairprice} = await getRFQId({
                    clientId: user?.sub?.split('|')[1],
                    sym: sym.toUpperCase(),
                    strikeoffset: strikeOffset ? Number(strikeOffset)/100 : 0,
                    strikeoffsetcall: strikeOffsetCall ? Number(strikeOffsetCall)/100 : 0,
                    method,
                    frequency: frequency ? frequency : '', 
                    notional: notional ? Number(notional) : 0,
                    expiry: expiry ? moment(new Date(expiry)).format("YYYY[-]MM[-]DD") : '',
                }, authToken)

                if(rfqid !== undefined || fairprice !== undefined) {
                    localStorage.setItem('rfqid', rfqid)
                    setRFQId(rfqid)
                    
                    await getRFQById({
                        clientId: user.sub.split('|')[1],
                        rfqId: rfqid
                    }, authToken).then(data => {
                        const {strike_offset} = JSON.parse(data.parameters)
                        setRFQFormDetails({
                            ...data,
                            strike_offset: Number(strike_offset)*100,
                            date: new Date(data.date),
                            timestamp: data.timestamp.toString().split('').length === 10 ? Number(data.timestamp)*1000 : data.timestamp
                        })
                        localStorage.setItem('rfqformsubmitted', 'true')
                        setRFQFormSubmitted(true)
                    })

                    await getRFQList({
                        clientId: user.sub.split('|')[1],
                        rfqId: rfqid,
                        fairPrice: fairprice
                    }, authToken).then(res => {
                        setRFQList(res)
                    })
                 
                } else {
                    throw new Error('RFQ ID not found')
                }
                
                toast.success('RFQ submitted successfully')
                navigate("/rfq-submitted")
            } else {
                throw new Error('User not logged in')
            }
        } catch (error) {
            if((error as Error).message === 'EAuth0') {
                 localStorage.removeItem('token')
                navigate('/login')
                return;
              }
            toast.error((error as Error).message || 'Something went wrong')
        }
        
    }
  return (
    <form onSubmit={(e) => handleSubmit(e)}>
        <Toaster />
        <h3 className="font-[600] text-lg md:text-xl text-rfq-header mt-[1.5rem] md:mt-0">Send A RFQ</h3>
        <div className="flex flex-col bg-white w-full mt-[1.5rem] justify-between shadow-[2px_3px_30px_10px_rgba(222,235,241,0.35)]">
            <div className="flex flex-col md:grid md:grid-cols-2 pt-[1.5rem] md:pt-[3rem] px-[1rem] md:pl-[3.25rem] gap-[2.75rem] md:pr-[3.25rem]">
                <label htmlFor='sym'>
                    <p className="text-rfq-label-text">Token</p>
                    <select id='sym' className="text-rfq-value-text mt-[1rem] w-full border-b-[#E6EBEE] border-b-[1px]" onChange={(e) => setSym(e.target.value)} value={sym} required>
                        <option value="">Select</option>
                         {
                           [...SUPPORTED_CCY_PAIRS, ...CCY_PAIRS_VS_EUR].map((sym) => <option key={sym} value={sym}>{sym}</option>)
                         }
                    </select>
                </label>
                <label htmlFor='method'>
                    <p className="text-rfq-label-text">Hedging Method</p>
                    <select id="method" className="text-rfq-value-text mt-[1rem] w-full border-b-[#E6EBEE] border-b-[1px]" onChange={(e) => setMethod(e.target.value)} value={method} required>
                        <option value="">Select</option>
                        {
                            hedgingMethod.map((method) => {
                               return <option key={method.name} value={method.value}>{method.name}</option>
                            })
                        }
                    </select>
                </label>
                {method !== SPOT ? (<label className="flex flex-col" htmlFor='frequency'>
                    <p className="text-rfq-label-text flex flex-1">Frequency</p>
                    <select id="frequency" className="text-rfq-value-text mt-[1.2rem] w-full border-b-[#E6EBEE] border-b-[1px]" onChange={(e) => setFrequency(e.target.value)} value={frequency} disabled={method ===SPOT ? true : false} required>
                        <option value="">Select</option>
                        {
                            rollFrequency.map((frequency) => <option key={frequency} value={frequency}>{capFirstLetter(frequency)}</option>)
                        }
                    </select>
                </label>) : <></>}
                {method !== SPOT ? (<label htmlFor="expiry">
                    <p className="text-rfq-label-text">Expiry</p>
                    <select id="expiry" className="text-rfq-value-text mt-[1rem] w-full border-b-[#E6EBEE] border-b-[1px]" 
                        onChange={(e) => {
                            setExpiry(e.target.value)
                        }} value={expiry} disabled={method=== SPOT ? true: false} 
                        required
                    >
                        <option value="">Select</option>
                        {
                            frequency === 'monthly' 
                            ? generateExpiryOptions(FrequencyType.monthly)?.map(month => <option key={month}>{month}</option>) 
                            : frequency === 'quarterly'
                            ? generateExpiryOptions(FrequencyType.quarterly)?.map(quarter => <option key={quarter}>{quarter}</option>) 
                            : <></>
                        }
                    </select>
                </label>) : <></>}
                <label htmlFor="notional" className="flex flex-col justify-between">
                    <p className="text-rfq-label-text">{`Notional ${sym ? `(Amount in ${extractCryptoCcyFromPair(sym)})`: ''}`}</p>
                    <input id='notional' placeholder={'50'} min={0} className="text-rfq-value-text mt-[1rem] w-full border-b-[#E6EBEE] border-b-[1px]" onChange={(e) => setNotional(e.target.value)} value={notional} required/>
                </label>
                {!INSTRUMENTS_WITHOUT_STRIKE.includes(method) ? (
                    <label htmlFor='strikeoffset'>
                        <p className="text-rfq-label-text pb-[0.5rem]">{`Current ${extractCryptoCcyFromPair(sym)} Price: ${priceFormatWithDecimals(latestPrice)}`}</p>
                        <p className="text-rfq-label-text">{strikeOffsetPutFormField}</p>
                        <input id='strikeoffset' placeholder={'10'} min={0} max={100} type="number" className="text-rfq-value-text mt-[1rem] w-full border-b-[#E6EBEE] border-b-[1px]"
                            onChange={(e) => setStrikeOffset(e.target.value)}
                            value={strikeOffset}
                            disabled={INSTRUMENTS_WITHOUT_STRIKE.includes(method) ? true : false} required/>
                        <p className="text-rfq-label-text pb-[0.5rem]">{`Put Strike Price: ${priceFormatWithDecimals(latestPrice*(1-Number(strikeOffset)/100))}`}</p>
                    </label>
                ): <></>}

                {COLLAR==method ? (
                    <label>
                        <p className="text-rfq-label-text"></p>
                    </label>): <></>}
                    
                {COLLAR==method ? (
                    <label htmlFor='strikeoffsetcall'>
                        <p className="text-rfq-label-text">{strikeOffsetCallFormField}</p>
                        <input id='strikeoffsetcall' placeholder={'10'} min={0} max={100} type="number" className="text-rfq-value-text mt-[1rem] w-full border-b-[#E6EBEE] border-b-[1px]" onChange={(e) => setStrikeOffsetCall(e.target.value)} value={strikeOffsetCall} disabled={COLLAR!=method ? true : false} required/>
                        <p className="text-rfq-label-text pb-[0.5rem]">{`Call Strike Price: ${priceFormatWithDecimals(latestPrice*(1+Number(strikeOffsetCall)/100))}`}</p>
                    </label>
                    ): <></>}
            </div>
            <button type="submit" className="mx-auto rounded-[6px] h-[4.125rem] w-[10.875rem] bg-black text-white text-[1.25rem] font-[600] mt-[2rem] mb-[2.125rem] shadow-[2px_3px_24px_10px_rgba(0,0,0,0.15)]">Submit</button>
        </div>
    </form>
  )
}

export default RFQForm;

