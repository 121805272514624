
// NOTE: Please read the doc about syms before modifying anything in here

// fiat
export const USD = 'USD';
export const EUR = 'EUR';

// crypto
export const ETH = 'ETH';
export const STETH = 'STETH';
export const BTC = 'BTC';
export const USDC = 'USDC';
export const USDT = 'USDT';
export const NEAR = 'NEAR';
export const SOL = 'SOL';
export const MATIC = 'MATIC';
export const ADA = 'ADA';
export const AVAX = 'AVAX';
export const APT = 'APT';
export const OSMO = 'OSMO';
export const ATOM = 'ATOM';
export const XTZ = 'XTZ';
export const DOT = 'DOT';
export const KSM = 'KSM';
export const KAVA = 'KAVA';
export const INU = 'INU';
export const FTM = 'FTM';
export const ROSE = 'ROSE';
export const POKT = 'POKT';
export const BLD = 'BLD';
export const AXL = 'AXL';
export const CFG = 'CFG';
export const FLOW = 'FLOW';
export const MINA = 'MINA';
export const CSPR = 'CSPR';

// Currency Pairs
export const ETHUSD = ETH + USD;
export const STETHUSD = STETH + USD;
export const BTCUSD = BTC + USD;
export const USDCUSD = USDC + USD;
export const USDTUSD = USDT + USD;
export const NEARUSD = NEAR + USD;
export const SOLUSD = SOL + USD;
export const MATICUSD = MATIC + USD;
export const ADAUSD = ADA + USD;
export const AVAXUSD = AVAX + USD;
export const APTUSD = APT + USD;
export const MINAUSD = MINA + USD;
export const FLOWUSD = FLOW + USD;
export const CFGUSD = CFG + USD;
export const AXLUSD = AXL + USD;
export const BLDUSD = BLD + USD;
export const POKTUSD = POKT + USD;
export const ROSEUSD = ROSE + USD;
export const FTMUSD = FTM + USD;
export const INUUSD = INU + USD;
export const KAVAUSD = KAVA + USD;
export const KSMUSD = KSM + USD;
export const DOTUSD = DOT + USD;
export const XTZUSD = XTZ + USD;
export const ATOMUSD = ATOM + USD;
export const OSMOUSD = OSMO + USD;
export const CSPRUSD = CSPR + USD;

// vs EUR
export const USDCEUR = USDC + EUR;
export const USDTEUR = USDT + EUR;

export const SUPPORTED_CRYPTO_CURRENCIES = [ETH, STETH, BTC, USDC, USDT, NEAR, SOL, MATIC, ADA, AVAX, APT, OSMO, ATOM, XTZ, DOT, KSM, KAVA, INU, FTM, ROSE, POKT, BLD, AXL, CFG, FLOW, MINA, CSPR];
export const SUPPORTED_FIAT_CURRENCIES = [USD, EUR];
export const SUPPORTED_CURRENCIES = [...SUPPORTED_CRYPTO_CURRENCIES, ...SUPPORTED_FIAT_CURRENCIES];
export const SUPPORTED_CCY_PAIRS = SUPPORTED_CRYPTO_CURRENCIES.map((crypto) => crypto + USD).sort((a, b) => a.localeCompare(b));
export const CCY_PAIRS_VS_EUR = [USDCEUR, USDTEUR];


export const displayedSymToValue = [
    {
        symbol: ETH,
        value: ETHUSD
    },
    {
        symbol: BTC,
        value: BTCUSD
    },
    {
        symbol: STETH,
        value: STETHUSD
    },
    {
        symbol: USDT,
        value: USDTUSD
    },
    {
        symbol: NEAR,
        value: NEARUSD
    },
    {
        symbol: SOL,
        value: SOLUSD
    },
    {
        symbol: MATIC,
        value: MATICUSD
    },
    {
        symbol: ADA,
        value: ADAUSD
    },
    {
        symbol: AVAX,
        value: AVAXUSD
    },
    {
        symbol: APT,
        value: APTUSD
    },
    {
        symbol: OSMO,
        value: OSMOUSD
    },
    {
        symbol: ATOM,
        value: ATOMUSD
    },
    {
        symbol: XTZ,
        value: XTZUSD
    },
    {
        symbol: DOT,
        value: DOTUSD
    },
    {
        symbol: KSM,
        value: KSMUSD
    },
    {
        symbol: KAVA,
        value: KAVAUSD
    },
    {
        symbol: INU,
        value: INUUSD
    },
    {
        symbol: FTM,
        value: FTMUSD
    },
    {
        symbol: ROSE,
        value: ROSEUSD
    },
    {
        symbol: POKT,
        value: POKTUSD
    },
    {
        symbol: BLD,
        value: BLDUSD
    },
    {
        symbol: AXL,
        value: AXLUSD
    },
    {
        symbol: CFG,
        value: CFGUSD
    },
    {
        symbol: FLOW,
        value: FLOWUSD
    },
    {
        symbol: MINA,
        value: MINAUSD
    },
    {
        symbol: CSPR,
        value: CSPRUSD
    },
].sort((a, b) => a.symbol.localeCompare(b.symbol))


export const selectSymList = [
    {
        label: 'Select All',
        value: 'all'
    },
    ...displayedSymToValue.map(sym => {
        return {
            label: sym.symbol,
            value: sym.value
        }
    })
]

export type SymArrayType = typeof selectSymList;

export function extractCryptoCcyFromPair(sym: string) {
    sym = sym.toUpperCase();
    const fiat = sym.slice(-3);
    if (fiat !== '') {
        if (SUPPORTED_FIAT_CURRENCIES.includes(fiat)) {
            const crypto = sym.slice(0, -3);
            return crypto;
        } else {
            throw new Error(`fiat not supported: ${fiat} in ${sym}`);
        }
    } else {
        return sym;
    }
}