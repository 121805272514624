import React, {useState, useRef} from 'react'
import {
  ResponsiveContainer,
  AreaChart,
  XAxis, 
  YAxis,
  Area, 
  Tooltip,
  CartesianGrid,
  Line,
} from 'recharts';
import { Theme } from '../utils/Theme';
import moment from 'moment';
import Legend from './Legend';
import { startDate, formatOverviewChartCSVData, getSmartYtdStartDate, ticksArray, YAxisTickCount, ChartPeriod } from '../utils/chartUtils';
import CSVDownload from './CSVDownload';
import useOverview from '../hooks/useOverview';
import { CSVFileName } from '../utils/formFieldsMapping';
import CustomToolTip from './CustomToolTip';

const PnlChart = () => {
  const {overviewChartData, overviewChartCols} = useOverview()
  const [chartStartDate, setChartStartDate] = useState(Number(getSmartYtdStartDate()))
  const [chartPeriod, setChartPeriod] = useState('YTD')
  const {formattedData, headers} = formatOverviewChartCSVData(overviewChartData, overviewChartCols)
  const periodRef = useRef<any>(null)

  const handlePeriod = (e:any) => {
    if(periodRef.current?.childNodes){
      for (let i = 0; i < periodRef.current?.childNodes?.length; i++) {
        if(periodRef.current?.childNodes[i].textContent === e.target.innerText) {
          periodRef.current.childNodes[i].style.fontWeight = 600
          periodRef.current.childNodes[i].style.textDecoration = 'underline'
        } else {
          periodRef.current.childNodes[i].style.fontWeight = 400
          periodRef.current.childNodes[i].style.textDecoration = 'none'
        }
      }
    }

    setChartPeriod(e.target.innerText)
    if(e.target.innerText === 'Max') {
      setChartStartDate(overviewChartData[0].date) // date is sorted in ascending order
    } else if (startDate(e.target.innerText as string)) {
      Number(startDate(e.target.innerText as string)) < overviewChartData[0]?.date 
        ? setChartStartDate(overviewChartData[0]?.date) 
        : setChartStartDate(Number(startDate(e.target.innerText as string)))
    }
 }

 const customPeriodChartData = (data:typeof overviewChartData,startDate:number) => {
  return data.filter((data:any) => {
    return data.date >= startDate
  })}
  
  return (
    <article className="shadow-[2px_3px_30px_10px_rgba(222,235,241,0.35)] h-[35rem] rounded-[6px] bg-white pt-[1.875rem] pl-[2.625rem] pr-[3.25rem] w-[100%] min-w-[40rem]">
      <header className="flex h-[3.125rem] px-[2.625] items-center space-around mb-[1.75rem] md:mb-[2rem] w-full">
        <h3 className=" xl:text-xxl text-mlg font-[600] text-chart-header w-[30%] flex-1">{`Profit Over Time (k$)`}</h3>
        <div className="flex gap-[1.5rem] justify-self-end mr-[3rem]">
          <Legend color={`bg-primary-blue`} text={overviewChartCols[1] ? overviewChartCols[1] : ''}/>
          <Legend color={`bg-secondary-red`} text={overviewChartCols[2] ? overviewChartCols[2]: ''}/>
        </div>
        <CSVDownload 
          data={formattedData} 
          headers={headers}
          filename={CSVFileName.PnLChart}
        />
      </header>
      <ul className="flex gap-[1.875rem] text-chart-period text-xs md:text-large mb-[1.75rem] justify-center w-full" onClick={(e)=> handlePeriod(e)} ref={periodRef}>
          {
            Object.keys(ChartPeriod).filter(key => key !== '1m').map((key) => {
              return <li key={key} className="cursor-pointer">{ChartPeriod[key as keyof typeof ChartPeriod]}</li>
            })
          }
        </ul>
      <ResponsiveContainer width="100%" height={400}>
        <AreaChart data={customPeriodChartData(overviewChartData, chartStartDate)}>
          <defs>
            {/* colour gradient for blue-area */}
            <linearGradient id="colorBlue" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={Theme.primaryColor} stopOpacity={0.4}/>
              <stop offset="75%" stopColor={Theme.primaryColor} stopOpacity={0.05}/>
            </linearGradient>
          </defs>
          <defs>
          <linearGradient id="colorRed" x1="0" y1="0" x2="0" y2="1">
            {/* colour gradient for red-area */}
              <stop offset="0%" stopColor={Theme.secondaryColor} stopOpacity={0.4}/>
              <stop offset="75%" stopColor={Theme.secondaryColor} stopOpacity={0.05}/>
            </linearGradient>
          </defs>
          <Area dataKey={overviewChartCols[1] ? overviewChartCols[1]: ''} stroke={Theme.primaryColor} fill="url(#colorBlue)"/>
          <Area dataKey={overviewChartCols[2] ? overviewChartCols[2]: ''} stroke={Theme.secondaryColor} fill="url(#colorRed)"/>
          <XAxis 
            dataKey={"date"}
            type="number"
            axisLine={false} 
            tickLine={false} 
            ticks={ticksArray(customPeriodChartData(overviewChartData, chartStartDate), chartPeriod)}
            domain={[chartStartDate, 'dataMax']}
            tickFormatter={(date) => {
              // format x-axis ticks for max period
              if(chartPeriod === 'Max') {
                if(Math.abs(moment(overviewChartData[0]?.date).diff(moment(new Date()),'months')) <= 3) {
                  return moment(date).format('DD[/]MM[/]YY')
                } else {
                  return moment(date).format('MMM[-]YY')
                }
              }
              // format x-axis ticks for other periods
              if(chartPeriod === '3m' || chartPeriod === '1m') return moment(date).format('DD[/]MM[/]YY')
              return moment(date).format('MMM[-]YY')
            }}
          />

          <YAxis 
            axisLine={false} 
            tickLine={false} 
            tickCount={YAxisTickCount}
            tickFormatter={(tick) => (Number(tick)/1000).toLocaleString()}
          />

          <Tooltip content={<OverviewTooltip chartData={overviewChartCols}/>}/>

          <CartesianGrid vertical={false} opacity={0.5} strokeDasharray="5 5"/>
          <Line type="monotone" dataKey={overviewChartCols[1]? overviewChartCols[1]: ''} stroke={Theme.primaryColor}/>
          <Line type="monotone" dataKey={overviewChartCols[2]? overviewChartCols[2]: ''} stroke={Theme.secondaryColor}/>
        </AreaChart>

      </ResponsiveContainer>
    </article>
  )
}

interface CustomToolTipProps {
  active?: boolean;
  payload?: any;
  label?: string | number | Date;
  chartData: any;
}

export const OverviewTooltip = ({active, payload, label, chartData}:CustomToolTipProps) => {
  if(!payload) return <></>
  if(active && label) {
  return (
      <CustomToolTip>
        <h4>{moment(label).format('DD[/]MM[/]YY')}</h4>
        <div>
          <span className="text-primary-blue">{`${chartData[1]}:`}</span>
          <span className="text-black">{` $${(Math.round(payload[0]?.value/1000)).toLocaleString()}${payload[0]?.value >= 1000 ? 'k': ''}`}</span>
        </div>
        <div>
          <span className="text-secondary-red">{`${chartData[2]}:`}</span>
          <span className="text-black">{` $${(Math.round(payload[1]?.value/1000)).toLocaleString()}${payload[1]?.value >= 1000 ? 'k': ''}`}</span>
        </div>
      </CustomToolTip>
    )
  }
  return <></>
}

export default PnlChart;