import React from 'react'

interface LegendProps{
    color: string,
    text: string
}

const Legend = ({color, text}:LegendProps) => {
  return (
    <div className="flex gap-[0.5rem] items-center">
        <div className={`w-[1.25rem] h-[1.25rem] ${color} rounded-[6px]`}></div>
        <p className="text-xxs lg:text-sm">{text}</p>
    </div>
  )
}
export default Legend;