import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Overview from './pages/Overview';
import Pricing from './pages/Pricing';
import Backtest from './pages/Backtest';
import RFQ from './pages/RFQ';
import Docs from './pages/Docs';
import Profile from './pages/Profile';
import Support from './pages/Support';
import RFQSubmit from './pages/RFQSubmit';
import Login from './pages/Login';
import Register from './pages/Register';
import SubscriptionPlan from './pages/SubscriptionPlan';
import AuthLoading from './pages/AuthLoading';
import Logout from './pages/Logout';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/overview" element={<Overview />} />
        {/* to set up protected routes */}
        <Route path="/" element={<Overview />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/backtest" element={<Backtest />} />
        <Route path="/rfq" element={<RFQ />} />
        <Route path="/rfq-submitted" element={<RFQSubmit />} />
        <Route path="/docs" element={<Docs />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/support" element={<Support />} />
        <Route path="/subscribe" element={<SubscriptionPlan />} />
        <Route path='/loading' element={<AuthLoading/>}/>
        <Route path='/logout' element={<Logout/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
