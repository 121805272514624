import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'

const Logout = () => {
    const {logout} = useAuth0()
    const handleLogout = async () => {
      localStorage.removeItem('token');
      localStorage.removeItem('rfqid');
      logout({returnTo: window.location.origin + '/login'});
    }
  return (
    <div className="w-full h-[100vh] flex justify-center items-center">
        <button type="button" className="bg-white h-[4rem] w-[14rem] gap-[1.5rem] pl-[2.5rem] rounded-[6px] flex items-center border-2 cursor-pointer" onClick={handleLogout}>
            <div className="w-[1.25rem] h-[1.25rem]">
            <img src="logout.png" alt="logout" />
            </div>
            <p className="rounded-[6px] text-lg font-[600] self-center">{'Logout'}</p>
        </button>
    </div>
  )
}

export default Logout;