import React from 'react'
import ProfileSettingsMenu from './ProfileSettingsMenu';

const ProfileSettings = () => {
  return (
    <section className="flex flex-col bg-bg-dashboard h-full w-full">
        <ProfileSettingsMenu />
   </section>
  )
}

export default ProfileSettings;