import React,{useEffect, useState} from 'react'
import {
  ResponsiveContainer,
  AreaChart,
  XAxis, 
  YAxis,
  Area, 
  Tooltip,
  CartesianGrid,
  Line,
  Label,
  ReferenceLine
} from 'recharts';
import { Theme } from '../utils/Theme';
import Legend from './Legend';
import usePricing from '../hooks/usePricing';
import { formatPricingChartCSVData } from '../utils/chartUtils';
import { thousandSeparator, priceFormatWithDecimals, numFormat } from '../utils/numberFormat';
import CSVDownload from './CSVDownload';
import PricingChartExplanation from './PricingChartExplanation';
import { getLatestPrice } from '../utils/apiService';
import { CSVFileName } from '../utils/formFieldsMapping';
import CustomToolTip from './CustomToolTip';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { extractCryptoCcyFromPair } from '../utils/syms';

const PayoffChart = () => {
    const {pricingChartData, pricingTable, pricingFormValues} = usePricing();
    const compareCheck = pricingTable.methodCompare.name ? true : false;
    const {formattedData, headers} = formatPricingChartCSVData(pricingChartData, pricingTable)
    const [latestPrice, setLatestPrice] = React.useState(0);
    const {method:{sym}} = pricingFormValues;
    const [authToken] = useState<string | null>(localStorage.getItem('token'))
    const navigate = useNavigate()
    
    useEffect(() => {
      if(sym && authToken) {
        getLatestPrice(sym, authToken)
        .then(data => setLatestPrice(data.toFixed(2)))
        .catch(err => {
          if((err as Error).message === 'EAuth0') {
             localStorage.removeItem('token')
            navigate('/login')
            return;
          }
          toast.error((err as Error).message)
        })
      }
    },[sym, authToken, navigate])

    const getDivisor = (pricingChartData: any) =>{
      var ppnnll = pricingChartData[pricingChartData.length-1]?.method;
      if (ppnnll>1000000) {
        return 1000000;
      }
      if (ppnnll>1000) {
        return 1000;
      }
      return 1;
  
    }
  
    const getUnit = (pricingChartData: any) =>{
      var ppnnll = pricingChartData[pricingChartData.length-1]?.method;
      if (ppnnll>1000000) {
        return "M";
      }
      if (ppnnll>1000) {
        return "k";
      }
      return "";
  
    }

  
  return (
    <article className="md:mx-[2.875rem] shadow-[2px_3px_30px_10px_rgba(222,235,241,0.35)] rounded-[6px] pb-[3rem] bg-white pt-[1.875rem] pl-[2.625rem] pr-[3.25rem] min-w-[40rem]">
      <Toaster/>
      <header className="flex h-[3.125rem] px-[2.625] items-center mb-[1.75rem] md:mb-[2rem] w-full justify-end">
        <div className="flex gap-[2rem] justify-self-end mr-[3rem]">
          <Legend color={`bg-primary-blue`} text={pricingTable.method?.name}/>
          {compareCheck && (<Legend color={`bg-secondary-red`} text={pricingTable.methodCompare?.name}/>)}
        </div>
        <CSVDownload data={formattedData} headers={headers} filename={CSVFileName.PricingChart}/>
      </header>
      
      <ResponsiveContainer width="100%" height={400}>
        <AreaChart 
            data={pricingChartData} 
            margin={{bottom:30}}
        >
          <defs>
            <linearGradient id="colorBlue" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={Theme.primaryColor} stopOpacity={0.4}/>
              <stop offset="75%" stopColor={Theme.primaryColor} stopOpacity={0.05}/>
            </linearGradient>
          </defs>
          {compareCheck && (<defs>
            <linearGradient id="colorRed" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={Theme.secondaryColor} stopOpacity={0.4}/>
              <stop offset="75%" stopColor={Theme.secondaryColor} stopOpacity={0.05}/>
            </linearGradient>
          </defs>)}
          <Area dataKey="method" stroke={Theme.primaryColor} fill="url(#colorBlue)"/>
          {compareCheck && <Area dataKey="methodCompare" stroke={Theme.secondaryColor} fill="url(#colorRed)"/>}
          <XAxis 
            dataKey="price"
            type="number"
            axisLine={false} 
            tickLine={false} 
            tickCount={8}
            domain={['dataMin', 'dataMax']}
            tickFormatter={(price) => {
             return thousandSeparator(price)
            }}
          >
            <Label value="Market Price at Maturity ($)" offset={5} position="bottom" />
          </XAxis>

          <YAxis 
            axisLine={false} 
            tickLine={false} 
            tickCount={8}
            tickFormatter={(tick) => (Number(tick)/getDivisor(pricingChartData)).toLocaleString()}
            className="text-center"
            padding={{top:50}}
          >
            <Label 
                value={`Profit at Maturity (${getUnit(pricingChartData)}$)`}
                offset={5}
                position="insideTopLeft"
            />
          </YAxis>

          <Tooltip content={<PricingToolTip chartData={pricingTable}/>}/>
          <ReferenceLine 
            x={latestPrice} 
            stroke={'#000'} 
            strokeDasharray="3 3" 
            className="text-start flex justify-start"
            style={{textAnchor:'start', textAlign:'left'}}
    
          >
            <Label 
              position="insideTopLeft" 
              offset={10}
              value={`Current ${extractCryptoCcyFromPair(sym)} Price`}
            />
            <Label 
              position="insideTopLeft" 
              offset={30}
              value={`(${numFormat(latestPrice)})`}
              textAnchor="end"
            />
          </ReferenceLine>

          <CartesianGrid vertical={false} opacity={0.5} strokeDasharray="5 5"/>
          <Line type="monotone" dataKey="method" stroke={Theme.primaryColor}/>
          {compareCheck && <Line type="monotone" dataKey="methodCompare" stroke={Theme.secondaryColor}/>}
        </AreaChart>

      </ResponsiveContainer>
      <PricingChartExplanation/>
    </article>
  )
}

interface CustomToolTipProps {
  active?: boolean;
  payload?: any;
  label?: number;
  chartData: any;
}

export const PricingToolTip = ({active, payload, label, chartData}:CustomToolTipProps) => {
  if(!payload) return <></>
  if(active && label) {
    return (
      <CustomToolTip>
        <h4>{`Price: $${thousandSeparator(label as number)}`}</h4>
        <div>
          <span className="text-primary-blue">{`${chartData.method?.name}:`}</span>
          <span className="text-black">{` $${(Math.round(payload[0].value/1000)).toLocaleString()}${payload[0].value >= 1000 ? 'k': ''}`}</span>
        </div>
        {payload[1] && (
        <div>
          <span className="text-secondary-red">{`${chartData.methodCompare?.name && chartData.methodCompare?.name}:`}</span>
          <span className="text-black">{` $${(Math.round(payload[1].value/1000)).toLocaleString()}${payload[1].value >= 1000 ? 'k': ''}`}</span>
        </div>)}
      </CustomToolTip>
    )
  }
  return <></>
}

export default PayoffChart;