import React from 'react';
import usePricing from '../hooks/usePricing';
import { thousandSeparator, priceFormatWithDecimals, numFormat } from '../utils/numberFormat';
import { useNavigate } from 'react-router-dom';
import useMenu, {Selected} from '../hooks/useMenu';

const PayoffTable = () => {
    const {pricingTable, pricingFormValues} = usePricing()
    const {method, methodCompare} = pricingTable
    const navigate= useNavigate()
    const {setSelected} = useMenu()

    return (
        <article className="md:mx-[2.875rem] pb-[1.5rem] bg-white shadow-[2px_3px_30px_10px_rgba(222,235,241,0.35)] rounded-[6px] pt-[1.875rem]">
             <h3 className="font-[600] text-xlg text-backtest-header-text ml-[2.5rem]">{`Payoff Comparisons`}</h3>
                <table className="mt-[1.5rem] w-[85%] rounded-[6px] mb-[2rem] ml-[0.5rem] sm:ml-[2.5rem]">
                    <thead className="text-rfq-table-header-text text-sm font-[400]">
                        <tr className="border-b-[1px] h-[2.5rem] border-b-[#EBF2F5]">
                            <th className="text-start sm:text-center w-[2rem] md:w-[33%]">Method</th>
                            <th className="text-start w-[28%] pl-[1rem] md:pl-[3rem]">Parameters</th>
                            <th className="text-center">Price</th>
                        </tr>
                    </thead>
                    <tbody className="text-rfq-table-row-text text-xxs md:text-sm font-[400] bg-white">
                        <tr className="border-b-[1px] border-b-[#EBF2F5]">
                            {method.name ? (
                            <>
                                <td className="text-start sm:text-center break-words">{method.name}</td>
                                <td className="md:pl-[3rem] pl-[1rem] py-[1rem]">
                                    {method.parameters?.notional && <p className="md:text-xs text-xxs">{`Notional: ${method.parameters?.notional}`}</p>}
                                    {method.parameters?.strike_call && <p className="md:text-xs text-xxs">{`Strike - Call: ${thousandSeparator(method.parameters?.strike_call)}`}</p>}
                                    {method.parameters?.strike_put && <p className="md:text-xs text-xxs">{`Strike - Put: ${thousandSeparator(method.parameters?.strike_put)}`}</p>}
                                    {method.parameters?.time_to_maturity && <p className="md:text-xs text-xxs">{`Time to maturity: ${method.parameters?.time_to_maturity?.toFixed(2)}`}</p>}
                                    {method.parameters?.volatility && <p className="md:text-xs text-xxs">{`Vol: ${(method.parameters?.volatility*100)?.toFixed(2)}%`}</p>}
                                </td>
                                <td className="text-center">{`${numFormat(Number(method?.price))}`}</td>
                            </>
                            ):<></>}
                        </tr>
                        <tr>
                            {methodCompare.name ? (
                            <>
                                <td className="text-center">{methodCompare.name}</td>
                                <td className="md:pl-[3rem] pl-[1rem] py-[1rem]">
                                    {methodCompare.parameters?.notional ? <p className="md:text-xs text-xxs">{`Notional: ${methodCompare.parameters?.notional}`}</p> : <></>}
                                    {methodCompare.parameters?.strike_call ? <p className="md:text-xs text-xxs">{`Strike - Call: ${thousandSeparator(methodCompare.parameters?.strike_call)}`}</p> : <></>}
                                    {methodCompare.parameters?.strike_put ? <p className="md:text-xs text-xxs">{`Strike - Put: ${thousandSeparator(methodCompare.parameters?.strike_put)}`}</p> : <></>}
                                    {methodCompare.parameters?.time_to_maturity ? <p className="md:text-xs text-xxs">{`Time to maturity: ${methodCompare.parameters?.time_to_maturity?.toFixed(2)}`}</p> : <></>}
                                    {methodCompare.parameters?.volatility ? <p className="md:text-xs text-xxs">{`Vol: ${(methodCompare.parameters?.volatility*100)?.toFixed(2)}%`}</p> : <></>}
                                </td>
                                <td className="text-center">{`${priceFormatWithDecimals(Number(methodCompare?.price))}`}</td>
                            </>): <></>}
                        </tr>
                    </tbody>
                </table>
                {pricingFormValues.pricingFormSubmitted && (
                <button
                    className="mx-auto rounded-[6px] h-[4.125rem] px-[2rem] text-mlg bg-black text-white  mb-[1rem] shadow-[2px_3px_24px_10px_rgba(0,0,0,0.15)] flex self-center"
                    onClick={() => {
                        navigate('/backtest')
                        setSelected(Selected.Backtest)
                    }}>
                    <p className="mx-auto flex self-center text-mlg font-[500]">Backtest Strategies</p>
                </button>)}
        </article>
    )
}

export default PayoffTable;