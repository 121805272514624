import { isValidDate } from "./sortDate";
import moment from 'moment';
import { SingleBacktestPerformance } from "./chartUtils";
import { SingleOverviewChartData } from "./chartUtils";
import {  PRIDWEN_START_DATE_STR } from "./constants";
import { SPOT } from "./instruments";

export const BACKEND_API_URL = process.env.REACT_APP_BACKEND_URL as string;
export const domain = process.env.REACT_APP_AUTH0_DOMAIN as string;


export interface Auth0UserDetailsProps {
  email: string;
  email_verified: boolean;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;
  method: string;
  strikeOffset: number | string;
  revenue: number | string;
  sym: string;
  frequency: string;
  startDate: string;
}

export const getUserId = (sub: string) => {
  return sub.split("|")[1];
};

/*
* Function is called to register user's trade options when user first signs up
*/

export const registerTradeOptions = async (userDetails: Auth0UserDetailsProps, token:string) => {
  const signUpData = new FormData();
  // check if user has registered, if registered, no sign up again
  if(!token) throw new Error('Unauthorized!')
  const {isregistered} = await getRegistrationStatus(userDetails.sub.split('|')[0], token);
  if (isregistered === "true") return;
  console.log('userdetails', userDetails)
  const {email, sub, method, frequency, revenue, sym, strikeOffset, startDate} = userDetails
  
  const formattedSignUpData = {
    email,
    clientId: sub.split("|")[1],
    method,
    frequency,
    revenue,
    sym,
    percentageSpot: strikeOffset,
    startdate: startDate,
    auth0Token: token
  };
  for (const [key, value] of Object.entries(formattedSignUpData)) {
    signUpData.append(key, value.toString());
  }
  try {
    const data = await fetch(`${BACKEND_API_URL}/auth/register`, {
      method: "POST",
      mode: "cors",
      body: signUpData,
    });
    const json = await data.json();
    return json;
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const getOverviewData = async (clientId: string, sym:string[], token: string) => {
  const clientData = new FormData();
  if (!clientId) throw new Error("Client Id is required");
  if(!token) throw new Error('Unauthorized')
  clientData.append("clientId", clientId);
  clientData.append('auth0Token', token);
  try {
    const symbols = (!sym || sym.length === 0) ? 'ethusd' : sym.length === 1 ? sym[0] : sym.join('-')
    const {
      result: { pnlhedging },
      error: pnlhedgingError
    } = await fetch(`${BACKEND_API_URL}/overview/pnlhedging?sym=${symbols}`, {
      method: "POST",
      mode: "cors",
      body: clientData,
    })
    .then((data) => {
      if(!data) {
        throw new Error('data not available')
      }
      return data.json()
    })
    .catch((error) => {
      throw new Error(error.message)
    })
    
    const {
      result: { pnlnohedging },
      error: pnlnohedgingError
    } = await fetch(`${BACKEND_API_URL}/overview/pnlnohedging?sym=${symbols}`, {
      method: "POST",
      mode: "cors",
      body: clientData,
    })
    .then((data) => {
      if(!data) {
        throw new Error('data not available')
      }
      return data.json()
    })
    .catch((error) =>{ 
      throw new Error(error.message)
    })
    
    const {
      result: { savings, savingspercentage },
      error: savingsError
    } = await fetch(`${BACKEND_API_URL}/overview/savings?sym=${symbols}`, {
      method: "POST",
      mode: "cors",
      body: clientData,
    }).then((data) => {
      if(!data) {
        throw new Error('data not available')
      }
      return data.json()
    })
      .catch((error) => {
        throw new Error(error.message)
      })
    
    // error handling
    if(pnlhedgingError && pnlhedgingError.length !== 0) {
      
        throw new Error(pnlhedgingError[0].split(':')[0])
    }
    if(pnlnohedgingError && pnlnohedgingError.length !== 0) {
      throw new Error(pnlnohedgingError[0].split(':')[0])
    }
    if(savingsError && savingsError.length !== 0) {
      
        throw new Error(savingsError[0].split(':')[0])
    }

    return {
      pnlhedging,
      pnlnohedging,
      savings,
      savingspercentage,
    }
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const getOverviewCharts = async (clientId: string, sym: string[], token: string) => {
  const clientData = new FormData();

  clientData.append("clientId", clientId);
  clientData.append('auth0Token', token);
  try {
    if (!clientId) throw new Error("Client Id is required");
    if(!token) throw new Error('Unauthorized')
    const symbols = (!sym || sym.length === 0) ? 'ethusd' : sym.length === 1 ? sym[0] : sym.join('-')    
    const {
      result: { performance },
      error: performanceChartError
    } = await fetch(
      `${BACKEND_API_URL}/overview/performance?sym=${symbols}&startdate=${PRIDWEN_START_DATE_STR}`,
      {
        method: "POST",
        mode: "cors",
        body: clientData,
      }
    ).then((data) => data.json());

    const performanceTimeSeries = performance.data.map((item: any) => {
      if (isValidDate(new Date(item[0]))) {
        return {
          date: item[0],
          [performance.columns[1]]: item[1],
          [performance.columns[2]]: item[2],
        };
      }
      return null;
    })
    .sort((a:SingleOverviewChartData,b:SingleOverviewChartData) => a.date - b.date)

    const performanceColumns = performance.columns

    if(performanceChartError.length !== 0) {
        throw new Error(performanceChartError[0].split(':')[0])
    }

    return {
        performanceColumns,
        performanceTimeSeries
    }
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

interface IPricingData {
  clientId: string;
  sym: string;
  method: string;
  revenue: number | string;
  frequency: string;
  strikeoffset: number | string;
  strikeoffsetcall: number | string;
  methodcompare: string;
  frequencycompare: string;
  strikeoffsetcompare: number | string;
  strikeoffsetcallcompare: number | string;
}

export const getPriceSimulationData = async (pricingData: IPricingData, token: string) => {
  const {
    sym,
    method,
    revenue,
    strikeoffset,
    strikeoffsetcall,
    frequency,
    methodcompare,
    strikeoffsetcompare,
    frequencycompare,
    strikeoffsetcallcompare,
  } = pricingData;
  const pricingFormData = new FormData();
  if (!pricingData.clientId) throw new Error("Client Id is required");
  if(!token) throw new Error('Unauthorized')
  pricingFormData.append("clientId", pricingData.clientId);
  pricingFormData.append('auth0Token', token);
  const pricingURL = methodcompare
    ? `${BACKEND_API_URL}/pricing/simulate?sym=${sym}&method=${method}&revenue=${revenue}&frequency=${frequency}&strikeoffset=${strikeoffset}&strikeoffsetcall=${strikeoffsetcall}&methodcompare=${methodcompare}&frequencycompare=${frequencycompare}&strikeoffsetcompare=${strikeoffsetcompare}&strikeoffsetcallcompare=${strikeoffsetcallcompare}`
    : `${BACKEND_API_URL}/pricing/simulate?sym=${sym}&method=${method}&revenue=${revenue}&frequency=${frequency}&strikeoffset=${strikeoffset}&strikeoffsetcall=${strikeoffsetcall}`;

  try {
    const {
      result: { pnl, prices, explanation },
      error: pricingError
    } = await fetch(pricingURL, {
      method: "POST",
      mode: "cors",
      body: pricingFormData,
    }).then((data) => data.json());
    
    const pricingChartData = pnl.data.map((item: any) => ({
      price: item[0],
      method: item[1],
      methodCompare: item.length === 3 ? item[2] : null,
    }));

    const pricingTable = {
      method: {
        name: prices.data[0][0],
        parameters: prices.data[0][1],
        price: prices.data[0][2],
      },
      methodCompare: {
        name: prices.data[1] ? prices.data[1][0] : "",
        parameters: prices.data[1] ? prices.data[1][1] : "",
        price: prices.data[1] ? prices.data[1][2] : "",
      },
    };

    const pricingExplanationText = {
        method: {
            header: explanation.data[0][0],
            text: explanation.data[0][1],
        },
        methodCompare: {
            header: explanation.data[1] ? explanation.data[1][0]: '',
            text: explanation.data[1] ? explanation.data[1][1]: '',
        }
    }

    if(pricingError.length !== 0) {
        throw new Error (pricingError[0].split(':')[0])
    }

    return {
      pricingChartData,
      pricingTable,
      pricingExplanationText
    };
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

interface IBacktestData {
  clientId: string;
  sym: string;
  method: string;
  frequency: string;
  strikeoffset: number | string;
  strikeoffsetcall: number | string;
  methodcompare: string;
  frequencycompare: string;
  strikeoffsetcompare: number | string;
  strikeoffsetcallcompare: number | string;
  revenue: number | string;
  withuserrevenues: boolean;
  startDate: Date;
}

export const getBacktestChartData = async (backtestData: IBacktestData, token: string) => {
  const {
    sym,
    method,
    strikeoffset,
    strikeoffsetcall,
    revenue,
    frequency,
    methodcompare,
    strikeoffsetcompare,
    strikeoffsetcallcompare,
    frequencycompare,
    withuserrevenues,
    startDate,
  } = backtestData;
  const backtestFormData = new FormData();
  if (!backtestData?.clientId) throw new Error("Client Id is required");
  if(!token) throw new Error('Unauthorized')
  backtestFormData.append("clientId", backtestData?.clientId);
  backtestFormData.append('auth0Token', token);
  const startDateStr= moment(startDate).format("YYYY[-]MM[-]DD").toString()

  const backtestURL = methodcompare
    ? `${BACKEND_API_URL}/backtest/runbacktest?sym=${sym}&method=${method}&frequency=${frequency}&strikeoffset=${Number(strikeoffset)}&strikeoffsetcall=${Number(strikeoffsetcall)}&methodcompare=${methodcompare}&frequencycompare=${frequencycompare}&strikeoffsetcompare=${Number(strikeoffsetcompare)}&strikeoffsetcallcompare=${Number(strikeoffsetcallcompare)}&revenue=${revenue}&withuserrevenues=${withuserrevenues}&&startdate=${startDateStr}`
    : `${BACKEND_API_URL}/backtest/runbacktest?sym=${sym}&method=${method}&frequency=${frequency}&strikeoffset=${Number(strikeoffset)}&strikeoffsetcall=${Number(strikeoffsetcall)}&revenue=${revenue}&withuserrevenues=${withuserrevenues}&&startdate=${startDateStr}`;

  try {
    const {
      result: { backtest, backtest_summary },
      error: backtestError
    } = await fetch(backtestURL, {
      method: "POST",
      mode: "cors",
      body: backtestFormData,
    }).then((data) => data.json());

    const backtestTimeSeries = backtest.data
      .map((item: any) => {
        if (isValidDate(new Date(item[0]))) {
          return {
            date: item[0],
            method: item[1],
            methodCompare: item.length === 3 ? item[2] : null,
          };
        }
        return null;
      })
      .sort(
        (a: SingleBacktestPerformance, b: SingleBacktestPerformance) =>
          a.date - b.date
      );

    const backtestSummary = backtest_summary.data.map((item: any) => ({
      method: item[0],
      parameters: item[1],
      pnl: item[2],
      id: (item[0] + (item[2] ? item[2].toString(): '')).replace(/\s+/g,"") // to work on this
    }));


    if(backtestError.length !== 0) {
        throw new Error(backtestError[0].split(':')[0])
    }

    return {
      backtestTimeSeries,
      backtestSummary,
    };
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const getRegistrationStatus = async (userId: string, token: string) => {
    try {
        const userData = new FormData();
        if(!userId) throw new Error('No user id provided.')
        if(!token) throw new Error('Unauthorized')
        // pass user Id instead of email
        userData.append("clientId", userId);
        userData.append('auth0Token', token);
        const {
            result,
            error: registrationError
        } = await fetch(`${BACKEND_API_URL}/auth/isregistered`, {
            method: "POST",
            mode: "cors",
            body: userData,
        }).then((data) => data.json());
        console.log('registration', result)
        if(registrationError.length !==0) {
            throw new Error (registrationError[0].split(':')[0])
        }
        const {isregistered, stripestatus} = result;
        return {
          isregistered,
          stripestatus
        }
  } catch (error) {
    throw new Error((error as Error).message)
  }
};

export const getLatestPrice = async (sym:string, token: string) => {
    try{
      if(!token) throw new Error('Unauthorized')
      const tokenData = new FormData();
      tokenData.append('auth0Token', token);
        if(!sym) {
            return
        } 
        const {
            result: { latestprice },
            error: latestpriceError
        } = await fetch(`${BACKEND_API_URL}/marketprices/getlatestprice?sym=${sym}`, {
          body:tokenData,
          method:'POST',
          mode: "cors",
        }).then(data => data.json())
        
        if(latestpriceError.length !== 0) {
            throw new Error(latestpriceError[0].split(':')[0])
        }
        
        return latestprice
    } catch (error) {
        throw new Error((error as Error).message)
    }
}

export const uploadRevenuesCSV = async (file: File, clientId:string, sym:string, token: string) => {
    
    try{
        if(!file) {
            throw new Error('File attachment is required')
        }
        if(!clientId) {
            throw new Error('Client Id is required')
        }
        if(!token) throw new Error('Unauthorized')
        const csvFormData = new FormData()
        csvFormData.append('clientId', clientId)
        csvFormData.append('revenues',file)
        csvFormData.append('auth0Token', token);
        
        const res = await fetch(`${BACKEND_API_URL}/backtest/uploadrevenues?sym=${sym}`, {
            method:'POST',
            mode: "cors",
            body: csvFormData,
        }).then(data => data.json())

        if(res?.error.length !== 0) {
            throw new Error(res?.error[0]?.split(':')[0])
        }

        return res
    } catch (error) {
        throw new Error((error as Error).message)
    }
}

interface IHedgingDetails {
    clientId:string,
    sym: string,
    method: string,
    frequency: string,
    strikeoffset:string | number,
    startdate:Date
}

export const changeHedgingMethod = async (hedgingDetails:IHedgingDetails, token: string) => {
    const clientFormData = new FormData();
    const {clientId, sym, method, frequency, strikeoffset, startdate} = hedgingDetails
    try {
        if (!clientId) throw new Error("Client Id is required");
        if(!token) throw new Error('Unauthorized')
        clientFormData.append("clientId", clientId);
        clientFormData.append('auth0Token', token);
        const startDateStr = moment(startdate).format("YYYY[-]MM[-]DD").toString();
        
        const {
        result: { changehedgingmethod },
        error: changeError
        } = await fetch(`${BACKEND_API_URL}/overview/changehedgingmethod?sym=${sym}&method=${method}&frequency=${frequency}&strikeoffset=${strikeoffset}&startdate=${startDateStr}`, {
            method: "POST",
            mode: "cors",
            body: clientFormData,
        })
        .then(async (data) => {
          return await data.json()
        });

        if(changeError.length !== 0) {
            throw new Error(changeError[0]?.split(':')[0])
        }
        return changehedgingmethod;

    } catch (error) {
        throw new Error((error as Error).message)
    }
}

interface ILatestHedgingMethodInput {
    clientId: string,
    sym: string
}


export const getLatestHedgingMethod = async (latestHedgingMethodInput:ILatestHedgingMethodInput, token: string) => {
    const clientFormData = new FormData();
    const {clientId, sym} = latestHedgingMethodInput;
    try {
        if (!clientId) throw new Error("Client Id is required");
        if(!token) throw new Error('Unauthorized')
        clientFormData.append('clientId', clientId)
        clientFormData.append('auth0Token', token)
        const {
        result: { latesthedgingmethod },
        error: hedgingMethodError
        } = await fetch(`${BACKEND_API_URL}/overview/latesthedgingmethod?sym=${sym}`, {
            method: "POST",
            mode: "cors",
            body: clientFormData,
        })
        .then((data) => data.json());

        if(hedgingMethodError.length !== 0) {
            throw new Error(hedgingMethodError[0]?.split(':')[0])
        }

        return latesthedgingmethod?.method;

    } catch (error) {
        throw new Error((error as Error).message)
    }
}

interface IRFQDetails {
    clientId: string,
    sym: string,
    method:string,
    frequency: string,
    strikeoffset: number | string,
    strikeoffsetcall: number | string,
    notional: number|string
    expiry: Date|string,
}

export const getRFQId = async (RFQDetails: IRFQDetails, token: string) => {
    const clientFormData = new FormData();
    const {clientId, sym, method, frequency, strikeoffset, strikeoffsetcall, notional, expiry} = RFQDetails;
    try {
        if(!clientId) throw new Error('Client Id is required')
        if(!token) throw new Error('Unauthorized')
        clientFormData.append('clientId', clientId)
        clientFormData.append('auth0Token', token)
        const url = method !== SPOT ? `sym=${sym}&method=${method}&strikeoffset=${strikeoffset}&strikeoffsetcall=${strikeoffsetcall}&notional=${notional}&frequency=${frequency}&expiry=${expiry.toString()}` : `sym=${sym}&method=${method}&strikeoffset=${strikeoffset}&strikeoffsetcall=${strikeoffsetcall}&notional=${notional}&frequency=${frequency}`
        
        const {
            result: { fairprice, rfqid },
            error: rfqError
            } = await fetch(`${BACKEND_API_URL}/rfq/submit?${url}`, {
                method: "POST",
                mode: "cors",
                body: clientFormData,
            })
            .then((data) => data.json());
    
            if(rfqError.length !== 0) {
                throw new Error(rfqError[0]?.split(':')[0])
            }

            return {
                fairprice,
                rfqid
            }

    } catch (error) {
        throw new Error((error as Error).message)
    }
    
}

interface IUsernameInput {
    clientId: string,
    username:string
}

export const changeUserName = async (usernameInput: IUsernameInput, token: string) => {
    const {clientId, username} = usernameInput;
    const clientFormData = new FormData()
    try {
        if(!clientId) throw new Error ('Client Id is required')
        if(!username) throw new Error('Username is required')
        if(!token) throw new Error('Unauthorized')
        
        clientFormData.append('clientId', clientId)
        clientFormData.append('auth0Token', token)

        const {result:{success}, error} = await fetch(`${BACKEND_API_URL}/user/username?username=${username}`,{
            method: "POST",
            mode: "cors",
            body: clientFormData,
        }).then(data => data.json())

        if(error.length !== 0) {
            throw new Error(error[0]?.split(':')[0])
        }

        return success
    } catch (error) {
        throw new Error((error as Error).message)
    }
}

interface IUserEmailInput {
    clientId: string,
    email:string
}

export const changeUserEmail = async (userEmailInput: IUserEmailInput, token: string) => {
    const {clientId, email} = userEmailInput;
    const clientFormData = new FormData()
    try {
        if(!clientId) throw new Error ('Client Id is required')
        if(!email) throw new Error('Email is required')
        if(!token) throw new Error('Unauthorized')
        
        clientFormData.append('clientId', clientId)
        clientFormData.append('auth0Token', token)

        const {result:{success}, error} = await fetch(`${BACKEND_API_URL}/user/email?email=${email}`,{
            method: "POST",
            mode: "cors",
            body: clientFormData,
        }).then(data => data.json())

        if(error.length !== 0) {
            throw new Error(error[0]?.split(':')[0])
        }

        return success
    } catch (error) {
        throw new Error((error as Error).message)
    }
}

interface IUserPictureInput {
    clientId: string,
    picture: File | string,
}

export const changeUserPicture = async (userPictureInput:IUserPictureInput, token: string) => {
    const {clientId, picture} = userPictureInput;
    const clientFormData = new FormData()
    try {
        if(!clientId) throw new Error ('Client Id is required')
        if(!picture || !(picture as File).name) throw new Error('Picture is required')
        if(!token) throw new Error('Unauthorized')
        
        clientFormData.append('clientId', clientId)
        clientFormData.append('picture', picture)
        clientFormData.append('auth0Token', token)

        const {result, error} = await fetch(`${BACKEND_API_URL}/user/picture`,{
            method: "POST",
            mode: "cors",
            body: clientFormData,
        }).then(data => data.json())

        if(error.length !== 0) {
            throw new Error(error[0]?.split(':')[0])
        }

        return result?.picture
    } catch (error) {
        throw new Error((error as Error).message)
    }
}


export const srcToFile = (src:string, fileName:string, mimeType:string) => {
    return (fetch(src)
        .then(function(res){return res.arrayBuffer();})
        .then(function(buf){return new File([buf], fileName, {type:mimeType});})
        .catch(error => {throw new Error(error)})
    );
}

interface IUserPasswordInput {
    clientId: string,
    currentPassword:string,
    newPassword:string,
}

export const changeUserPassword = async (userPasswordInput:IUserPasswordInput, token: string) => {
    const {clientId, currentPassword, newPassword } = userPasswordInput;
    const clientFormData = new FormData()
    try {
        if(!clientId) throw new Error ('Client Id is required')
        if(!currentPassword) throw new Error('Current password input is required')
        if(!newPassword) throw new Error('New password input is required')
        if(!token) throw new Error('Unauthorized')

        const passwordInput = {
            clientId: clientId,
            password: newPassword,
            currentPassword: currentPassword,
        }
        clientFormData.append('auth0Token', token)

        for (const [key, value] of Object.entries(passwordInput)) {
            clientFormData.append(key, value.toString());
        }

        const {result:{success}, error} = await fetch(`${BACKEND_API_URL}/user/password`,{
            method: "POST",
            mode: "cors",
            body: clientFormData,
        }).then(data => data.json())

        if(error.length !== 0) {
            throw new Error(error[0]?.split(':')[0])
        }
        return success
    } catch (error) {
        throw new Error((error as Error).message)
    }
}

interface ISingleRFQ {
  rfqId:string, 
  clientId:string,
  fairPrice:number | string
}

export const getRFQList = async (singleRFQ:ISingleRFQ, token: string) => {
  const clientFormData = new FormData();
  const {clientId, rfqId, fairPrice} = singleRFQ
  try {
      if(!clientId) throw new Error('Client Id is required')
      if(!rfqId) throw new Error('rfqId is required')
      if(!token) throw new Error('Unauthorized')

      clientFormData.append('clientId', clientId)
      clientFormData.append('auth0Token', token)
      const {
        result,
        error: rfqError
    } = await fetch(`${BACKEND_API_URL}/rfq/getquotes?rfqid=${rfqId}`,{
        method: "POST",
        mode: "cors",
        body: clientFormData,
    }).then(data => data.json())
    if(rfqError.length !== 0) {
        throw new Error(rfqError[0]?.split(':')[0])
    }
    console.log('rfqlist', result)

    const quoteList = result.quotes.data.map((quote:any) => {
        return {
            [result.quotes.columns[0]]:quote[0].toString().length === 10 ? Number(quote[0])*1000 : Number(quote[0]), // date
            [result.quotes.columns[1]]: quote[1], // quoteid
            [result.quotes.columns[2]]: quote[2], // notional
            [result.quotes.columns[3]]: quote[3], // entity
            ["bidPrice"]: Number(quote[4] || ''), // price
            ["askPrice"]: Number(quote[5] || ''), // price
            fairPrice: Number(fairPrice || '') 
        }
    })
    console.log('quoteList', quoteList)

    return quoteList
  } catch (error) {
      throw new Error((error as Error).message)
  }
}

interface IRFQInput {
  clientId: string,
  rfqId: string
}

export const getRFQById = async (RFQInput: IRFQInput, token: string) => {
    const {clientId, rfqId} = RFQInput;
    const clientFormData = new FormData()
    try {
      if(!clientId) throw new Error ('Client Id is required')
      if(!rfqId) throw new Error('RFQ Id is required')
      if(!token) throw new Error('Unauthorized')

      clientFormData.append('clientId', clientId);
      clientFormData.append('auth0Token', token)

      const {result:{rfq}, error} = await fetch(`${BACKEND_API_URL}/rfq/getrfq?rfqid=${rfqId}`,{
          method: "POST",
          mode: "cors",
          body: clientFormData,
      }).then(data => data.json())

      if(error.length !== 0) {
          throw new Error(error[0]?.split(':')[0])
      }

      return rfq
  } catch (error) {
      throw new Error((error as Error).message)
  }

}

interface IRQInput {
  clientId: string,
  symArray:string[]
}

export const getRecentQuoteList = async(RQInput:IRQInput, token: string) => {
  if(!RQInput || Object.values(RQInput).length === 0) {
    return
  }
  const {clientId, symArray} = RQInput;
  const clientFormData = new FormData()
  try {
    if(!clientId) throw new Error ('Client Id is required')
    if(!symArray) throw new Error('Token sym is required')
    if(!token) throw new Error('Unauthorized') 
    const symbols = (symArray.length === 0) ? 'ethusd' : symArray.length === 1 ? symArray[0] : symArray.join('-')
    clientFormData.append('clientId', clientId);
    clientFormData.append('auth0Token', token)

    const {result, error} = await fetch(`${BACKEND_API_URL}/rfq/getrecentquotes?sym=${symbols}`,{
        method: "POST", 
        mode: "cors",
        body: clientFormData,
    }).then(data => {
      return data.json()
    })
    if(error.length !== 0) {
      throw new Error(error[0]?.split(':')[0])
    }
    if(result.recentquotes !== undefined) {
      const recentQuoteList = result.recentquotes.data?.map((item:any) => {
        return {
          [result.recentquotes.columns[0]]: item[0], // Date
          [result.recentquotes.columns[1]]: item[1], // Timestamp
          [result.recentquotes.columns[2]]: item[2], // Product
          [result.recentquotes.columns[3]]: item[3], // Notional
          [result.recentquotes.columns[4]]: item[4], // Counterparty
          [result.recentquotes.columns[5]]: item[5], // FairPrice
          [result.recentquotes.columns[6]]: item[6],  // QuotedPriceBid
          [result.recentquotes.columns[7]]: item[7],  // QuotedPriceAsk
          [result.recentquotes.columns[8]]: item[8]  // QuoteId
        }
      })

      const recentQuoteCols = result.recentquotes.columns

      return {
        recentQuoteList,
        recentQuoteCols
      }
    }
  } catch (error) {
      throw new Error((error as Error).message)
  }

}

interface ITradeInput {
  clientId: string,
  quoteId: string
}

export const postTrade = async (tradeInput:ITradeInput, token: string) => {
  const {clientId, quoteId} = tradeInput;
    const clientFormData = new FormData()
    try {
      if(!clientId) throw new Error ('Client Id is required')
      if(!quoteId) throw new Error('Token sym is required')
      if(!token) throw new Error('Unauthorized')
      clientFormData.append('clientId', clientId)
      clientFormData.append('auth0Token', token)

      const {
        result,
        error
      } =  await fetch(`${BACKEND_API_URL}/rfq/trade?quoteid=${quoteId}`,{
        method: "POST",
        mode: "cors",
        body: clientFormData,
      }).then(data => data.json())

      if(error.length !== 0) {
        throw new Error(error[0]?.split(':')[0])
      }

      return result?.trade

    } catch (error) {
      throw new Error((error as Error).message)
    }

}

interface ITradeDetails {
  clientId:string;
  rfqId: string;
}

export interface IQuoteResponse {
  title: string;
  message: string;
  telegramlink: string;
  status: string;
  button:string;
}

export const getPopUpContent= async (tradeDetails:ITradeDetails, token:string) => {
  const {clientId, rfqId} = tradeDetails;
    const clientFormData = new FormData()
    try {
      if(!clientId) throw new Error ('Client Id is required')
      if(!token) throw new Error('Unauthorized')
      clientFormData.append('clientId', clientId)
      clientFormData.append('auth0Token', token)
      const {
        result,
        error
      } =  await fetch(`${BACKEND_API_URL}/rfq/trade?quoteid=${rfqId}`,{
        method: "POST",
        mode: "cors",
        body: clientFormData,
      })
      .then(data => data.json())
  

      if(error.length !== 0) {
        throw new Error(error[0]?.split(':')[0])
      }

      const {title, message, telegramlink, status, button} = result

      return {
        title,
        message,
        telegramlink,
        status,
        button
      }

    } catch (error) {
      throw new Error((error as Error).message)
    }
}