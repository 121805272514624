import React, {useState} from 'react'
import toast, {Toaster}  from 'react-hot-toast'
import { useAuth0 } from '@auth0/auth0-react';
import { changeUserPassword } from '../utils/apiService';
import { useNavigate } from 'react-router-dom';

const PasswordSetting = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const {user, logout} = useAuth0();
    const [authToken] = useState<string | null>(localStorage.getItem('token'))
    const navigate = useNavigate()

    const handleSubmit = async (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const confirmChange = window.confirm('You will be logged out after changing your password. Would you like to proceed?')
        if(confirmChange && authToken){
            try {
                if(!currentPassword) {
                    toast.error('Please enter a valid current password')
                    return
                }
    
                if(!newPassword) {
                    toast.error('Please enter a valid new password')
                    return
                }
    
                if(confirmPassword !== newPassword) {
                    toast.error('New password and confirm password do not match')
                    return
                }
    
                if(user && user?.sub) {
                    const res = await changeUserPassword({
                        clientId: user && user?.sub ? user?.sub?.split('|')[1] : '',
                        newPassword: newPassword,
                        currentPassword: currentPassword
                    }, authToken)
                    if(res === 'True') {
                        toast.success('Password changed successfully')
                        logout({returnTo: window.location.origin + '/login'})
                    }
                }
            } catch (error) {
                if((error as Error).message === 'EAuth0') {
                     localStorage.removeItem('token')
                     localStorage.removeItem('rfqid')
                    navigate('/login')
                    return;
                  }
                toast.error((error as Error)?.message || 'Failed to change user email. Please try again.')
            } finally {
                setCurrentPassword('')
                setNewPassword('')
                setConfirmPassword('')
            }
        }

        // setPasswordSetting({
        //     currentPassword,
        //     newPassword,
        //     confirmPassword
        // })
    }

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-[1.5rem] mt-[1.5rem] w-[100mv] pr-[1rem]">
        <Toaster/>
        <label htmlFor="current-password" className="flex flex-col gap-[0.5rem]">
            <p className="text-xs mb-[0.5rem] text-rfq-label-text">Current Password</p>
            <input 
                type="password" 
                id="current-password" 
                name="current-password" 
                placeholder={"Enter current password"} 
                onChange={(e) => setCurrentPassword(e.target.value)} 
                value={currentPassword} 
                className="w-full md:w-[25rem] h-[3rem] border-[1px] border-bg-profile-setting rounded-[10px] pl-[1rem] text-lg"/>
        </label>
        <label htmlFor="new-password">
            <p className="text-xs mb-[0.5rem] text-rfq-label-text">New Password</p>
            <input 
                type="password" 
                id="new-password" 
                name="new-password" 
                placeholder={"Enter new password"} 
                onChange={(e) => setNewPassword(e.target.value)} 
                value={newPassword} 
                className="w-full md:w-[25rem] h-[3rem] border-[1px] border-bg-profile-setting rounded-[10px] pl-[1rem] text-lg"/>
        </label>
        <label htmlFor="confirm-password">
            <p className="text-xs mb-[0.5rem] text-rfq-label-text">Confirm Password</p>
            <input 
                type="password" 
                id="confirm-password" 
                name="confirm-password" 
                placeholder={"Confirm password"} 
                onChange={(e) => setConfirmPassword(e.target.value)} 
                value={confirmPassword} 
                className="w-full md:w-[25rem] h-[3rem] border-[1px] border-bg-profile-setting rounded-[10px] pl-[1rem] text-lg"/>
        </label>
        <button className="bg-primary-blue text-white text-xs w-[9.31rem] h-[3.5rem] rounded-[10px] shadow-[0px_6px_16px_5px_rgba(58,49,45,0.16)]">Save changes</button>
    </form>
  )
}

export default PasswordSetting;