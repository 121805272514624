import React from 'react'
import RFQSubmittedDetails from './RFQSubmittedDetails';
import RFQSubmittedQuotes from './RFQSubmittedQuotes';

const RFQSubmitted = () => {
  return (
    <div className="flex flex-col gap-[2.5rem]">
        <RFQSubmittedDetails/>
        <RFQSubmittedQuotes />
    </div>
  )
}

export default RFQSubmitted