export const isValidDate = (d: any) => {
  if (Object.prototype.toString.call(d) === "[object Date]") {
    if (isNaN(d)) {
      return false;
    } else {
      return true;
    }
  }
  return false;
};

