import React,{useEffect, useState} from 'react';
import RegisterForm from '../components/RegisterForm';
import Loading from '../components/Loading';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import {getRegistrationStatus, domain} from '../utils/apiService';
import ErrorPage from '../components/Error';
import { ErrorBoundary } from 'react-error-boundary';
import useOverview from '../hooks/useOverview';

const Register = () => {
  const {isAuthenticated, isLoading, user, getAccessTokenSilently} = useAuth0();
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useState<string|null>(localStorage.getItem('token'))
  const {setIsRegistered} = useOverview();
  const [isSubscribed, setSubscribed] = useState<boolean>(false);

  useEffect(() => {
    if(!authToken && !isLoading && user) {
        getAccessTokenSilently({
            ignoreCache: true,
            audience: `https://${domain}/api/v2/`,
        }).then(tk => {
          setAuthToken(tk)
          localStorage.setItem('token', tk)
        })
    }
  },[authToken, getAccessTokenSilently, isLoading, user])

  useEffect(() => {
    if(isAuthenticated && user && !isLoading && authToken) {
      getRegistrationStatus(user?.sub?.split('|')[1] as string, authToken as string)
      .then(st => {
        if(st.isregistered === 'true') {
          setIsRegistered(true)
          if(st.stripestatus === 'paid') {
            // user has registered and paid
            setSubscribed(true)
            navigate('/');
          } else {
            // user has registered but not paid
            navigate('/loading')
            window.open('/subscribe', '_blank')
          }
        }
        // user has not registered -> stay on register page
    })
      .catch(err => {
        if(err.message === 'EAuth0') {
          localStorage.removeItem('token')
          localStorage.removeItem('rfqid')
        }
        navigate('/login')
      });
    } else if (!isLoading && !isAuthenticated) {
      // user is not authenticated or user is not defined
      navigate('/login');
    }
  },[authToken, isAuthenticated, isLoading, navigate, user, setIsRegistered, isSubscribed])

  if(isLoading || !isAuthenticated || !user ) {
    return <Loading />
  }
  
  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
    >
      <div className="flex w-full h-[100vh] items-center pl-[4rem]">
        <div className="flex w-[40%]">
            <img src="login.png" alt="login" />
        </div>
        
        <div className="flex justify-center w-full">
            <RegisterForm />
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default Register;

