import React, {useContext, createContext, useState, useEffect} from 'react';
import {useAuth0} from '@auth0/auth0-react';
export interface IGeneralSetting {
    displayName: string,
    picture: string, // store the url of the picture
}

export interface IEmailSetting {
    currentEmail: string,
    newEmail: string,
    password: string,
}

export interface IPasswordSetting {
    currentPassword: string,
    newPassword: string,
    confirmPassword: string,
}

export interface ProfileProps {
    generalSetting: IGeneralSetting,
    setGeneralSetting: React.Dispatch<React.SetStateAction<IGeneralSetting>>,
    emailSetting: IEmailSetting,
    setEmailSetting: React.Dispatch<React.SetStateAction<IEmailSetting>>,
}


export const ProfileContext = createContext<ProfileProps>({
    generalSetting: {
        displayName: '',
        picture:'',
    },
    setGeneralSetting: () => {},
    emailSetting: {
        currentEmail: '',
        newEmail: '',
        password: '',
    },
    setEmailSetting: () => {},
})

export const ProfileProvider = ({children}:{children: React.ReactNode}) => {
    const {user} = useAuth0();
    const [generalSetting, setGeneralSetting] = useState<IGeneralSetting>({
        displayName: user?.nickname || user?.name || '',
        picture: user?.picture || 'placeholderImg.png',
    })
    const [emailSetting, setEmailSetting] = useState<IEmailSetting>({
        currentEmail: user?.email || '',
        newEmail: '',
        password: '',
    })

    useEffect(() => {
        if(user){
            setGeneralSetting({
                displayName: user?.nickname || user?.name || '',
                picture: user?.picture || 'placeholderImg.png',
            })
            setEmailSetting({
                currentEmail: user?.email || '',
                newEmail: '',
                password: '',
            })
        }
    },[user])
    

    return (
        <ProfileContext.Provider value={{
            generalSetting,
            setGeneralSetting,
            emailSetting,
            setEmailSetting,
        }}>
            {children}  
        </ProfileContext.Provider>
    )   
}

export default function useProfile(){
    return useContext(ProfileContext);
}