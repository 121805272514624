import { useAuth0 } from '@auth0/auth0-react';
import Loading from '../components/Loading';
import React, {useEffect, useState} from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import ErrorPage from '../components/Error';
import useOverview from '../hooks/useOverview';
import { domain, getRegistrationStatus } from '../utils/apiService';

function stripeEmbed(userid: string) {
  if(!userid) return
  return {
    __html: `<script async src="https://js.stripe.com/v3/pricing-table.js"></script>
    <stripe-pricing-table pricing-table-id="${process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}"
    publishable-key="${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}"
    client-reference-id="${userid}">
    </stripe-pricing-table>`
  };
}

const SubscriptionPlan = () => {
  const {isAuthenticated, user, isLoading, getAccessTokenSilently} = useAuth0();
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useState<string | null>(localStorage.getItem('token'))
  const {setIsRegistered} = useOverview();

  useEffect(() => {
    if(!authToken && !isLoading && user) {
        getAccessTokenSilently({
            ignoreCache: true,
            audience: `https://${domain}/api/v2/`,
        }).then(tk => {
          setAuthToken(tk)
          localStorage.setItem('token', tk)
        })
    }
  },[authToken, getAccessTokenSilently, isLoading, user])

  useEffect(() => {
    if(isAuthenticated && user && !isLoading && authToken) {
        getRegistrationStatus(user?.sub?.split('|')[1] as string, authToken as string)
        .then(status => {
            if(status.isregistered === 'true') {
                setIsRegistered(true)
                if(status.stripestatus === 'paid') {
                    // user is registered and has paid
                    navigate('/')
                } else {
                    // user is registered but has not paid -> stay on the page
                    return
                }
            } else {
                navigate('/register')
            }
        })
        .catch(err => {
            if(err.message === 'EAuth0') {
                localStorage.removeItem('token')
                localStorage.removeItem('rfqid')
            }
            navigate('/login') 
        })
    } else if (!isLoading && !isAuthenticated) {
      // user is not authenticated or user is not defined
      navigate('/login');
    }
},[isAuthenticated, navigate, user, isLoading, authToken, setIsRegistered])

if(isLoading || !user) {
  return <Loading/>
}


  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
    >
    <main className="h-[100vh] w-full flex flex-col items-center justify-center my-auto bg-bg-dashboard">
      <div className="absolute top-0 w-full bg-white min-h-[5.875rem]">
        <div className="flex justify-between px-[4rem] pt-[1rem]">
          <h1 className="text-xxl font-[700]">{'Subscription Plan'}</h1>
          <div className="w-[13.75rem] h-[3rem]">
            <img src="pridwenLogo.png" alt="" className="object-fit w-full h-full"/>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center">
        <div dangerouslySetInnerHTML={stripeEmbed(user?.sub?.split('|')[1] as string)} className="w-[70rem] py-[2rem] border-[1px] border-black rounded-xl bg-white"/>
      </div>
    </main>
  </ErrorBoundary>
  )
}

export default SubscriptionPlan