import React from 'react'
import SideMenuItem from './SideMenuItem';
import useMenu from '../hooks/useMenu';

const SideNavigationMenu = () => {
    const {selected} = useMenu()
  return (
    <ul className="flex flex-col">
        <li>
            <SideMenuItem text={"Overview"} icon={"overview"} active={selected === 'Overview'} />
        </li>
        <li>
            <SideMenuItem text={"Simulation"} icon={"pricing"} active={selected === 'Simulation'} />
        </li>
        <li>
            <SideMenuItem text={"Backtest"} icon={"backtest"} active={selected === 'Backtest'} />
        </li>
        <li>
            <SideMenuItem text={"RFQ"} icon={"rfq"} active={selected === 'RFQ'} />
        </li>
        <li>
            <SideMenuItem text={"Docs"} icon={"docs"} active={selected === 'Docs'} />
        </li>
        <hr className="border-[#E4EEF3] border-solid border-[1.3px] my-[3rem] w-[80%] flex mx-auto"/>
        <ul className="h-[100vh]">
          <li>
              <SideMenuItem text={"Profile"} icon={"profile"} active={selected === 'Profile'} />
          </li>
          <li>
            <SideMenuItem text={"Support"} icon={"support"} active={selected === 'Support'}/>
          </li>
          <li className="md:hidden">
            <SideMenuItem text={"Logout"} icon={"logout"} active={selected === 'Logout'}/>
          </li>
        </ul>
      </ul>
  )
}

export default SideNavigationMenu;