import React, {useContext, createContext, useState, useEffect, useCallback} from 'react';
import { SingleOverviewChartData } from '../utils/chartUtils';
import { getOverviewData, getOverviewCharts } from '../utils/apiService';
import { useAuth0 } from '@auth0/auth0-react';
import toast, {Toaster} from 'react-hot-toast';
import { SymArrayType } from '../utils/syms';
interface IOverview {
    pnlhedging: string | number,
    pnlnohedging:  string | number,
    savings: string | number,
    savingspercentage: string | number,
  }

export interface IOverviewChartData {
    overviewChartData: SingleOverviewChartData[],
    setOverviewChartData: React.Dispatch<React.SetStateAction<SingleOverviewChartData[]>>,
    overviewChartCols: string[],
    setOverviewChartCols: React.Dispatch<React.SetStateAction<string[]>>,
    overview:IOverview,
    setOverview: React.Dispatch<React.SetStateAction<IOverview>>,
    latestHedgingMethod:string,
    setLatestHedgingMethod: React.Dispatch<React.SetStateAction<string>>
    popUp: boolean,
    setPopup: React.Dispatch<React.SetStateAction<boolean>>
    showOverviewChart: boolean,
    setShowOverviewChart: React.Dispatch<React.SetStateAction<boolean>>
    isRegistered: boolean,
    setIsRegistered: React.Dispatch<React.SetStateAction<boolean>>,
    symArray: SymArrayType,
    setSymArray: React.Dispatch<React.SetStateAction<SymArrayType>>,
    firstSelectedSym: string,
    setFirstSelectedSym: React.Dispatch<React.SetStateAction<string>>
}

const initialOverview:IOverview = {
    pnlhedging: '',
    pnlnohedging: '',
    savings: '',
    savingspercentage: ''
}

const OverviewContext = createContext<IOverviewChartData>({
    overviewChartData: [] as SingleOverviewChartData[],
    setOverviewChartData: () => {},
    overviewChartCols: [] as string[],
    setOverviewChartCols: () => {},
    overview: initialOverview,
    setOverview: () => {},
    latestHedgingMethod: '',
    setLatestHedgingMethod: () => {},
    popUp: false,
    setPopup: () => {},
    showOverviewChart: false,
    setShowOverviewChart: () => {},
    isRegistered: false,
    setIsRegistered: () => {},
    symArray: [] as SymArrayType,
    setSymArray: () => {},
    firstSelectedSym: '',
    setFirstSelectedSym: () => {}
})

export const OverviewProvider = ({children}:{children: React.ReactNode}) => {
    const [overviewChartData, setOverviewChartData] = useState<SingleOverviewChartData[]>([]);
    const [overviewChartCols, setOverviewChartCols] = useState<string[]>([])
    const [symArray, setSymArray] = useState<SymArrayType>([{
      label: 'ETH',
      value: 'ethusd'
    }])
    const [firstSelectedSym, setFirstSelectedSym] = useState<string>('ethusd')
    const [overview, setOverview] = useState<IOverview>(initialOverview)
    const [latestHedgingMethod, setLatestHedgingMethod] = useState<string>('')
    const [popUp, setPopup] = useState<boolean>(false)
    const [isRegistered, setIsRegistered] = useState<boolean>(false)
    const [showOverviewChart, setShowOverviewChart] = useState<boolean>(false)
    const { isLoading, isAuthenticated, user } = useAuth0();
    const [authToken] = useState<string | null>(localStorage.getItem('token'))
    const keyPress = useCallback((e:KeyboardEvent) => {
      if(e.key === 'Escape' && popUp) {
          setPopup(false)
      }
  },[setPopup, popUp])

  // useEffect to execute the keypress function and watch for the function change
  useEffect(()=> {
      document.addEventListener('keydown', keyPress);
      return () => document.removeEventListener('keydown', keyPress);
  },[keyPress])

    useEffect(() => {
        const symList = symArray.map(sym => sym.value)
        if(isAuthenticated && user && authToken && isRegistered){
          getOverviewData(user?.sub?.split('|')[1] as string,symList,authToken)
          .then(data => {
            if(data) {
              setOverview(data)
            } 
          })
          .catch(err => {
            if((err as Error).message === 'EAuth0') {
               localStorage.removeItem('token')
               localStorage.removeItem('rfqid')
               window.location.href = window.location.origin + '/login'
              return;
            }
            toast.error(err.message || 'Error fetching overview data')
            })
    
          getOverviewCharts(user?.sub?.split('|')[1] as string,symList,authToken)
          .then(data => {
            setOverviewChartData(data?.performanceTimeSeries)
            setOverviewChartCols(data?.performanceColumns)
          })
          .catch(err => {
            if((err as Error).message === 'EAuth0') {
              localStorage.removeItem('token')
              localStorage.removeItem('rfqid')
              window.location.href = window.location.origin + '/login'
              return;
            }
            toast.error(err.message || 'Error fetching overview charts')
            })
        }
      },[isLoading, isAuthenticated,popUp, user, setOverviewChartData, setOverviewChartCols, setOverview, authToken, isRegistered, symArray])

    return (
        <OverviewContext.Provider value={{
            overviewChartData,
            setOverviewChartData,
            overviewChartCols,
            setOverviewChartCols,
            overview,
            setOverview,
            latestHedgingMethod,
            setLatestHedgingMethod,
            popUp,
            setPopup,
            showOverviewChart,
            setShowOverviewChart,
            isRegistered,
            setIsRegistered,
            symArray,
            setSymArray,
            firstSelectedSym,
            setFirstSelectedSym
        }}>
            <Toaster/>
            {children}
        </OverviewContext.Provider>
    )
}

export default function useOverview() {
    return useContext(OverviewContext);
}