import React,{ useEffect, useState } from 'react';
import { domain, getRegistrationStatus } from '../utils/apiService';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from '../components/Error';
import Loading from '../components/Loading';
import useOverview from '../hooks/useOverview';

const AuthLoading = () => {
  const {isAuthenticated, user, isLoading, getAccessTokenSilently, logout} = useAuth0();
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useState<string | null>(localStorage.getItem('token'))
  const {setIsRegistered} = useOverview();
  const [isSubscribed, setSubscribed] = useState<boolean>(false);

  const handleLogout = async () => {
    localStorage.removeItem('token');
    localStorage.removeItem('rfqid');
    logout({returnTo: window.location.origin + '/login'});
}

  useEffect(() => {
    if(!authToken && !isLoading && user) {
        getAccessTokenSilently({
            ignoreCache: true,
            audience: `https://${domain}/api/v2/`,
        }).then(tk => {
          setAuthToken(tk)
          localStorage.setItem('token', tk)
        })
    }
  },[authToken, getAccessTokenSilently, isLoading, user])

  useEffect(() => {
    if(isAuthenticated && user && !isLoading && authToken) {
        getRegistrationStatus(user?.sub?.split('|')[1] as string, authToken as string)
        .then(status => {
            if(status.isregistered === 'true') {
              setIsRegistered(true) 
              if(status.stripestatus === 'paid') {
              // user is registered and has paid
                setSubscribed(true)
                navigate('/')
              } else {
                // user is registered but has not paid --> stay on loading page
                return;
              } 
            } else {
              // if user is not registered
                navigate('/register')
            }
        })
        .catch(err => {
            if(err.message === 'EAuth0') {
                localStorage.removeItem('token')
                localStorage.removeItem('rfqid')
            }
            navigate('/login') 
        });
    }
},[isAuthenticated, navigate, user, isLoading, authToken, setIsRegistered, isSubscribed])

  useEffect(() => {
      let id: ReturnType<typeof setInterval>
      if(!isLoading && user && !isSubscribed && authToken) {
        id = setInterval(() => {
          getRegistrationStatus(user?.sub?.split('|')[1] as string, authToken as string)
          .then(status => {
              if(status.isregistered === 'true') {
                setIsRegistered(true) 
                if(status.stripestatus === 'paid') {
                // user is registered and has paid
                  setSubscribed(true)
                  navigate('/')
                } else {
                  // user is registered but has not paid --> stay on loading page
                  return;
                } 
              } else {
                // if user is not registered
                  navigate('/register')
              }
          })
          .catch(err => {
              if(err.message === 'EAuth0') {
                  localStorage.removeItem('token')
                  localStorage.removeItem('rfqid')
              }
              navigate('/login') 
          });
        }, 1000)
      }
      return () => {
        clearInterval(id)
      }
  },[isSubscribed, authToken, isLoading, navigate, user, setIsRegistered])


  return (
    <ErrorBoundary
        FallbackComponent={ErrorPage}
    >
        <div className="flex w-full h-[100vh] items-center md:pl-[4rem]">
            <div className="w-[50%] h-[100vh] flex justify-center items-center">
              <button type="button" className="bg-white h-[4rem] w-[14rem] gap-[1.5rem] pl-[2.5rem] rounded-[6px] flex items-center border-2 cursor-pointer" onClick={handleLogout}>
                  <div className="w-[1.25rem] h-[1.25rem]">
                  <img src="logout.png" alt="logout" />
                  </div>
                  <p className="rounded-[6px] text-lg font-[600] self-center">{'Logout'}</p>
              </button>
            </div>
            <Loading />
        </div>
    </ErrorBoundary>
  )
}

export default AuthLoading;


