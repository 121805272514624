export const validateName= (name:string) => {
    if(!name || typeof name !== 'string') {
        return false
    }
    if(/<[a-z][\s\S]*>/i.test(name)) {
        return false
    }

    return true;
}

export const validateEmail = (email:string) => {
    if(!email || typeof email !== 'string' || !email.includes('@')) {
        return false
    }
    if(/<[a-z][\s\S]*>/i.test(email)) {
        return false
    }
    return true;
}

export const validateRevenue = (revenue:number) => {
    if(revenue === undefined || revenue === null || isNaN(revenue) || typeof revenue !== 'number' || revenue <= 0) {
        return false
    }
    if(/<[a-z][\s\S]*>/i.test(revenue.toString())) {
        return false
    }
    return true
}

export const validateStrikeOffset = (strikeOffset:number) => {
    if(strikeOffset === undefined || strikeOffset === null || isNaN(strikeOffset) || Number(strikeOffset) <0 || Number(strikeOffset)> 100) {
        return false
    }
    if(/<[a-z][\s\S]*>/i.test(strikeOffset.toString())) {
        return false
    }
    return true
}

export const validateNotional = (notional:number) => {
    if(notional === undefined || notional === null || isNaN(notional)|| Number(notional) <= 0) {
        return false
    }
    if(/<[a-z][\s\S]*>/i.test(notional.toString())) {
        return false
    }
    return true
}