import React,{useState, useRef} from 'react'
import {
  ResponsiveContainer,
  AreaChart,
  XAxis, 
  YAxis,
  Area, 
  Tooltip,
  CartesianGrid,
  Line,
} from 'recharts';
import { Theme } from '../utils/Theme';
import moment from 'moment';
import Legend from './Legend';
import useBacktest from '../hooks/useBacktest';
import { startDate, formatBacktestChartCSVData, ticksArray, ChartPeriod, getSmartYtdStartDate, capFirstLetter } from '../utils/chartUtils';
import CSVDownload from './CSVDownload';
import { CSVFileName } from '../utils/formFieldsMapping';
import CustomToolTip from './CustomToolTip';

const BacktestChart = () => {
   const {backtestChartData, backtestSummary} = useBacktest();
   const [chartStartDate, setChartStartDate] = useState(Number(getSmartYtdStartDate()))
   const [chartPeriod, setChartPeriod] = useState('YTD')
   const compareCheck = backtestSummary.length === 2 ? true : false;
   const {formattedData, headers} = formatBacktestChartCSVData(backtestChartData, backtestSummary)
   const periodRef = useRef<any>(null)

   const handlePeriod = (e:any) => {
      if(periodRef.current?.childNodes){
        for (let i = 0; i < periodRef.current?.childNodes?.length; i++) {
          if(periodRef.current?.childNodes[i].textContent === e.target.innerText) {
            periodRef.current.childNodes[i].style.fontWeight = 600
            periodRef.current.childNodes[i].style.textDecoration = 'underline'
          } else {
            periodRef.current.childNodes[i].style.fontWeight = 400
            periodRef.current.childNodes[i].style.textDecoration = 'none'
          }
        }
      }
      
      setChartPeriod(e.target.innerText)
      if(e.target.innerText === 'Max') {
        setChartStartDate(backtestChartData[0]?.date) // date is sorted in ascending order
      } else if(startDate(e.target.innerText as string)) {
        Number(startDate(e.target.innerText as string)) < backtestChartData[0]?.date 
        ? setChartStartDate(backtestChartData[0]?.date) 
        : setChartStartDate(Number(startDate(e.target.innerText as string)))
      }
   }

   const customPeriodChartData = (chartData:typeof backtestChartData,startDate:number) => {
      const newChartData = chartData.filter((data:any) => {
        return data.date >= startDate
      })
      return newChartData.map((data,index) => {
        return {
          ...data,
          method: newChartData.slice(0,index).reduce((acc, curr) => acc + curr.method,0),
          methodCompare: newChartData.slice(0,index).reduce((acc, curr) => acc + curr.methodCompare,0)
        }
      })
  }

  const getDivisor = (backtestSummary: { pnl: any; }[]) =>{
    var ppnnll = backtestSummary[0]?.pnl;
    if (ppnnll>1000000) {
      return 1000000;
    }
    if (ppnnll>1000) {
      return 1000;
    }
    return 1;

  }

  const getUnit = (backtestSummary: { pnl: any; }[]) =>{
    var ppnnll = backtestSummary[0]?.pnl;
    if (ppnnll>1000000) {
      return "M";
    }
    if (ppnnll>1000) {
      return "k";
    }
    return "";

  }


   return (
    <article className="md:mx-[2.875rem] shadow-[2px_3px_30px_10px_rgba(222,235,241,0.35)] h-[35rem] rounded-[6px] bg-white pt-[1.875rem] pl-[2.625rem] pr-[3.25rem] block min-w-[40rem]">
      <header className="flex h-[3.125rem] md:px-[2.625rem] items-center space-around mb-[1.75rem] md:mb-[2rem] w-full">
        <h3 className=" xl:text-xxl md:text-mlg text-sm font-[600] text-chart-header w-[30%] flex-1">{`Hedged Revenues (${getUnit(backtestSummary)}$)`}</h3>
        {(backtestChartData.length !==0 && !!backtestChartData) && (
        <div className="flex gap-[1rem] justify-self-end mr-[3rem]">
          <Legend color={`bg-primary-blue`} text={backtestSummary[0]?.method +" - "+ capFirstLetter(backtestSummary[0]?.parameters.frequency)}/>
          {compareCheck && backtestSummary[1] && <Legend color={`bg-secondary-red`} text={backtestSummary[1]?.method +" - "+ capFirstLetter(backtestSummary[1]?.parameters.frequency) }/>}
        </div>)}
        <CSVDownload data={formattedData} headers={headers} filename={CSVFileName.BacktestChart} />
      </header>
      <ul className="flex gap-[1.875rem] text-chart-period text-xs md:text-large mb-[1.75rem] justify-center w-full period-click" onClick={(e) => handlePeriod(e)} ref={periodRef}>
          {
            backtestSummary && backtestSummary[0]?.parameters.frequency === 'monthly' 
            ? Object.keys(ChartPeriod).filter(key => key !== '1m').map((key) => {
                return <li key={key} className="cursor-pointer">{ChartPeriod[key as keyof typeof ChartPeriod]}</li>
              })
            : backtestSummary && backtestSummary[0]?.parameters.frequency === 'quarterly' 
            ? Object.keys(ChartPeriod).filter(key => key !== '1m' && key !== '3m').map((key) => {
              return <li key={key} className="cursor-pointer">{ChartPeriod[key as keyof typeof ChartPeriod]}</li>
            })
            : Object.keys(ChartPeriod).map((key) => {
              return <li key={key} className="cursor-pointer">{ChartPeriod[key as keyof typeof ChartPeriod]}</li>
            })
          }
        </ul>
      <ResponsiveContainer width="100%" height={400}>
        <AreaChart data={customPeriodChartData(backtestChartData,chartStartDate)}>
          <defs>
            <linearGradient id="colorBlue" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={Theme.primaryColor} stopOpacity={0.4}/>
              <stop offset="75%" stopColor={Theme.primaryColor} stopOpacity={0.05}/>
            </linearGradient>
          </defs>
          {compareCheck && (<defs>
            <linearGradient id="colorRed" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={Theme.secondaryColor} stopOpacity={0.4}/>
                <stop offset="75%" stopColor={Theme.secondaryColor} stopOpacity={0.05}/>
            </linearGradient>
          </defs>)}
          <Area dataKey="method" stroke={Theme.primaryColor} fill="url(#colorBlue)"/>
          {compareCheck && <Area dataKey="methodCompare" stroke={Theme.secondaryColor} fill="url(#colorRed)"/>}
          <XAxis 
            dataKey="date"
            type="number"
            axisLine={false} 
            tickLine={false} 
            ticks={ticksArray(customPeriodChartData(backtestChartData,chartStartDate),chartPeriod, backtestSummary[0]?.parameters?.frequency)}
            domain={[chartStartDate, 'dataMax']}
            tickFormatter={(date) => {
              if(chartPeriod === 'Max') {
                if(Math.abs(moment(backtestChartData[0]?.date).diff(moment(new Date()),'months')) <= 3) {
                  return moment(date).format('DD[/]MM[/]YY')
                } 
                return moment(date).format('MMM[-]YY')
              }
              if(chartPeriod === '3m' || chartPeriod === '1m') return moment(date).format('DD[/]MM[/]YY')
              return moment(date).format('MMM[-]YY')
            }}
          />

          <YAxis 
            axisLine={false} 
            tickLine={false} 
            tickCount={8}
            tickFormatter={(tick) => (Number(tick)/getDivisor(backtestSummary)).toLocaleString()}
          />

          <Tooltip content={<BacktestToolTip  chartData={backtestSummary}/>}/>

          <CartesianGrid vertical={false} opacity={0.5} strokeDasharray="5 5"/>
          <Line type="monotone" dataKey="method" stroke={Theme.primaryColor}/>
          {compareCheck && <Line type="monotone" dataKey="methodCompare" stroke={Theme.secondaryColor}/>}
        </AreaChart>
      </ResponsiveContainer>
    </article>
  )
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: any;
  label?: string | number | Date;
  chartData: any;
}

export const BacktestToolTip = ({active, payload, label, chartData}:CustomTooltipProps) => {
  if(!payload) return <></>
  if(active && label) {
    return (
      <CustomToolTip>
        <h4>{moment(label).format('DD[/]MM[/]YY')}</h4>
        <div>
          <span className="text-primary-blue">{`${chartData[0]?.method}:`}</span>
          <span className="text-black">{` $${(Math.round(payload[0].value/1000)).toLocaleString()}${payload[0].value >= 1000 ? 'k': ''}`}</span>
        </div>
        {payload[1] && (<div>
          <span className="text-secondary-red">{`${chartData[1].method}:`}</span>
          <span className="text-black">{` $${(Math.round(payload[1].value/1000)).toLocaleString()}${payload[1].value >= 1000 ? 'k': ''}`}</span>
        </div>)}
      </CustomToolTip>
    )
  }
  return <></>
}

export default BacktestChart;