import React from 'react'

interface TooltipProps {
    text: string;
}

const Tooltip = ({text}:TooltipProps) => {
  return (
    <div className="tooltip">
        <p className="p-0">{text}</p>
    </div>
  )
}

export default Tooltip;