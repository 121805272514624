import React, { useRef } from 'react';

interface PopUpBoxProps {
    children: React.ReactNode,
    popUp: boolean,
    setPopup: React.Dispatch<React.SetStateAction<boolean>>
}

const PopUpBox = ({children, popUp, setPopup}:PopUpBoxProps) => {
    const changeRef = useRef<HTMLDivElement>(null)
    const closePopupFromBackground = (e:React.MouseEvent<HTMLElement>) => {
        if(changeRef.current === e.target && popUp) {
          setPopup(false)
        }
      }

  return (
    <article className="fixed flex justify-center items-center top-0 z-10 bg-[rgba(0,0,0,0.5)] w-[100vw] h-[100vh]" ref={changeRef} onClick={(e) => closePopupFromBackground(e)}>
        {children}
    </article>
  )
}

export default PopUpBox;