import React,{useEffect, useRef, useState} from 'react'
import SideMenu from '../components/SideMenu';
import Header from '../components/Header';
import BacktestImportData from '../components/BacktestImportData';
import BacktestResult from '../components/BacktestResult';
import BacktestChart from '../components/BacktestChart';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useLocation } from 'react-router-dom';
import useMenu, {Selected} from '../hooks/useMenu';
import ErrorPage from '../components/Error';
import { ErrorBoundary } from 'react-error-boundary';
import MobileSideMenu from '../components/MobileSideMenu';
import MobileHeader from '../components/MobileHeader';
import useBacktest from '../hooks/useBacktest';
import ChartButton from '../components/ChartButton';
import { domain, getRegistrationStatus } from '../utils/apiService';
import useOverview from '../hooks/useOverview';
import Loading from '../components/Loading';

const Backtest = () => {
  const {isAuthenticated, isLoading, getAccessTokenSilently, user} = useAuth0();
  const navigate = useNavigate();
  const chartRef = useRef(null);
  const {setSelected, showMobileMenu, setShowMobileMenu} = useMenu();
  const {pathname} = useLocation();
  const pageRef = useRef<any>(null);
  const headerText = Selected.Backtest
  const {showBacktestChart, setShowBacktestChart} = useBacktest();
  const [authToken, setAuthToken] = useState<string | null>(localStorage.getItem('token'))
  const {setIsRegistered} = useOverview();
  const [isSubscribed, setSubscribed] = useState<boolean>(false);

  useEffect(() => {
    if(pathname === '/backtest') {
      setSelected(Selected.Backtest)
    }
    if(pageRef.current) {
      pageRef.current?.scrollIntoView({behavior: 'smooth', block:'start'})
    }
  },[pathname, setSelected])

  useEffect(() => {
    if(!authToken && !isLoading && user) {
        getAccessTokenSilently({
            ignoreCache: true,
            audience: `https://${domain}/api/v2/`,
        }).then(tk => {
          setAuthToken(tk)
          localStorage.setItem('token', tk)
        })
    }
  },[authToken, getAccessTokenSilently, isLoading, user])

  useEffect(() => {
    if(isAuthenticated && user && !isLoading && authToken) {
      getRegistrationStatus(user?.sub?.split('|')[1] as string, authToken as string)
      .then(st => {
        if(st.isregistered === 'true') {
            setIsRegistered(true)
          if(st.stripestatus === 'paid') {
            // user has registered
            setSubscribed(true)
            return;
          } else  {
          // user has not paid
            navigate('/loading')
            window.open('/subscribe', '_blank')
          } 
        } else {
          // user has not registered
          navigate('/register');
        }
    })
      .catch(err => {
        if(err.message === 'EAuth0') {
          localStorage.removeItem('token')
          localStorage.removeItem('rfqid')
        }
        navigate('/login')
      });
    } else if (!isLoading && !isAuthenticated) {
      // user is not authenticated or user is not defined
      navigate('/login');
    }
  },[authToken, isAuthenticated, isLoading, navigate, user, setIsRegistered, isSubscribed])

  if(isLoading || !authToken || !user|| !isAuthenticated || !isSubscribed){
    return <Loading />
  }
 
  return (
    <ErrorBoundary
    FallbackComponent={ErrorPage}
  >
      <main className="h-[100%] flex flex-1 overflow-x-scroll md:overflow-x-hidden bg-bg-dashboard">
        <SideMenu />
        <div className="flex flex-col bg-bg-dashboard gap-[2.5rem] h-[100%] w-[100%] md:header-width lg:w-[100vw]" ref={pageRef}>
          {showMobileMenu && <MobileSideMenu />}  
          <Header headerText={headerText}/>
          <MobileHeader/>
            <section className="flex flex-col h-full bg-bg-dashboard mt-[3rem] md:mt-0 w-full pb-[5%] px-[1rem] md:px-0 md:gap-[2.5rem] gap-[1.8rem] min-w-[18.5rem]" onClick={() => setShowMobileMenu(false)}>
              <h1 className="text-xlg font-[700] text-center md:hidden mt-[2rem]">{headerText}</h1>
              <BacktestImportData chartRef={chartRef}/>
              <BacktestResult />
              <div 
                className="block md:hidden mb-[3rem] cursor-pointer w-full" 
                onClick={() => setShowBacktestChart((prev:boolean) => !prev)} 
                ref={chartRef}
              >
                {
                    showBacktestChart ? <BacktestChart/> : <ChartButton image={"chart.svg"}/>
                }
              </div>
              <div 
                ref={chartRef} 
                className="hidden md:block mb-[3rem] w-full"
              >
                <BacktestChart />
              </div>
            </section>
        </div>
      </main>
    </ErrorBoundary>
  )
}

export default Backtest