import React from 'react';
import { useNavigate } from 'react-router-dom';
import useMenu from '../hooks/useMenu';
import { useAuth0 } from '@auth0/auth0-react';

interface SideMenuItemProps {
    text:string,
    icon:string,
    active:boolean
}

const SideMenuItem = ({text, icon, active}:SideMenuItemProps) => {
  const navigate = useNavigate()
  const {setSelected} = useMenu()
  const {logout} = useAuth0()

  const handleClick= (e:any) => {
    setSelected(e.target.outerText)
    switch (text) {
      case 'Overview': 
        navigate('/')
        break;
      case 'Simulation':
        navigate('/pricing')
        break;
      case 'RFQ':
          navigate('/rfq')
          break;
      case 'Backtest':
          navigate('/backtest')
          break;
      case 'Profile':
        navigate('/profile')
        break;
      case 'Support':
        navigate('/support')
        break;
      case 'Docs':
        window.open('https://docs.pridwen.fi/', '_blank')
        break;
      case 'Logout':
        localStorage.removeItem('token')
        localStorage.removeItem('rfqid')
        logout({returnTo: window.location.origin + '/login'});
    }
  }

  return (
    <button id={text} className={`${active ? `side-menu-item-container-active`: `side-menu-item-container`} w-full flex gap-[1.375rem] h-[3.875rem] items-center pl-[25%]`} onClick={(e) => handleClick(e)}>
        <div className="h-6 w-6">
          <img src={`${active ? `${icon}-active.svg`: `${icon}.svg`}`} alt=""/>
        </div>
        <p className={`${active ? 'side-menu-item-text-active': 'side-menu-item-text'}`}>{text}</p>
    </button>
  )
}

export default SideMenuItem;