import React from 'react';

interface CustomToolTipProps {
    children: React.ReactNode;
  }
  
const CustomToolTip = ({children}:CustomToolTipProps) => {
      return (
        <div className="bg-white border-[1px] border-opacity-40 border-[#5c5c5c] py-1 px-[1rem]  flex flex-col gap-[0.1rem] rounded-[6px] text-xxs">
          {children}
        </div>
      )
}

export default CustomToolTip;