import React,{useState, useEffect} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { registerTradeOptions, Auth0UserDetailsProps, getLatestPrice, domain } from '../utils/apiService';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { priceFormatWithDecimals } from '../utils/numberFormat';
import SubmitButton from './SubmitButton';
import {COLLAR, hedgingMethod, PUT, SPOT, ZERO_COST_COLLAR} from '../utils/instruments';
import { rollFrequency} from '../utils/formFieldsMapping';
import { capFirstLetter } from '../utils/chartUtils';
import { displayedSymToValue, extractCryptoCcyFromPair } from '../utils/syms';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { validateRevenue, validateStrikeOffset } from '../utils/inputValidation';

const RegisterForm = () => {
  const [sym, setSym] = useState('');
  const [method, setMethod] = useState('');  
  const [frequency, setFrequency] = useState('');
  const [strikeOffset, setStrikeOffset] = useState<number | string>(0);
  const [revenue, setRevenue] = useState<number | string>('');
  const [startDate, setStartDate] = useState<Date>(new Date())
  const {user, getAccessTokenSilently, isLoading} = useAuth0();
  const navigate = useNavigate();
  const [latestPrice, setLatestPrice] = useState(0);
  const [authToken, setAuthToken] = useState<string|null>(localStorage.getItem('token'))

  useEffect(() => {
    if(!authToken && !isLoading && user) {
        getAccessTokenSilently({
            ignoreCache: true,
            audience: `https://${domain}/api/v2/`,
        }).then(tk => {
          setAuthToken(tk)
          localStorage.setItem('token', tk)
        })
    }
  },[authToken, getAccessTokenSilently, isLoading, user, setAuthToken])

  useEffect(()=> {
    if(!authToken || !sym) {
      return
    }
    getLatestPrice(sym,authToken)
    .then(price => setLatestPrice(price?.toFixed(2)))
    .catch(err => {
      if((err as Error).message === 'EAuth0') {
         localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      toast.error((err as Error).message)
    })
  },[authToken, sym, navigate])

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(!authToken) {
      toast.error('Please login');
      return;
    }
    if(!sym) {
      toast.error('Please select a token');
      return;
    }
    if(!method) {
      toast.error('Please select a method');
      return;
    }
    if(!startDate) {
      toast.error('Please select a start date');
      return;
    }
    if(method !== 'notsold') {
      if(!frequency) {
        toast.error('Please select a frequency');
        return;
      }
      if([PUT, COLLAR, ZERO_COST_COLLAR].includes(method)) {
        if(!validateStrikeOffset(Number(strikeOffset))) {
            toast.error('Please enter a valid strike offset value')
            return
        }
      }
    }
    if(!validateRevenue(Number(revenue))) {
        toast.error('Please enter a valid revenue number');
        return;
    }
    if(user) {
      try {
        await registerTradeOptions({
          ...user,
          method,
          frequency: frequency ? frequency : '',
          strikeOffset: strikeOffset ? Number(strikeOffset)/100 : 0,
          revenue,
          sym,
          startDate: startDate ? moment(startDate).format("YYYY[-]MM[-]DD") : '',
        } as Auth0UserDetailsProps, authToken)
      } catch (error) {
        if((error as Error).message === 'EAuth0') {
           localStorage.removeItem('token')
           localStorage.removeItem('rfqid')
          navigate('/login')
          return;
        }
        toast.error((error as Error).message)
      }
      
    }
    navigate('/')
  }


  return (
    <article className="flex flex-col w-[25.25rem] bg-bg-login px-[1.5rem] rounded-[10px] pb-[2.8rem] shadow-[0px_6px_16px_5px_rgba(58,49,45,0.16)]">
        <h3 className="text-xl mt-[1.5rem] mb-[1.8rem]">Register Your Trade Options</h3>
        <Toaster/>
        <form className="flex flex-col gap-[1.875rem]" onSubmit={handleSubmit}>
          <label htmlFor='sym'>
              <p className="text-rfq-label-text">Token</p>
              <select id="sym" className="text-rfq-value-text mt-[1rem] w-full border-b-[#E6EBEE] border-b-[1px] h-[2.8rem] pl-[0.8rem] rounded-[6px]" onChange={(e) => setSym(e.target.value)} value={sym} required>
                  <option value="">{"Select"}</option>
                  {
                    displayedSymToValue.map((sym) => <option key={sym.value} value={sym.value}>{sym.symbol}</option>)
                  }
              </select>
          </label>
          <label htmlFor="revenue">
              <p className="text-rfq-label-text">What is your approximate monthly revenue (in token)?</p>
              <input type="number" id="revenue" name="revenue" placeholder={'10'} min={0} className="text-rfq-value-text mt-[1rem] w-full border-b-[#E6EBEE] border-b-[1px] h-[2.8rem] pl-[0.8rem] rounded-[6px]" onChange={(e) => setRevenue(e.target.value)} value={revenue} required/>
          </label>
          <label htmlFor='method'>
              <p className="text-rfq-label-text">Current Hedging Method</p>
              <select id="method" className="text-rfq-value-text mt-[1rem] w-full border-b-[#E6EBEE] border-b-[1px] h-[2.8rem] pl-[0.8rem] rounded-[6px]" onChange={(e) => setMethod(e.target.value)} value={method} required>
                  <option value="">{"Select"}</option>
                  <option value="notsold">No Hedging</option>
                  {
                    hedgingMethod.map((method) => <option key={method.value} value={method.value}>{method.name}</option>)
                  }
              </select>
          </label>
          {method !== 'notsold' ? (
          <label htmlFor='frequency'>
              <p className="text-rfq-label-text">Hedging Frequency</p>
              <select 
                id="frequency" 
                className="text-rfq-value-text mt-[1rem] w-full border-b-[#E6EBEE] border-b-[1px] h-[2.8rem] pl-[0.8rem] rounded-[6px]" 
                onChange={(e) => setFrequency(e.target.value)} 
                value={frequency}
              >
                <option value="">{"Select"}</option>
                {method === SPOT && <option value="weekly">Weekly</option>}
                {
                  rollFrequency.map((frequency) => <option key={frequency} value={frequency}>{capFirstLetter(frequency)}</option>)
                }
              </select>
          </label>) : <></>}
            <label htmlFor="startdate">
                <p className="text-rfq-label-text">When did you start using this hedging method?</p>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                      setStartDate(date as Date);
                  }}
                  className="border-b-[#E6EBEE] border-b-[1px] w-full pl-[0.8rem] mt-[1rem] h-[2.8rem] rounded-[6px]"
                  dateFormat={"dd/MM/yyyy"} 
                  maxDate={new Date()}
                />
            </label>         

          {[PUT, COLLAR, ZERO_COST_COLLAR].includes(method) && (
          <label htmlFor="strikeOffset">
              <p className="text-rfq-label-text pb-[0.5rem]">{`Current ${extractCryptoCcyFromPair(sym)} Price: ${priceFormatWithDecimals(latestPrice)}`}</p>
              <p className="text-rfq-label-text">How much below the market price do you choose your strike (% of spot)?</p>
              <input type="number" id="strikeOffset" name="strikeOffset" placeholder={'10'} min={0} max={100} className="text-rfq-value-text mt-[1rem] w-full border-b-[#E6EBEE] border-b-[1px] h-[2.8rem] pl-[0.8rem] rounded-[6px]" onChange={(e) => setStrikeOffset(e.target.value)} value={strikeOffset}/>
          </label>)}
          <SubmitButton btnText={"Submit"}/>
        </form>
    </article>
  )
}

export default RegisterForm;