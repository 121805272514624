import React,{useEffect, useState} from 'react'
import Header from '../components/Header'
import SideMenu from '../components/SideMenu'
import ProfileSettings from '../components/ProfileSettings';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useLocation } from 'react-router-dom';
import useMenu, {Selected} from '../hooks/useMenu';
import ErrorPage from '../components/Error';
import { ErrorBoundary } from 'react-error-boundary';
import MobileSideMenu from '../components/MobileSideMenu';
import MobileHeader from '../components/MobileHeader';
import useOverview from '../hooks/useOverview';
import { domain, getRegistrationStatus } from '../utils/apiService';
import Loading from '../components/Loading';

const Profile = () => {
  const {isAuthenticated, isLoading, getAccessTokenSilently, user} = useAuth0();
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const {setIsRegistered} = useOverview();
  const {setSelected, showMobileMenu, setShowMobileMenu} = useMenu();
  const headerText = "Profile Settings"
  const [authToken, setAuthToken] = useState<string | null>(localStorage.getItem('token'))
  const [isSubscribed, setSubscribed] = useState<boolean>(false);

  useEffect(() => {
    if(pathname === '/profile') {
      setSelected(Selected.Profile)
    }
  },[pathname, setSelected])

  useEffect(() => {
    if(!authToken && !isLoading && user) {
        getAccessTokenSilently({
            ignoreCache: true,
            audience: `https://${domain}/api/v2/`,
        }).then(tk => {
          setAuthToken(tk)
          localStorage.setItem('token', tk)
        })
    }
  },[authToken, getAccessTokenSilently, isLoading, user])

  useEffect(() => {
    if(isAuthenticated && user && !isLoading && authToken) {
      getRegistrationStatus(user?.sub?.split('|')[1] as string, authToken as string)
      .then(st => {
        if(st.isregistered === 'true') {
            setIsRegistered(true)
          if(st.stripestatus === 'paid') {
            // user has registered
            setSubscribed(true)
            return;
          } else  {
          // user has not paid
            navigate('/loading')
            window.open('/subscribe', '_blank')
          } 
        } else {
          // user has not registered
          navigate('/register');
        }
    })
      .catch(err => {
        if(err.message === 'EAuth0') {
          localStorage.removeItem('token')
          localStorage.removeItem('rfqid')
        }
        navigate('/login')
      });
    } else if (!isLoading && !isAuthenticated) {
      // user is not authenticated or user is not defined
      navigate('/login');
    }
  },[authToken, isAuthenticated, isLoading, navigate, user, setIsRegistered, isSubscribed])

  if(isLoading || !authToken || !user|| !isAuthenticated || !isSubscribed){
    return <Loading/>
  }

  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
    >
    <main className="h-[100vh] flex flex-1 bg-bg-dashboard">
      <SideMenu />
      <div className="flex flex-col bg-bg-dashboard w-full min-w-[18.5rem]" >
        {showMobileMenu && <MobileSideMenu />}  
        <Header headerText={headerText}/>
        <MobileHeader/>
        <div className="mt-[5rem] bg-bg-dashboard h-full" onClick={() => setShowMobileMenu(false)}>
          <h1 className="text-xlg font-[700] text-center md:hidden mb-[1.5rem] text-black">{headerText}</h1>
          <ProfileSettings />
        </div>
      </div>
    </main>
  </ErrorBoundary>
  )
}

export default Profile