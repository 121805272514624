import React, {useEffect, useRef, useState} from 'react';
import SideMenu from '../components/SideMenu';
import Header from '../components/Header';
import RFQForm from '../components/RFQForm';
import RecentRFQTable from '../components/RecentRFQTable';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useLocation } from 'react-router-dom';
import useMenu, {Selected} from '../hooks/useMenu';
import ErrorPage from '../components/Error';
import {  ErrorBoundary } from 'react-error-boundary';
import MobileHeader from '../components/MobileHeader';
import MobileSideMenu from '../components/MobileSideMenu';
import useRFQ from '../hooks/useRFQ';
import ChartButton from '../components/ChartButton';
import TradeBox from '../components/TradeBox';
import { domain, getRegistrationStatus } from '../utils/apiService';
import useOverview from '../hooks/useOverview';
import Loading from '../components/Loading';
import Select from 'react-select';
import { selectSymList, SymArrayType } from '../utils/syms';

const RFQ = () => {
  const {isAuthenticated, isLoading, getAccessTokenSilently, user} = useAuth0();
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const {setSelected, showMobileMenu, setShowMobileMenu} = useMenu();
  const pageRef = useRef<any>(null);
  const headerText = Selected.RFQ
  const {showRecentQuoteTable, setShowRecentQuoteTable, popUp} = useRFQ();
  const [authToken, setAuthToken] = useState<string | null>(localStorage.getItem('token'))
  const {setIsRegistered} = useOverview();
  const [isSubscribed, setSubscribed] = useState<boolean>(false);
  const {symArray, setSymArray, setFirstSelectedSym} = useOverview()

  useEffect(() => {
    if(pathname === '/rfq') {
      setSelected(Selected.RFQ)
    }
    if(pageRef.current) {
      pageRef.current?.scrollIntoView({behavior: 'smooth', block:'start'})
    }
  },[pathname, setSelected])

  useEffect(() => {
    if(!authToken && !isLoading && user) {
        getAccessTokenSilently({
            ignoreCache: true,
            audience: `https://${domain}/api/v2/`,
        }).then(tk => {
          setAuthToken(tk)
          localStorage.setItem('token', tk)
        })
    }
  },[authToken, getAccessTokenSilently, isLoading, user])

  useEffect(() => {
    if(isAuthenticated && user && !isLoading && authToken) {
      getRegistrationStatus(user?.sub?.split('|')[1] as string, authToken as string)
      .then(st => {
        if(st.isregistered === 'true') {
            setIsRegistered(true)
          if(st.stripestatus === 'paid') {
            // user has registered
            setSubscribed(true)
            return;
          } else  {
          // user has not paid
            navigate('/loading')
            window.open('/subscribe', '_blank')
          } 
        } else {
          // user has not registered
          navigate('/register');
        }
    })
      .catch(err => {
        if(err.message === 'EAuth0') {
          localStorage.removeItem('token')
          localStorage.removeItem('rfqid')
        }
        navigate('/login')
      });
    } else if (!isLoading && !isAuthenticated) {
      // user is not authenticated or user is not defined
      navigate('/login');
    }
  },[authToken, isAuthenticated, isLoading, navigate, user, setIsRegistered, isSubscribed])

  if(isLoading || !isAuthenticated || !user || !authToken || !isSubscribed) {
    return <Loading/>
  }

  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
    >
    <main className="h-[100%] flex flex-1 bg-bg-dashboard overflow-x-scroll md:overflow-x-hidden">
      <SideMenu />
      <div className="flex flex-col bg-bg-dashboard w-full h-[100vh]" ref={pageRef}>
        {showMobileMenu && <MobileSideMenu />} 
        {popUp && <TradeBox/>}
        <Header headerText={headerText}/>
        <MobileHeader/>
        <section className="flex flex-col md:mx-[2.875rem] mt-[2.25rem] px-[1rem] md:px-0 min-w-[18.5rem]" onClick={()=> setShowMobileMenu(false)}>
          <h1 className="text-xlg font-[700] text-center md:hidden mt-[3rem]">{headerText}</h1>
          <div className="flex self-center md:hidden">
              {
                <Select
                  isMulti
                  name="sym-list"
                  defaultValue = {symArray[0]}
                  onChange={(e) => {
                    setSymArray(e as SymArrayType)
                    const sym = e && e.length > 0 ? (e as SymArrayType)[0]?.value : 'ethusd'
                    setFirstSelectedSym(sym)
                  }}
                  options={selectSymList}
                />
              }
          </div>
          <RFQForm />
          <h3 className="font-[600] text-lg md:text-xl text-rfq-header mt-[2rem]">Recent RFQs</h3>
          <div 
            className="block md:hidden mb-[3rem] cursor-pointer w-full" 
            onClick={() => setShowRecentQuoteTable((prev:boolean) => !prev)} 
          >
            {
              showRecentQuoteTable ? <RecentRFQTable/> : <ChartButton image={"table.png"}/>
            }
          </div>
          <div 
            className="hidden md:block mb-[3rem] w-full"
          >
            <RecentRFQTable />
          </div>
        </section>
      </div>
    </main>
    </ErrorBoundary>
  )
}

export default RFQ;