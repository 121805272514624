import React, {useContext, createContext, useState} from 'react';
import { SingleBacktestPerformance } from '../utils/chartUtils';
import { PRIDWEN_START_DATE } from '../utils/constants';

export interface SingleBacktestSummary {
    method: string,
    parameters: {
        frequency:string,
        strike_offset:number,
        strike_offset_call:number,
    },
    pnl: number,
    id: string,
}

export interface ISelectedMethod {
    method: string,
    sym:string,
    frequency: string,
    strikeOffset: number | string,
    strikeOffsetCall: number | string,
}


interface BacktestFormFieldsProps {
    method: {
        sym: string,
        method: string,
        frequency: string,
        strikeOffset: number | string,
        strikeOffsetCall: number | string,
    },
    methodCompare: {
        method: string,
        frequency: string,
        strikeOffset: number | string,
        strikeOffsetCall: number | string,
    },
    backtestFormSubmitted: boolean,
}
export interface BacktestProps {
    sym: string,
    setSym: React.Dispatch<React.SetStateAction<string>>,
    backtestChartData: SingleBacktestPerformance[],
    setBacktestChartData: React.Dispatch<React.SetStateAction<SingleBacktestPerformance[]>>,
    backtestSummary:SingleBacktestSummary[],
    setBacktestSummary: React.Dispatch<React.SetStateAction<SingleBacktestSummary[]>>,
    compareCheck: boolean,
    setCompareCheck: React.Dispatch<React.SetStateAction<boolean>>,
    backtestFormValues: BacktestFormFieldsProps,
    setBacktestFormValues: React.Dispatch<React.SetStateAction<BacktestFormFieldsProps>>,
    selectedMethod: ISelectedMethod,
    setSelectedMethod: React.Dispatch<React.SetStateAction<ISelectedMethod>>,
    showBacktestChart: boolean,
    setShowBacktestChart: React.Dispatch<React.SetStateAction<boolean>>,
    startDate: Date,
    setStartDate: React.Dispatch<React.SetStateAction<Date>>,
}

const initialBacktestFormValues = {
    method: {
        sym: '',
        method: '',
        frequency: '',
        strikeOffset: '',
        strikeOffsetCall: '',
    },
    methodCompare: {
        method: '',
        frequency: '',
        strikeOffset: '',
        strikeOffsetCall: '',
    },
    backtestFormSubmitted: false,
}


const BacktestContext = createContext<BacktestProps>({
    sym: '',
    setSym: () => {},
    backtestChartData: [],
    setBacktestChartData: () => {},
    backtestSummary: [],
    setBacktestSummary: () => {},
    compareCheck: false,
    setCompareCheck: () => {},
    backtestFormValues: initialBacktestFormValues,
    setBacktestFormValues: () => {},
    selectedMethod: {
        method: '',
        sym:'',
        frequency: '',
        strikeOffset: '',
        strikeOffsetCall:'',
    },
    setSelectedMethod: () => {},
    showBacktestChart: false,
    setShowBacktestChart: () => {},
    startDate:PRIDWEN_START_DATE,
    setStartDate: () => {},
})

export const BacktestProvider = ({children}:{children: React.ReactNode}) => {
    const [backtestChartData, setBacktestChartData] = useState([] as SingleBacktestPerformance[])
    const [backtestSummary, setBacktestSummary] = useState([] as SingleBacktestSummary[])
    const [compareCheck, setCompareCheck] = useState(false)
    const [sym, setSym] = useState('ethusd')
    const [startDate, setStartDate] = useState(PRIDWEN_START_DATE)
    const [backtestFormValues, setBacktestFormValues] = useState(initialBacktestFormValues as BacktestFormFieldsProps)
    const [selectedMethod, setSelectedMethod] = useState<ISelectedMethod>({
        method: '',
        sym:'',
        frequency: '',
        strikeOffset: '',
        strikeOffsetCall: '',
    })
    const [showBacktestChart, setShowBacktestChart] = useState(false)

    return (
        <BacktestContext.Provider value={{
            sym,
            setSym,
            backtestChartData,
            setBacktestChartData,
            backtestSummary,
            setBacktestSummary,
            compareCheck,
            setCompareCheck,
            backtestFormValues,
            setBacktestFormValues,
            selectedMethod,
            setSelectedMethod,
            showBacktestChart,
            setShowBacktestChart,
            startDate,
            setStartDate,
        }}>
            {children}
        </BacktestContext.Provider>
    )
}

export default function useBacktest(){
    return useContext(BacktestContext)
}