import React from 'react'

interface SubmitButtonProps {
    btnText: string;
    btnClick: () => void;
    cancel?: boolean;
    disabled?: boolean;
}

const TradeButton = ({btnText, btnClick, cancel, disabled}:SubmitButtonProps) => {
  return (
    <button 
        type="button" 
        id={btnText}
        className={`rounded-[6px] h-[3.125rem] w-[8.875rem] text-white text-lg font-[600] mb-[1.5rem] shadow-[2px_3px_24px_10px_rgba(0,0,0,0.15)] ${cancel ? 'bg-secondary-red' : disabled ? 'bg-chart-period' :'bg-black'}`} 
        onClick={btnClick}
        disabled={disabled ? true : false}
    >
      {btnText}
    </button>
  )
}

export default TradeButton;